import React, { Component } from "react";
import ScrollToMount from "../common/ScrollToMount";
import { Helmet } from 'react-helmet'

const TITLE = 'Privacy and Cookies statement'


const Conditions = () => {
    return (
        <div className="container" id="responsive" style={{ marginTop: "10px" }}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <ScrollToMount />
            <h2>The My Free Course - Privacy and Cookies Statement (“Privacy Statement”)</h2>
            <p>
                <b>1. Introduction</b>
                <br />  1.1 We, Starch UK Ltd  (ta My Free Course), operate this Privacy Statement because we are committed to safeguarding the privacy of those using our services (including but not limited to the Site defined below) and the confidentiality of any information that we collect about you. This Privacy Statement sets out how we will use any personal information that we may obtain from you in connection with our services (including but not limited to the Site). If we change any of the terms of this Privacy Statement we will post the revised statement on the Site behind the “Privacy and Cookies Statement” link at the bottom of each page.
                <br />  1.2 The My Free Course (home page www.myfreecourse.co.uk) (“the Site”) is operated by Starch UK Ltd.
                <br />  1.3 Whenever you submit your information to us in connection with our services or your use of the Site, whether it be by using the Site, by email, by SMS, over the telephone or in person, you acknowledge that we will collect and use such information in accordance with the terms of this Privacy Statement and, if you opt in when requested by us, are consenting to be added to our mailing list. Please see section 4 below for further information if you no longer wish to receive such mailings from us.
                <br />  1.3.1 By using our website, you consent to us contacting you regarding your course inquiry, application, or enrolment through SMS, email, phone, and WhatsApp. Additionally, you agree to be added to our mailing list. Your information will be handled in accordance with our privacy policy.
                <br />  1.4 You have the right to lodge a complaint with the Information Commissioner’s Office (“ICO”) if you have any concerns with regard to the way in which we process your personal data. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
                <br />  1.5 Please note that if you register with us as a learner we will collect additional information from you in connection with such registration. Such information will be processed in accordance with our separate Learner Data Protection Statement.
                <br />  1.6 You warrant that any information you supply to us is accurate and up to date (whether or not the information is about you), that you will inform us if any information that we hold about you requires updating, that you will update any information that you upload using the Site in the event that it becomes out of date, and that if you submit a third party’s details to us (including but not limited to via the Site) you have that third party’s permission or an alternative legal basis to do so.
                <br />  <b>2. Who are we?</b>
                <br />  2.1 We are Starch UK Ltd, a limited company registered in England (number 13176774) whose registered office and address for correspondence is at 71–75 Shelton Street, Covent Garden, London, WC2H 9JQ.
                <br />  2.2 We are committed to ensuring that all personal information we hold is treated properly and in accordance with applicable data protection legislation.
                <br />  <b>3. What information do we collect and how do we use it?</b>
                <br />  3.1 It is possible to visit and browse some sections of the Site without being required to tell us who you are or reveal any information about you (e.g. if you do not log in to the Site).
                <br />  3.2 We may keep a record of any information that you post or upload using the Site, or if you contact us, we may keep a copy of that correspondence. We may also keep details of your use of the Site, including, but not limited to, traffic data, location data and other communication data, and the resources that you access and download using the Site. We will use this information for our legitimate interests in the following ways:
                <br />  3.2.1 to develop our systems and services and ensure that content from the Site is presented in the most effective manner for you and for your computer/device;
                <br />  3.2.2 to provide you with information, products or services that you request from us;
                <br />  3.2.3 to carry out our obligations arising from any contracts entered into between us and your employer;
                <br />  3.2.4 to monitor your compliance with our terms and conditions;
                <br />  3.2.5 to allow you to participate in interactive features of the Site;
                <br />  3.2.6 to notify you about changes to our courses, our services and/or the Site; and
                <br />  3.2.7 to create anonymised reports based on your usage of the Site.
                <br />  3.3 If you order or register on any of our courses using the Site, we will use your name, contact details, bank account details, payment card details and/or PayPal account details where necessary for the performance of any contract between us (for example, to take payment from you of our fee for the relevant course and to issue any refunds that may become payable). Where you are paying by card, you will be redirected to the website of our payment services provider SagePay in order to make your payment (we do not store any card details on our servers). After the payment has been completed you will be redirected back to the Site. Please note that SagePay may collect personal information from you which will be used in accordance with SagePay own privacy policy (which you should read). More information regarding SagePay can be accessed via the following URL: www.sagepay.co.uk. We are not liable for the practices of SagePay in respect of your personal information.
                <br />  3.4 If you enter into a promotion or competition using the Site, we will use your name and contact details for our legitimate interests in processing your entry in accordance with the relevant promotion/competition terms and conditions (which you should familiarise yourself with before entering).
                <br />  3.5 In the event that you provide us with feedback regarding the Site, we may disclose that feedback to our suppliers, awarding bodies and to other users of the Site where it is in our legitimate interests to do so in order to improve the products and services that we provide. This includes any information that you provide to us if you report a problem with the Site.
                <br />  3.6 Where you have given consent, we may use your information to inform you of special offers, promotions, new products, new services and changes to the Site via post, telephone, email, SMS or other appropriate means. If you subscribe to our mailing list but subsequently decide that you no longer wish to receive such mailings please see section 4 below.
                <br />  3.7 If you correspond with us (e.g. using email), we may retain the content of your correspondence together with your contact details and our responses where necessary for our and your legitimate interests in order that we can appropriately manage your relationship with us, to reply to your correspondence and for training and quality purposes. This may include us recording and monitoring our telephone calls with you for such purposes
                <br />  3.8 Where we need to collect personal data in connection with any services which you request or order from us and you fail to provide that data when requested, we may not be able to provide such services or perform any related contract that we have or are trying to enter into with you.
                <br />  Third Party Disclosures
                <br />  3.9 We may disclose personal data about you:
                <br />  3.9.1 with third parties who are directly involved in dealing with any request, enquiry, complaint or other correspondence submitted by you or in the performance of any contract between us and you;
                <br />  3.9.2 with third parties who are processing your information on our behalf for specified purposes in accordance with our instructions;
                <br />  3.9.3 with third parties who are providing us with professional advice where necessary for our legitimate interests in obtaining such advice and permitted by law;
                <br />  3.9.4 where we are legally required to do so;
                <br />  3.9.5 in connection with criminal investigations, legal proceedings or prospective legal proceedings where necessary for our related legitimate interests and where permitted by law;
                <br />  3.9.6 where necessary for our legitimate interests and permitted by law in establishing, exercising or defending our legal rights (including providing information to others for the purposes of fraud prevention) and protecting the rights, property or safety of My Free Course (Starch UK Ltd), our customers, our employees or others; and
                <br />  3.9.7 where we have stated or informed you otherwise (e.g. in this Privacy Statement or on the Site).
                <br />  3.10 We may also disclose your personal information to third parties in the event that we propose to sell or buy any business or assets (in which case we may disclose your personal information to the prospective seller or buyer of such business or assets and/or their professional advisors) or if we or substantially all of our assets are proposed to be acquired by a third party. Any such disclosure will be made where necessary for the legitimate interests of us and/or the third party in respect of the proposed transaction; however we will not transfer your personal information to any such third party unless we are satisfied that they are able to provide an adequate level of protection in respect of your personal information.
                <br />  3.11 Except as provided in this Privacy Statement, we will not provide or disclose your information to third parties without your express consent for any purpose (including but not limited to direct marketing). we do not sell personal information under any circumstances.
                <br />  <b>4. How do you stop marketing information being sent to you?</b>
                <br />  You can ask that such information is no longer sent to you by emailing us at support@my-freecourse.co.uk or writing to us at My Free Course (Starch UK Ltd)71–75 Shelton Street, Covent Garden, London, WC2H 9JQ clicking on the appropriate link in the footer of any of our marketing emails or by following the instructions in any related SMS message. Any such withdrawal of consent will not affect the lawfulness of our processing based on your consent before you withdrew it.
                <br />  <b>5. Security</b>
                <br />  5.1 We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know that information. However, the Internet is global and no data transmitted via the Internet can be guaranteed by us to be completely secure during transmission. We cannot guarantee the security of any information that you disclose online and we will not be responsible for any breach of security unless this is due to our negligence or wilful default. It is possible that the information you provide to us will be temporarily transferred via a route outside the European Economic Area as it passes between us.
                <br />  5.2 In certain circumstances we may use the services of a supplier outside the European Economic Area (EEA) for purposes referred to in this Statement (for example, we use the services of HubSpot and Dotmailer to store our email marketing database and send electronic communications). This may involve your personal information being processed by the relevant supplier on our behalf outside of the EEA. If we do this, we will require the relevant supplier to put in place appropriate technical and organisational data protection security measures (for example, as part of our contract with the relevant supplier or by verifying that they operate under the EU-U.S. Privacy Shield framework (please see www.privacyshield.gov for further details).
                <br />  5.3 We have put in place procedures to deal with any suspected data security breach and will notify you and/or any applicable regulator of a suspected breach where we are legally required to do so.
                <br />  <b>6. Access to your information</b>
                <br />  6.1 You have a legal right to see a copy of the personal information that we keep about you and to correct any inaccuracies, subject to certain exemptions. In some circumstances you may also have the right to:
                <br />  6.1.1 request that we erase any personal information held about you;
                <br />  6.1.2 restrict our processing of your personal information (for example, to ask to suspend the processing of personal information to establish its accuracy or the reasons for processing it);
                <br />  6.1.3 data portability (i.e. to request the transfer of personal information to a third party); and
                <br />  6.1.4 object to our processing of your personal information where we are relying on a legitimate interest (or those of a third party).
                <br />  6.2 Requests of the nature set out in section 6.1 above should be made in writing to the [Data Compliance Officer], My Free Course (Starch UK Ltd) 71–75 Shelton Street, Covent Garden, London, WC2H 9JQ. Please contact the same address if you have any reason to believe that information we hold about you is inaccurate. We will respond to your request as soon as possible and, in any event, within one month from the date of receiving the request. Please note that we may, where legally permitted, reject any such request or limit the scope of our response (e.g. if, in the circumstances, the right does not apply to you).
                <br />  6.3 In accordance with applicable data protection legislation, we follow security procedures in the storage and disclosure of your information. We may therefore request proof of your identity before disclosing certain information to you or complying with any other request of or a nature described in section 6.1 above.
                <br />  6.4 You will not generally have to pay a fee to exercise any of your rights described in section 6.1 above. However, we may charge a reasonable fee if you make a request to see a copy of your personal information which is clearly unfounded or excessive. Alternatively we may refuse to comply with your request in such circumstances.
                <br />  <b>7. Retention and Destruction of your Personal Information</b>
                <br />  7.1 Any personal information held by us in relation to any of the purposes described in this Privacy Statement will be retained by us for as long as we need it to fulfil the purpose(s) for which it was collected. Further details of our specific retention periods are set out in our information retention policy (a copy of which is available upon request).
                <br />  7.2 For example, if you have consented to receiving our newsletter, your preferences in that regard will be retained by us unless and until:
                <br />  7.2.1 we cease producing our newsletter; or
                <br />  7.2.2 you withdraw your consent,
                <br />  following which we will destroy or suppress such personal data without delay so that you no longer receive our newsletter.
                <br />  7.3 Save for any contact preferences suppressed under section 7.2 above, your information will be securely destroyed at the end of the relevant retention period described or otherwise referred to in this section 7.
                <br />  7.4 Whilst taking into consideration our legal obligations, we will on an ongoing basis: review the length of time that we retain personal data for; consider the purpose or purposes for which we hold the personal data in deciding whether (and for how long) to retain it for; securely delete personal data that is no longer needed for such purpose or purposes; and update, archive or securely delete information if it goes out of date.
                <br />  <b>8. Cookies</b>
                <br />  8.1 The Site uses “cookies” for our legitimate interests in ensuring that you receive the best possible visitor experience. Cookies are small files which are sent by a web server to an individual’s computer which are then stored on that computer’s hard drive. A cookie contains text, and is like an identification card which can only be translated by the server it originated from.
                <br />  8.2 Cookies cannot tell us information such as your email address, which we can only collect where you or your employer tells us, for example if you provide your details in a capture form.
                <br />  8.3 The Site uses the following cookies for the following purposes:
                <br />  To collect information about how visitors use the Site. We use the information to compile reports and to help us improve the Site. The cookies collect information in an anonymous form, including the number of visitors to the Site, where visitors have come to the Site from and the pages they visited.

                <br />  8.4 Most internet browsers allow you to prevent cookies being stored on your computer. Alternatively you may be able to configure your browser to accept all cookies or to notify you when a cookie is offered by our server. You may also be able to delete all cookies currently stored on your web browser.
                <br />  8.5 Some parts of the Site require cookies to be enabled in order to function correctly. Therefore unless you change your browser settings you will automatically accept cookies from the Site.
                <br />  8.6 We may also collect information about where you are on the Internet (e.g. the URL you came from, IP address, and domain types like .co.uk and .com), your browser type, the country where your computer is located, the parts of the Site that were viewed during your visit and any search terms that you entered using the Site. We may collect this information even if you are not registered with us, and will use it for our legitimate interests in administering and improving the Site, for internal operations (including troubleshooting, data analysis, testing, research, statistical and survey purposes) and as part of our efforts to keep the Site safe and secure.
                <br />  8.7 We do not use cookies to market or promote the interests of any third party.
                <br />  8.8 For further information about cookies and how they are used, please visit www.aboutcookies.org.
                <br />  <b>9. How will you know if we make any changes to this Privacy Statement?</b>
                <br />  We may amend this Privacy Statement from time to time. You are bound by any changes we make to this Privacy Statement with effect from the date of the change. If we make any substantial changes to the way in which we use your personal information collected in connection with our services or the Site we will notify you (e.g. by posting a notice on the log-in page for the Site). You can view the current version of our Privacy Statement at any time by clicking on the Privacy Statement link on the log-in page for the Site.
                <br />  <b>10. What about third party websites that you can access via the Site?</b>
                <br />  10.1 The Site contains links to other websites which are outside our control and are not covered by this Privacy Statement. If you access other websites using the links provided, the operators of those websites may collect personal information from you which will be used in accordance with their respective privacy policies which you should read. We are not liable for the practices of such third party website operators in respect of your personal information.
                <br />  10.2 You acknowledge that any information that you post using our social media facilities (such as Facebook, YouTube and Twitter) will be viewable by anybody who visits those websites and that such information is also subject to the relevant provider’s privacy policy. You are advised to consult each such privacy policy to see how they will use your data.
                <br />  10.3 You acknowledge that any content that you post using our social media facilities will be considered non-confidential and non-proprietary, and that we have the right to monitor, remove, use, copy, distribute and disclose to third parties any such content for any purpose permitted by law.
                <br />  10.4 You acknowledge that we will cooperate with any person, law enforcement authority or court order requesting or directing us to disclose the identity of anyone posting or sending material using any of our social media facilities in breach of any law (whether civil or criminal) or the legal rights of any party (including but not limited to intellectual property rights and rights to privacy).
                <br />  <b>11. Contact</b>
                <br />  If you have any questions about this Privacy Statement or our treatment of personal data, please write to us at Starch UK Ltd (t/a, My Free Course), 71–75 Shelton Street, Covent Garden, London, WC2H 9JQ. Or email support@my-freecourse.co.uk

            </p>
        </div>
    );
};

export default Conditions;
