import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import { trackApplyButton } from "../common/analyticsGA4";
const AccessToSocialWork = () => {
    let product = {
        "courseTitle": "Access to Higher Education Social Work",
        "courseLength": "12 months",
        "awardingBody": "NCFE / TQUK",
        "courseLevel": "Level 3",
        "funding": "Advance Learner Loan",
        "learningMethods": "Online learning portal",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1743673398/Courses/Untitled_design_lw9fto.png",
    }
    console.log(product)
    const Benefits = (props) => {
        const text = props.text;
        const newText = text.split("•").map((str) => (
            <li>{str}</li>
        ));
        return newText;
    };
    let Content = (props) => {
        let text = props.text;
        const res = String(text);
        console.log(res);
        let newText = res.split("•").map((str) => (
            <li>{str} </li>
        ));
        return newText;
    };
    function demo(arr1, arr2) {
        let Farray = [];
        for (let i = 0; i < arr1.length - 1; i++) {
            let object = {
                heading: arr1[i],
                desc: arr2[i]
            }
            Farray.push(object);
        }
        return Farray;
    }
    return (
        <div>
            <Helmet>
                <title>{product.courseTitle}</title>
            </Helmet>

            <div className="jumbotron text-center splash only-on-desktop">
                <h3 className="heading-jumbotron">{product.courseTitle}</h3>
            </div>
            <div className="only-on-mobile">
                <img
                    src={product.img}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
            <div className="container-sm course-details-p" xs={12}>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
                        <div className="row" style={{ display: "flex !important" }}>
                            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                                <img
                                    src={product.img}
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                                <h3 className="coursedetailh2" style={{ margin: "auto" }}>{product.courseTitle}</h3>
                            </div>
                        </div>

                        <br />

                        <h1 className="coursedetailh2 only-on-mobile" style={{ margin: "auto", fontSize: "22px", color: "#222222" }}>{product.courseTitle}</h1>
                        <br />
                        <HashLink
                            className="btn btn-primary co-button vv only-on-mobile"
                            to={'/form/' + "Access to Social Work" + '#top'}
                            onClick={() => trackApplyButton()}
                        >
                            Apply Now
                        </HashLink>
                        <br />
                        <h6><b>Description: </b></h6>
                        <p>
                            Make a real difference in people’s lives with a career in social work. As a Social Worker, you’ll provide vital support to individuals facing challenges, helping them navigate difficult situations and access the care they need. While the role can be demanding, the impact you make is life-changing.
                            <br /><br />
                            This Access to Higher Education Diploma (Social Work) is your pathway to university-level study, equipping you with the knowledge and skills required for a social work degree. Equivalent to three A Levels, this course covers key topics such as safeguarding, social justice, and identifying at-risk individuals. You’ll gain an in-depth understanding of the reasons people need support and how to create tailored care plans to improve their well-being.
                        </p>
                        <br />
                        <b>Areas of Study: </b>
                        <ul>
                            <li>Roles and responsibilities of a social worker</li>
                            <li>Understanding the welfare state</li>
                            <li>Biopsychology:Behaviour and the Brain</li>
                        </ul>
                        <br />
                        <h5><strong>Key Features of the Advanced Learner Loan:</strong></h5>

                        <p><strong>No Income or Credit Check</strong> – Loan approval is not based on your financial status.</p>

                        <p><strong>Flexible Repayments</strong> – You only start repaying the loan <strong>once you earn above the repayment threshold</strong>.</p>

                        <p><strong>Covers Tuition Fees</strong> – The loan is paid directly to your learning provider to cover course costs.</p>

                        <p><strong>No Age Limit</strong> – Available for learners aged <strong>19 and above</strong>, with no upper age restriction.</p>

                        <p><strong>Loan Write-Off for University Graduates</strong> – If you go on to complete a <strong>higher education degree</strong>, your Advanced Learner Loan may be written off, subject to government policies.</p>

                        <br />
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1743009613/Site-pics/image_2025-03-26_221954782_a0eras.png" style={{ width: '100%' }} />
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title="Content">
                                <br />
                                <p>
                                    <b>Module 1: Academic Writing Skills</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to structure a written response</li>
                                        <li>Be able to develop a structured response to a plan</li>
                                        <li>Be able to present the response appropriately for audience and purpose</li>
                                        <li>Understand how to apply academic writing principles to own work</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 2: Reading and Note Making</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand a range of reading strategies</li>
                                        <li>Understand the use of language in terms of the purpose and context of a range of texts</li>
                                        <li>Be able to use methods for developing notes from a range of sources</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 3: Perspectives in Psychology</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand different psychological perspectives</li>
                                        <li>Understand the differences between alternative psychological perspectives</li>
                                        <li>Understand the application of psychological perspectives to contemporary issues in psychology</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 4: Crime and Society</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand crime and deviance</li>
                                        <li>Understand crime statistics</li>
                                        <li>Understand theoretical explanations of crime</li>
                                        <li>Understand social and cultural patterns of crime and criminality</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 5: Developmental Psychology</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand the nature/nurture debate in developmental psychology</li>
                                        <li>Understand early socialisation and the formation of attachment</li>
                                        <li>Understand cognitive development and the ways in which children process and use information</li>
                                        <li>Understand the application of developmental psychology theories in the real world</li>
                                        <li>Understand psychological changes that accompany ageing</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 6: Family</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the relationship between the family and the wider society</li>
                                        <li>Understand role relationships within the family</li>
                                        <li>Understand the influence of feminism on the study of the family</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 7: The role and responsibilities of a social worker</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the influence of feminism on the study of the family</li>
                                        <li>Understand social work values and principles</li>
                                        <li>Understand the range of employment opportunities available for a Social worker</li>
                                        <li>Understand the importance of current legislation for the Social Worker and social work</li>
                                        <li>Understand a range of client groups and the services available to them</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 8: Cognitive Psychology</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand theories relating to cognitive psychology</li>
                                        <li>
                                            Understand the contribution of cognitive psychology research to our understanding of three of the following:
                                            <ul>
                                                <li>Development</li>
                                                <li>Memory</li>
                                                <li>Attention</li>
                                                <li>Perception</li>
                                                <li>Language and thought</li>
                                            </ul>
                                        </li>
                                    </ul>

                                </p>

                                <p>
                                    <b>Module 9: Understanding the Welfare State</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand the historical development of the British Welfare State</li>
                                        <li>Understand the main welfare services available in contemporary British society</li>
                                        <li>Understand the differing ideologies of welfare</li>
                                        <li>Understand a current contemporary issue in welfare provision</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 10: Behaviour and the Brain</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand the concept of localisation of function</li>
                                        <li>Understand the strengths and weaknesses of methods of investigating the relationship between the brain and behaviour</li>
                                        <li>Understand the interaction between the brain and behaviour</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 11: Social Care Provisions</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand the adult and child social work provisions in the UK</li>
                                        <li>Understand the contributions of the voluntary and private sector in the provision of care</li>
                                        <li>Understand the differing welfare needs of groups of people</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 12: Poverty in Contemporary Britain</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand definitions of poverty</li>
                                        <li>Understand the historical context of poverty in Britain</li>
                                        <li>Understand the different methods used to measure poverty</li>
                                        <li>Understand theoretical explanations of poverty</li>
                                        <li>Understand explanations about why certain social groups are more likely to experience poverty</li>
                                        <li>Understand recent policies for tackling poverty in Britain</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 13: Biopsychology: Behaviour and Drugs</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand how neurotransmitters in the brain control behaviour</li>
                                        <li>Understand the action of drugs on synaptic transmission</li>
                                        <li>Understand how psychoactive drugs change behaviour</li>
                                        <li>Understand the role of serotonin antagonists and reuptake inhibitors in the treatment of disorders</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 14: Social Inequality</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand the relationship between social differentiation and social inequality</li>
                                        <li>Understand the main theoretical perspectives on social inequality</li>
                                        <li>Understand how class, gender and ethnicity influence the opportunities available to people</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 15: Responding to Prejudice and Discrimination</b> <br />
                                    <p>Upon successful completion of this module, you will:</p>
                                    <ul>
                                        <li>Understand theories for prejudice and discrimination</li>
                                        <li>Understand legal responses to discrimination and prejudice</li>
                                        <li>Understand community initiatives in overcoming discrimination and prejudice</li>
                                    </ul>
                                </p>
                                <p>
                                    In addition to the qualification units, you will also be required to complete short introductory tasks at the start of your course to support the development of your academic study skills.
                                </p>
                            </Tab>
                            <Tab eventKey="benefits" title="Benefits">
                                <h6><b>The course will help you…</b></h6>
                                <ul>
                                    <li>
                                        First step to becoming a social worker
                                    </li>
                                    <li>
                                        Learn at your pace
                                    </li>
                                    <li>
                                        100% online study
                                    </li>
                                    <li>
                                        No Exams
                                    </li>
                                    <li>
                                        Equivalent to 3 A Levels
                                    </li>
                                </ul>
                                <b>Career Aspects: </b>
                                <ul>
                                    <li>Computer Science</li>
                                    <li>Game Design</li>
                                    <li>Data Analytics</li>
                                </ul>
                                <br />
                                <b>University Applications & Entry Requirements</b>
                                <br />
                                Your allocated college will support you in applying to universities, but <b>each university sets its own admission criteria</b>. It’s your responsibility to check if your <b>Access to HE Diploma</b> and other qualifications meet the requirements of your chosen university.
                                <br /><br />
                                Common university entry requirements include:
                                <ul>
                                    <li><b>A certain number of credits</b> at <b>Merit</b> or <b>Distinction</b></li>
                                    <li>A <b>face-to-face interview</b> at the university</li>
                                    <li><b>Literacy and numeracy assessments</b> provided by the university</li>
                                    <li><b>Course-related work placements</b> or work experience </li>
                                    <li><b>GCSEs in Maths & English (Grade C/4 or equivalent Level 2 qualification)</b> such as Functional Skills or Key Skills</li>
                                </ul>
                            </Tab>

                            <Tab eventKey="eligibility" title="Eligibility">
                                <br />
                                <h6><b>Eligibility Criteria:</b></h6>
                                <ul>
                                    <li>Must be 19 or older (Must turn 19 before 31st August, 2024)</li>
                                    <li>Level 2 in Maths and English</li>
                                </ul>
                                <b>Residency Status</b>
                                <ul>
                                    <li>Be living in the UK on first day of your course</li>
                                    <li>UK /Irish Citizen or have ‘settled status’ </li>
                                    <li>Lived in the UK, British territories, Channel islands or the Isle of Man for 3 years in a row.</li>
                                    <li>Other Visa types may be eligible <a href="https://www.gov.uk/advanced-learner-loan">Advanced Learner Loan: Overview - GOV.UK</a></li>
                                </ul>
                                <p >It will be the learner’s responsibility to understand the repayment terms of the loan.</p>
                            </Tab>
                            <Tab eventKey="Learning Method" title="Learning Method">
                                <br />
                                <h6><b>Learning Method: </b></h6>
                                <p>
                                    Once you enroll, all your learning materials will be available through our online portal. If you need any accessibility arrangements, please let our team know.
                                    <br /><br />
                                    You'll receive full support throughout your course, including one-on-one online tutorials and group sessions.
                                </p>

                                <p>
                                    <b>Flexible Online Learning</b><br />
                                    Studying online allows you to fit your learning around your existing commitments. Whether you're working or caring for family, you can complete your course at your own pace and move closer to your goals.

                                </p>
                            </Tab>
                            <Tab eventKey="Assessment Method" title="Assessment Method">
                                <br />
                                <h6><b>Assessment Method: </b></h6>
                                <p>Your assignments are due at the end of each unit and will be marked by either a tutor or a computer.
                                    <br /><br />
                                    Your assigned college will provide access to MS Office through your learning portal, so you can complete your assessments easily.
                                </p>
                                <b>Qualification & Certification</b>
                                <br />
                                After completing your course, you will receive a <b>Level 3 nationally recognized qualification</b> regulated by the <b>Quality Assurance Agency for Higher Education (QAA)</b>. Your certificate is typically issued <b>12-16 weeks</b> after your final assessment is graded, but this may vary depending on the time of year.
                            </Tab>
                        </Tabs>
                        <br />
                        <hr />



                    </div>
                    <div className="col-md-4 info-box-courses">
                        <Card style={{ width: "18rem", color: "black" }} className="wd">
                            <Card.Body style={{ boxShadow: "none" }}>
                                <Card.Title>KEY INFORMATION</Card.Title>
                                <Table striped bordered>
                                    <thead>
                                        <tbody>
                                            <tr>
                                                <td>Course Length</td>
                                                <td>{product.courseLength}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Provider</td>
                                                <td>{product.awardingBody}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Level</td>
                                                <td>{product.courseLevel}</td>
                                            </tr>
                                            <tr>
                                                <td>Funding (subject to availability)</td>
                                                <td>Advance Learner Loan</td>
                                            </tr>
                                            <tr>
                                                <td>Learning Method(s)</td>
                                                <td>{product.learningMethods}</td>
                                            </tr>
                                        </tbody>
                                    </thead>
                                </Table>
                                <HashLink
                                    className="btn btn-primary co-button vv "
                                    to={'/form/' + "Access to Social Work" + '#top'}
                                    onClick={() => trackApplyButton()}
                                >
                                    Apply Now
                                </HashLink>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <section className="services section-bg" id="partners" style={{ borderTop: "1px solid #eaeaea", marginTop: "30px", paddingTop: "0px" }}>
                <div className="container">
                    <div className="row social-logo-section1" >
                        <div className="col">
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    <center>
                        <div className="row social-logo-section only-on-mobile" >
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                        </div>
                    </center>
                </div>

            </section>
        </div>
    )
}

export default AccessToSocialWork