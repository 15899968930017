import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToMount from '../../common/ScrollToMount'
import FormContainer from './FormContainer'
import { saveOppDetails, saveIdProof, updateForm } from '../../actions/formActions'
import { toast } from 'react-toastify'
import FormCompletetionSteps from './FormCompletetionSteps'

toast.configure()
const UpdateEqualOpp = ({ history }) => {
    const oppDetails = JSON.parse(localStorage.getItem('oppDetails'))
    const personalDetails = JSON.parse(localStorage.getItem('personalDetails'))

    const [ethnicOrigin, setEthnicOrigin] = useState(oppDetails?.ethnicOrigin)
    const [disabilities, setDisablities] = useState(oppDetails?.disabilities)
    const [wheelchair, setWheelchair] = useState(oppDetails?.wheelchair)
    const [firstLang, setFirstLang] = useState(oppDetails?.firstLang)
    const [resident, setResident] = useState(oppDetails?.resident)
    const [nationality, setNationality] = useState(oppDetails?.resident)
    const [inWhichCountryDoYouLive, setInWhichCountryDoYouLive] = useState(oppDetails?.inWhichCountryDoYouLive)
    const [countryOfBirth, setCountryOfBirth] = useState(oppDetails?.countryOfBirth)

    const [statementScheme, setStatementScheme] = useState(oppDetails?.statementScheme)
    const [nonEEACitizen, setNonEEACitizen] = useState(oppDetails?.nonEEACitizen)
    const [dValue1, setValue] = useState(oppDetails?.dValue1)
    const [dValue2, setValue2] = useState(oppDetails?.dValue2)
    const [dValue3, setValue3] = useState(oppDetails?.dValue3)
    const [dValue4, setValue4] = useState(oppDetails?.dValue4)
    const [dValue5, setValue5] = useState(oppDetails?.dValue5)
    const [dValue6, setValue6] = useState(oppDetails?.dValue6)
    const [dValue7, setValue7] = useState(oppDetails?.dValue7)
    const [dValue8, setValue8] = useState(oppDetails?.dValue8)
    const [dValue9, setValue9] = useState(oppDetails?.dValue9)
    const [dValue10, setValue10] = useState(oppDetails?.dValue10)
    const [dValue11, setValue11] = useState(oppDetails?.dValue11)
    const [dValue12, setValue12] = useState(oppDetails?.dValue12)
    const [dValue13, setValue13] = useState(oppDetails?.dValue13)
    const [dValue14, setValue14] = useState(oppDetails?.dValue14)
    const [dValue15, setValue15] = useState(oppDetails?.dValue15)
    const [dValue16, setValue16] = useState(oppDetails?.dValue16)
    const [dValue17, setValue17] = useState(oppDetails?.dValue17)
    const [dValue18, setValue18] = useState(oppDetails?.dValue18)
    const [adhd, setAdhd] = useState(oppDetails?.adhd)

    const [criminalConv, setCriminalConvictions] = useState(oppDetails?.criminalConv)
    const [show, setShow] = useState(false)
    const [showFileUpload, setFileUpload] = useState(false)
    const [validated, setValidated] = useState(false)
    const [perField, setPerField] = useState()
    const [id1, setID1] = useState()
    const [id2, setID2] = useState()
    const [id3, setID3] = useState()

    const dispatch = useDispatch()
    const uploadImage = (file) => {
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'equaloppertunities')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log('URL FIles', res.url)
                return res.url
            })
    }
    useEffect(() => {
        if (disabilities === 'Yes') {
            setShow(true)
        } else {
            setShow(false)
        }

        if (nonEEACitizen === 'Yes') {
            setFileUpload(true)
        } else {
            setFileUpload(false)
        }
    }, [])

    const submitHandler = async (e) => {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            if (disabilities === 'Yes') {
                let images = [id1, id2, id3]
                let promises = []
                images.forEach((image) => promises.push(uploadImage(image)))
                Promise.all([...promises])
                    .then((values) => {
                        dispatch(
                            saveOppDetails({
                                ethnicOrigin,
                                disabilities,
                                wheelchair,
                                firstLang,
                                resident,
                                nationality,
                                statementScheme,
                                nonEEACitizen,
                                criminalConv,
                                perField,
                                dValue1,
                                dValue2,
                                dValue3,
                                dValue4,
                                dValue5,
                                dValue6,
                                dValue7,
                                dValue8,
                                dValue9,
                                dValue10,
                                dValue11,
                                dValue12,
                                dValue13,
                                dValue14,
                                dValue15,
                                dValue16,
                                dValue17,
                                dValue18,
                                attachment1: values[0] || '',
                                attachment2: values[1] || '',
                                attachment3: values[2] || '',
                                inWhichCountryDoYouLive,
                                countryOfBirth,
                                adhd: adhd
                            })
                        )
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                const data = new FormData()

                const incFormId = localStorage.getItem('incFormId')
                const response = await axios.post(process.env.REACT_APP_API_URL + '/users/change_status', {
                    formId: incFormId,
                    status: "Edited",
                })
                history.push('/update/declaration')
            } else {
                let images = [id1, id2, id3]
                let promises = []
                images.forEach((image) => promises.push(uploadImage(image)))
                Promise.all([...promises])
                    .then((values) => {
                        console.log('Values', values)
                        dispatch(
                            saveOppDetails({
                                ethnicOrigin,
                                disabilities,
                                wheelchair,
                                firstLang,
                                perField,
                                resident,
                                nationality,
                                statementScheme,
                                nonEEACitizen,
                                criminalConv,
                                attachment1: values[0] || '',
                                attachment2: values[1] || '',
                                attachment3: values[2] || '',
                                inWhichCountryDoYouLive,
                                countryOfBirth
                            })
                        )
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                const incFormId = localStorage.getItem('incFormId')
                const response = await axios.post(process.env.REACT_APP_API_URL + '/users/change_status', {
                    formId: incFormId,
                    status: "Edited",
                })
                const data = new FormData()
                history.push('/update/declaration')
            }
        }
        setValidated(true)
    }
    const handleChange = (e) => {
        setDisablities(e.target.value)
        if (e.target.value === 'Yes') {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    const handleChange1 = (e) => {
        setNonEEACitizen(e.target.value)
        if (e.target.value === 'Yes') {
            setFileUpload(true)
        } else {
            setFileUpload(false)
        }
    }

    return (
        <FormContainer>
            <ScrollToMount />
            <FormCompletetionSteps step1 step2 step3 step4 step5 step6 />

            <h1>Step 6: Equal Oppurtunities</h1>

            <Form noValidate validated={validated} onSubmit={submitHandler}>
                <Form.Group controlId='ethnicOrigin'>
                    <Form.Label>What is your Ethnic Origin?</Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={ethnicOrigin}
                        onChange={(e) => setEthnicOrigin(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='English/Welsh/Scottish/Northern Irish/British'>
                            English/Welsh/Scottish/Northern Irish/British
                        </option>
                        <option value='Irish'>Irish</option>
                        <option value='Gypsy or Irish traveller'>Gypsy or Irish traveller</option>
                        <option value='Any other white background'>
                            Any other white background
                        </option>
                        <option value='White and Black Carribean'>White and Black Carribean</option>
                        <option value='White and Black African'>White and Black African</option>
                        <option value='White and Asian'>White and Asian</option>
                        <option value='Any other mixed/multiple ethnic background'>
                            Any other mixed/multiple ethnic background
                        </option>
                        <option value='Indian'>Indian</option>
                        <option value='Pakistani'>Pakistani</option>
                        <option value='Bangladeshi'>Bangladeshi</option>
                        <option value='Chinese'>Chinese</option>
                        <option value='Any other Asian background'>
                            Any other Asian background
                        </option>
                        <option value='African'>African</option>
                        <option value='Caribbean'>Caribbean</option>
                        <option value='Any other Black/African/Caribbean'>
                            Any other Black/African/Caribbean
                        </option>
                        <option value='Arab'>Arab</option>
                        <option value='Any other ethnic group'>Any other ethnic group</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='disabilities'>
                    <Form.Label>Do you have any disabilities or learning difficulties?</Form.Label>
                    <Form.Control as='select' required value={disabilities} onChange={handleChange}>
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                {show && (
                    <>
                        <Form.Group controlId=''>
                            <Form.Label>Select those which apply</Form.Label>
                            <br />
                            <input
                                type={'checkbox'}
                                value={'dValue1'}
                                checked={dValue1}
                                onChange={(e) => {
                                    if (!dValue1) {
                                        setValue(e.target.value)
                                    } else {
                                        setValue('')
                                    }
                                }}
                            />
                            Visual Impairment
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue2'}
                                checked={dValue2}
                                onChange={(e) => {
                                    if (!dValue2) {
                                        setValue2(e.target.value)
                                    } else {
                                        setValue2('')
                                    }
                                }}
                            />
                            Hearing Impairment
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue3'}
                                checked={dValue3}
                                onChange={(e) => {
                                    if (!dValue3) {
                                        setValue3(e.target.value)
                                    } else {
                                        setValue3('')
                                    }
                                }}
                            />
                            Disability affecting mobility
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue4'}
                                checked={dValue4}
                                onChange={(e) => {
                                    if (!dValue4) {
                                        setValue4(e.target.value)
                                    } else {
                                        setValue4('')
                                    }
                                }}
                            />
                            Profound complex disabilities
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue5'}
                                checked={dValue5}
                                onChange={(e) => {
                                    if (!dValue5) {
                                        setValue5(e.target.value)
                                    } else {
                                        setValue5('')
                                    }
                                }}
                            />
                            Social and emotional difficulties
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue6'}
                                checked={dValue6}
                                onChange={(e) => {
                                    if (!dValue6) {
                                        setValue6(e.target.value)
                                    } else {
                                        setValue6('')
                                    }
                                }}
                            />
                            Mental Health Difficulties
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue7'}
                                checked={dValue7}
                                onChange={(e) => {
                                    if (!dValue7) {
                                        setValue7(e.target.value)
                                    } else {
                                        setValue7('')
                                    }
                                }}
                            />
                            Moderate Learning Difficulties
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue8'}
                                checked={dValue8}
                                onChange={(e) => {
                                    if (!dValue8) {
                                        setValue8(e.target.value)
                                    } else {
                                        setValue8('')
                                    }
                                }}
                            />
                            Severe Learning Difficulties
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue9'}
                                checked={dValue9}
                                onChange={(e) => {
                                    if (!dValue9) {
                                        setValue9(e.target.value)
                                    } else {
                                        setValue9('')
                                    }
                                }}
                            />
                            Dyslexia
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue10'}
                                checked={dValue10}
                                onChange={(e) => {
                                    if (!dValue10) {
                                        setValue10(e.target.value)
                                    } else {
                                        setValue10('')
                                    }
                                }}
                            />
                            Dyscalculia
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue11'}
                                checked={dValue11}
                                onChange={(e) => {
                                    if (!dValue11) {
                                        setValue11(e.target.value)
                                    } else {
                                        setValue11('')
                                    }
                                }}
                            />
                            Autism Spectrum Disorder
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'adhd'}
                                checked={adhd}
                                onChange={(e) => {
                                    setAdhd(!adhd)
                                }}
                            />
                            ADHD/ADD
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue13'}
                                checked={dValue13}
                                onChange={(e) => {
                                    if (!dValue13) {
                                        setValue13(e.target.value)
                                    } else {
                                        setValue13('')
                                    }
                                }}
                            />
                            Temporary disability after illness or accident
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue14'}
                                checked={dValue14}
                                onChange={(e) => {
                                    if (!dValue14) {
                                        setValue14(e.target.value)
                                    } else {
                                        setValue14('')
                                    }
                                }}
                            />
                            Speech, language and communication needs
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue15'}
                                checked={dValue15}
                                onChange={(e) => {
                                    if (!dValue15) {
                                        setValue15(e.target.value)
                                    } else {
                                        setValue15('')
                                    }
                                }}
                            />
                            Other physical disabilities
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue16'}
                                checked={dValue16}
                                onChange={(e) => {
                                    if (!dValue16) {
                                        setValue16(e.target.value)
                                    } else {
                                        setValue16('')
                                    }
                                }}
                            />
                            Medical condition e.g. epilepsy, asthma, diabetes
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue17'}
                                checked={dValue17}
                                onChange={(e) => {
                                    if (!dValue17) {
                                        setValue17(e.target.value)
                                    } else {
                                        setValue17('')
                                    }
                                }}
                            />
                            Other learning difficulties
                            <br></br>
                            <input
                                type={'checkbox'}
                                value={'dValue18'}
                                checked={dValue18}
                                onChange={(e) => {
                                    if (!dValue18) {
                                        setValue18(e.target.value)
                                    } else {
                                        setValue18('')
                                    }
                                }}
                            />
                            Other disabilities
                            <br></br>
                        </Form.Group>
                    </>
                )}

                <Form.Group controlId='wheelchair'>
                    <Form.Label>Are you a wheelchair user? </Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={wheelchair}
                        onChange={(e) => setWheelchair(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='firstLang'>
                    <Form.Label>is english your first language? </Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={firstLang}
                        onChange={(e) => setFirstLang(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='nationality'>
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                    >
                        <option disabled selected value="">[Please select one]</option>
                        <option value="afghan">Afghan</option>
                        <option value="albanian">Albanian</option>
                        <option value="algerian">Algerian</option>
                        <option value="american">American</option>
                        <option value="andorran">Andorran</option>
                        <option value="angolan">Angolan</option>
                        <option value="antiguans">Antiguans</option>
                        <option value="argentinean">Argentinean</option>
                        <option value="armenian">Armenian</option>
                        <option value="australian">Australian</option>
                        <option value="austrian">Austrian</option>
                        <option value="azerbaijani">Azerbaijani</option>
                        <option value="bahamian">Bahamian</option>
                        <option value="bahraini">Bahraini</option>
                        <option value="bangladeshi">Bangladeshi</option>
                        <option value="barbadian">Barbadian</option>
                        <option value="barbudans">Barbudans</option>
                        <option value="batswana">Batswana</option>
                        <option value="belarusian">Belarusian</option>
                        <option value="belgian">Belgian</option>
                        <option value="belizean">Belizean</option>
                        <option value="beninese">Beninese</option>
                        <option value="bhutanese">Bhutanese</option>
                        <option value="bolivian">Bolivian</option>
                        <option value="bosnian">Bosnian</option>
                        <option value="brazilian">Brazilian</option>
                        <option value="british">British</option>
                        <option value="British National (Overseas)">British National (Overseas)</option>
                        <option value="bruneian">Bruneian</option>
                        <option value="bulgarian">Bulgarian</option>
                        <option value="burkinabe">Burkinabe</option>
                        <option value="burmese">Burmese</option>
                        <option value="burundian">Burundian</option>
                        <option value="cambodian">Cambodian</option>
                        <option value="cameroonian">Cameroonian</option>
                        <option value="canadian">Canadian</option>
                        <option value="cape verdean">Cape Verdean</option>
                        <option value="central african">Central African</option>
                        <option value="chadian">Chadian</option>
                        <option value="chilean">Chilean</option>
                        <option value="chinese">Chinese</option>
                        <option value="colombian">Colombian</option>
                        <option value="comoran">Comoran</option>
                        <option value="congolese">Congolese</option>
                        <option value="costa rican">Costa Rican</option>
                        <option value="croatian">Croatian</option>
                        <option value="cuban">Cuban</option>
                        <option value="cypriot">Cypriot</option>
                        <option value="czech">Czech</option>
                        <option value="danish">Danish</option>
                        <option value="djibouti">Djibouti</option>
                        <option value="dominican">Dominican</option>
                        <option value="dutch">Dutch</option>
                        <option value="east timorese">East Timorese</option>
                        <option value="ecuadorean">Ecuadorean</option>
                        <option value="egyptian">Egyptian</option>
                        <option value="emirian">Emirian</option>
                        <option value="equatorial guinean">Equatorial Guinean</option>
                        <option value="eritrean">Eritrean</option>
                        <option value="estonian">Estonian</option>
                        <option value="ethiopian">Ethiopian</option>
                        <option value="fijian">Fijian</option>
                        <option value="filipino">Filipino</option>
                        <option value="finnish">Finnish</option>
                        <option value="french">French</option>
                        <option value="gabonese">Gabonese</option>
                        <option value="gambian">Gambian</option>
                        <option value="georgian">Georgian</option>
                        <option value="german">German</option>
                        <option value="ghanaian">Ghanaian</option>
                        <option value="greek">Greek</option>
                        <option value="grenadian">Grenadian</option>
                        <option value="guatemalan">Guatemalan</option>
                        <option value="guinea-bissauan">Guinea-Bissauan</option>
                        <option value="guinean">Guinean</option>
                        <option value="guyanese">Guyanese</option>
                        <option value="haitian">Haitian</option>
                        <option value="herzegovinian">Herzegovinian</option>
                        <option value="honduran">Honduran</option>
                        <option value="hungarian">Hungarian</option>
                        <option value="icelander">Icelander</option>
                        <option value="indian">Indian</option>
                        <option value="indonesian">Indonesian</option>
                        <option value="iranian">Iranian</option>
                        <option value="iraqi">Iraqi</option>
                        <option value="irish">Irish</option>
                        <option value="israeli">Israeli</option>
                        <option value="italian">Italian</option>
                        <option value="ivorian">Ivorian</option>
                        <option value="jamaican">Jamaican</option>
                        <option value="japanese">Japanese</option>
                        <option value="jordanian">Jordanian</option>
                        <option value="kazakhstani">Kazakhstani</option>
                        <option value="kenyan">Kenyan</option>
                        <option value="kittian and nevisian">Kittian and Nevisian</option>
                        <option value="kuwaiti">Kuwaiti</option>
                        <option value="kyrgyz">Kyrgyz</option>
                        <option value="laotian">Laotian</option>
                        <option value="latvian">Latvian</option>
                        <option value="lebanese">Lebanese</option>
                        <option value="liberian">Liberian</option>
                        <option value="libyan">Libyan</option>
                        <option value="liechtensteiner">Liechtensteiner</option>
                        <option value="lithuanian">Lithuanian</option>
                        <option value="luxembourger">Luxembourger</option>
                        <option value="macedonian">Macedonian</option>
                        <option value="malagasy">Malagasy</option>
                        <option value="malawian">Malawian</option>
                        <option value="malaysian">Malaysian</option>
                        <option value="maldivan">Maldivan</option>
                        <option value="malian">Malian</option>
                        <option value="maltese">Maltese</option>
                        <option value="marshallese">Marshallese</option>
                        <option value="mauritanian">Mauritanian</option>
                        <option value="mauritian">Mauritian</option>
                        <option value="mexican">Mexican</option>
                        <option value="micronesian">Micronesian</option>
                        <option value="moldovan">Moldovan</option>
                        <option value="monacan">Monacan</option>
                        <option value="mongolian">Mongolian</option>
                        <option value="moroccan">Moroccan</option>
                        <option value="mosotho">Mosotho</option>
                        <option value="motswana">Motswana</option>
                        <option value="mozambican">Mozambican</option>
                        <option value="namibian">Namibian</option>
                        <option value="nauruan">Nauruan</option>
                        <option value="nepalese">Nepalese</option>
                        <option value="new zealander">New Zealander</option>
                        <option value="ni-vanuatu">Ni-Vanuatu</option>
                        <option value="nicaraguan">Nicaraguan</option>
                        <option value="nigerien">Nigerian</option>
                        <option value="north korean">North Korean</option>
                        <option value="northern irish">Northern Irish</option>
                        <option value="norwegian">Norwegian</option>
                        <option value="omani">Omani</option>
                        <option value="pakistani">Pakistani</option>
                        <option value="palauan">Palauan</option>
                        <option value="panamanian">Panamanian</option>
                        <option value="papua new guinean">Papua New Guinean</option>
                        <option value="paraguayan">Paraguayan</option>
                        <option value="peruvian">Peruvian</option>
                        <option value="polish">Polish</option>
                        <option value="portuguese">Portuguese</option>
                        <option value="qatari">Qatari</option>
                        <option value="romanian">Romanian</option>
                        <option value="russian">Russian</option>
                        <option value="rwandan">Rwandan</option>
                        <option value="saint lucian">Saint Lucian</option>
                        <option value="salvadoran">Salvadoran</option>
                        <option value="samoan">Samoan</option>
                        <option value="san marinese">San Marinese</option>
                        <option value="sao tomean">Sao Tomean</option>
                        <option value="saudi">Saudi</option>
                        <option value="scottish">Scottish</option>
                        <option value="senegalese">Senegalese</option>
                        <option value="serbian">Serbian</option>
                        <option value="seychellois">Seychellois</option>
                        <option value="sierra leonean">Sierra Leonean</option>
                        <option value="singaporean">Singaporean</option>
                        <option value="slovakian">Slovakian</option>
                        <option value="slovenian">Slovenian</option>
                        <option value="solomon islander">Solomon Islander</option>
                        <option value="somali">Somali</option>
                        <option value="south african">South African</option>
                        <option value="south korean">South Korean</option>
                        <option value="spanish">Spanish</option>
                        <option value="sri lankan">Sri Lankan</option>
                        <option value="sudanese">Sudanese</option>
                        <option value="surinamer">Surinamer</option>
                        <option value="swazi">Swazi</option>
                        <option value="swedish">Swedish</option>
                        <option value="swiss">Swiss</option>
                        <option value="syrian">Syrian</option>
                        <option value="taiwanese">Taiwanese</option>
                        <option value="tajik">Tajik</option>
                        <option value="tanzanian">Tanzanian</option>
                        <option value="thai">Thai</option>
                        <option value="togolese">Togolese</option>
                        <option value="tongan">Tongan</option>
                        <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                        <option value="tunisian">Tunisian</option>
                        <option value="turkish">Turkish</option>
                        <option value="tuvaluan">Tuvaluan</option>
                        <option value="ugandan">Ugandan</option>
                        <option value="ukrainian">Ukrainian</option>
                        <option value="uruguayan">Uruguayan</option>
                        <option value="uzbekistani">Uzbekistani</option>
                        <option value="venezuelan">Venezuelan</option>
                        <option value="vietnamese">Vietnamese</option>
                        <option value="welsh">Welsh</option>
                        <option value="yemenite">Yemenite</option>
                        <option value="zambian">Zambian</option>
                        <option value="zimbabwean">Zimbabwean</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>In which country do you normally live?</Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={inWhichCountryDoYouLive}
                        onChange={(e) => setInWhichCountryDoYouLive(e.target.value)}
                    >
                        <option selected disabled value="">Please Select One</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                        <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo - Brazzaville</option>
                        <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curacao">Curaçao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czechia</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="England">England</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                        <option value="Holy See (Vatican City State)">Vatican City</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">North Korea</option>
                        <option value="Korea, Republic of">South Korea</option>
                        <option value="Kosovo">Kosovo</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia</option>
                        <option value="Moldova, Republic of">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar (Burma)</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Curaçao</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn Islands</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Réunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthelemy">St. Barthélemy</option>
                        <option value="Saint Helena">St. Helena</option>
                        <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                        <option value="Saint Lucia">St. Lucia</option>
                        <option value="Saint Martin">St. Martin</option>
                        <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                        <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Scotland">Scotland</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten">Sint Maarten</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                        <option value="Swaziland">Eswatini</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syria</option>
                        <option value="Taiwan, Province of China">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Vietnam</option>
                        <option value="Wales">Wales</option>
                        <option value="Virgin Islands, British">British Virgin Islands</option>
                        <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                        <option value="Wallis and Futuna">Wallis & Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                {(localStorage.getItem('countryOfBirth') === "true") && <>
                    <Form.Group>
                        <Form.Label>Country of Birth</Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={countryOfBirth}
                            onChange={(e) => setCountryOfBirth(e.target.value)}
                        >
                            <option selected value="">Please Select One</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Aland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                            <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo - Brazzaville</option>
                            <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curacao">Curaçao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czechia</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="England">England</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                            <option value="Holy See (Vatican City State)">Vatican City</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">North Korea</option>
                            <option value="Korea, Republic of">South Korea</option>
                            <option value="Kosovo">Kosovo</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia</option>
                            <option value="Moldova, Republic of">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar (Burma)</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Curaçao</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn Islands</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Réunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Barthelemy">St. Barthélemy</option>
                            <option value="Saint Helena">St. Helena</option>
                            <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                            <option value="Saint Lucia">St. Lucia</option>
                            <option value="Saint Martin">St. Martin</option>
                            <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                            <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Scotland">Scotland</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Sint Maarten">Sint Maarten</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                            <option value="Swaziland">Eswatini</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syria</option>
                            <option value="Taiwan, Province of China">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Vietnam</option>
                            <option value="Wales">Wales</option>
                            <option value="Virgin Islands, British">British Virgin Islands</option>
                            <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                            <option value="Wallis and Futuna">Wallis & Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                </>}
                <Form.Group controlId='resident'>
                    <Form.Label>Have you been resident in the UK for the last 3 years?</Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={resident}
                        onChange={(e) => setResident(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='statementScheme'>
                    <Form.Label>
                        Have you obtained settled status under then EU Settlement Scheme?
                    </Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={statementScheme}
                        onChange={(e) => setStatementScheme(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='nonEEACitizen'>
                    <Form.Label>
                        Are you a non EEA citizen with permission from the UK government to live in
                        the UK?
                    </Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={nonEEACitizen}
                        onChange={handleChange1}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                {showFileUpload && (
                    <>
                        <Form.Group controlId='perField'>
                            <Form.Label>
                                What immigration permissions do you currently hold in the UK?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={perField}
                                onChange={(e) => setPerField(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value='Asylum Seeker'>Asylum Seeker</option>
                                <option value='Indefinite Leave'>Indefinite Leave</option>
                                <option value='Exceptional / Discretionary Leave'>
                                    Exceptional / Discretionary Leave
                                </option>
                                <option value='Humanitarian Protection'>
                                    Humanitarian Protection
                                </option>
                                <option value='Refugee'>Refugee</option>
                                <option value='Famile Member of EU citizen'>
                                    Famile Member of EU citizen
                                </option>
                                <option value='British/EU/EEA/Citizen '>
                                    British/EU/EEA/Citizen{' '}
                                </option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Proof of residency status covering the last 3 years is required.
                                Providing proof of rour residency status is a mandatory requirement
                                in order to access funding. If this is not provided you will be
                                deemed ineligible for funding and not permitted to access your
                                choosen course.
                            </Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Group controlId='id1'>
                                <Form.Control
                                    type='file'
                                    onChange={(e) => setID1(e.target.files[0])}
                                ></Form.Control>
                                {oppDetails?.attachment1 && (
                                    <span>File Attached. Overwrite by Uploading</span>
                                )}
                            </Form.Group>
                            <Form.Group controlId='id2'>
                                <Form.Control
                                    type='file'
                                    onChange={(e) => setID2(e.target.files[0])}
                                ></Form.Control>
                                {oppDetails?.attachment2 && (
                                    <span>File Attached. Overwrite by Uploading</span>
                                )}
                            </Form.Group>
                            <Form.Group controlId='id3'>
                                <Form.Control
                                    type='file'
                                    onChange={(e) => setID3(e.target.files[0])}
                                ></Form.Control>
                                {oppDetails?.attachment3 && (
                                    <span>File Attached. Overwrite by Uploading</span>
                                )}
                            </Form.Group>
                        </Form.Group>
                    </>
                )}
                <Form.Group controlId='criminalConv'>
                    <Form.Label>
                        Do you have any criminal convictions, cautions or pending prosecutions?
                    </Form.Label>
                    <Form.Control
                        as='select'
                        required
                        value={criminalConv}
                        onChange={(e) => setCriminalConvictions(e.target.value)}
                    >
                        <option disabled selected value=''>
                            [Please select one]
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Button
                    onClick={(e) => {
                        window.location.href = '/update/qualification'
                    }}
                    variant='primary'
                    className='mr-5 back-Button-form'
                >
                    Back
                </Button>

                <Button
                    type='submit'
                    variant='primary'
                    className='next-Button-form'
                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                >
                    Save Progress & Continue
                </Button>
            </Form>
        </FormContainer>
    )
}

export default UpdateEqualOpp
