import {
    DETAILFORM_SAVE_REQUEST,
    DETAILFORM_SAVE_SUCCESS,
    DETAILFORM_SAVE_FAIL,
    FORM_SAVE_SUCCESS,
    FORM_SAVE_FAIL,
    EMPLOYEMENTFORM_SAVE_SUCCESS,
    EMPLOYEMENTFORM_SAVE_FAIL,
    QUALIFICATIONFORM_SAVE_SUCCESS,
    QUALIFICATIONFORM_SAVE_FAIL,
    OPPURTUNITIESFORM_SAVE_SUCCESS,
    OPPURTUNITIESFORM_SAVE_FAIL,
    DECLARATIONFORM_SAVE_SUCCESS,
    DECLARATIONFORM_SAVE_FAIL,
    PROOF_SAVE_SUCCESS,
    PROOF_SAVE_FAIL,
    PROOF_SAVE_REQUEST,
    SIGN_SAVE_SUCCESS,
    SIGN_SAVE_FAIL,
    EMERGENCYDETAILS_SAVE_SUCCESS,
    EMERGENCYDETAILS_SAVE_FAIL,
    FORM_DETAILS_REQUEST,
    FORM_DETAILS_SUCCESS,
    FORM_DETAILS_FAIL,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_SUCCESS,
    UPDATE_DETAILS_FAIL,
    DELETE_FORM_REQUEST,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAIL,
    DELETE_IN_FORM_REQUEST,
    DELETE_IN_FORM_SUCCESS,
    DELETE_IN_FORM_FAIL,
    LEADSFORM_SAVE_REQUEST,
    LEADSFORM_SAVE_SUCCESS,
    LEADSFORM_SAVE_FAIL,
    TWINSLEADSFORM_SAVE_REQUEST,
    TWINSLEADSFORM_SAVE_SUCCESS,
    TWINSLEADSFORM_SAVE_FAIL,
} from '../constants/formConstans'
import axios from 'axios'
import { facebookApiConversation } from '../services/facebook'

export const saveDetailsForm = (data) => async (dispatch) => {
    const { firstName, middleName, lastName, email, chosenCollege, chosenContract, mobile, appliedCourse, PostCodeBL } = data
    try {
        dispatch({
            type: DETAILFORM_SAVE_REQUEST,
            payload: { firstName, middleName, lastName, email, chosenCollege, chosenContract, mobile, appliedCourse, PostCodeBL }
        })
        const response = await axios.post(
            process.env.REACT_APP_API_URL + '/users/registerDetails/',
            {
                firstName,
                middleName,
                lastName,
                email,
                chosenCollege,
                chosenContract,
                mobile,
                appliedCourse,
                PostCodeBL,
            }
        )
        const responseData = response;
        if (responseData.data.success) {
            await facebookApiConversation("Leads(Server)", mobile, email, firstName, lastName, '', '', '', '', responseData.data.data._id)
            dispatch({
                type: DETAILFORM_SAVE_SUCCESS,
                payload: responseData.data,
            })
            localStorage.setItem(
                'detailsFormData',
                JSON.stringify({
                    firstName,
                    middleName,
                    lastName,
                    email,
                    mobile,
                    appliedCourse,
                    PostCodeBL,
                })
            )
            localStorage.setItem('PostCodeBL', PostCodeBL)
            localStorage.setItem('incFormId', responseData.data.data._id)
            localStorage.setItem('appliedCourse', appliedCourse)
            localStorage.setItem('selectedCollege', chosenCollege)
            localStorage.setItem('selectedContract', chosenContract)
        }
        return responseData
    } catch (error) {
        console.log("error", error.message)
        dispatch({
            type: DETAILFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}


//rejected step 1 form submission
export const saveRejectedForms = async (data) => {
    const { firstName, middleName, lastName, email, mobile, appliedCourse, PostCodeBL } = data
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + '/users/registerfailed/',
            {
                firstName,
                middleName,
                lastName,
                email,
                mobile,
                appliedCourse,
                PostCodeBL,
            }
        )
    } catch (e) {
        console.log(e)
    }
}

export const savePersonalDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_SAVE_SUCCESS,
            payload: data,
        })

        data = {
            ...data,
            firstName: JSON.parse(localStorage.getItem('detailsFormData')).firstName,
            // middleName: JSON.parse(localStorage.getItem('detailsFormData')).middleName,
            lastName: JSON.parse(localStorage.getItem('detailsFormData')).lastName,
            postcode: JSON.parse(localStorage.getItem('detailsFormData')).PostCodeBL,
            email: JSON.parse(localStorage.getItem('detailsFormData')).email,
        }
        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/savePersonalDetails/', {
            personalDetails: data,
            incFormId: incFormId,
        })
        localStorage.setItem('personalDetails', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: FORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const editPersonalDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editPersonalDetails/', {
            formId: localStorage.getItem('incFormId'),
            personalDetails: data,
        })
        localStorage.setItem('personalDetails', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const editEmergencyDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editEmergencyDetails/', {
            formId: localStorage.getItem('incFormId'),
            emergencyDetails: data,
        })
        localStorage.setItem('emergencyDetails', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const editEmploymentDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editEmploymentDetails/', {
            formId: localStorage.getItem('incFormId'),
            employmentDetails: data,
        })
        localStorage.setItem('employmentDetails', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const saveEmergencyDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: EMERGENCYDETAILS_SAVE_SUCCESS,
            payload: data,
        })

        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/saveEmergencyDetails/', {
            emergencyDetails: data,
            incFormId: incFormId,
        })

        localStorage.setItem('emergencyDetails', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: EMERGENCYDETAILS_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const saveEmploymentDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: EMPLOYEMENTFORM_SAVE_SUCCESS,
            payload: data,
        })

        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/saveEmploymentDetails/', {
            employmentDetails: data,
            incFormId: incFormId,
        })

        localStorage.setItem('employmentDetails', JSON.stringify(data))
    } catch (error) {
        console.log(error)
        dispatch({
            type: EMPLOYEMENTFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
export const saveEnglishAssessmentRnnForm = (data) => async (dispatch) => {
    const incFormId = localStorage.getItem('incFormId')
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/assessment/single-english-rnn-form/', {
            formId: incFormId,
            assessment: { english: data },
        })
    } catch (e) {
        console.log(e)
    }
}

export const saveQualificationDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: QUALIFICATIONFORM_SAVE_SUCCESS,
            payload: data,
        })
        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/saveQualificationDetails/', {
            qualificationDetails: data,
            incFormId: incFormId,
        })

        localStorage.setItem('qualificationDetails', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: QUALIFICATIONFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const editQualificationDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editQualificationDetails/', {
            formId: localStorage.getItem('incFormId'),
            qualificationDetails: data,
        })
        localStorage.setItem('qualificationDetails', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const saveOppDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: OPPURTUNITIESFORM_SAVE_SUCCESS,
            payload: data,
        })

        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/saveOppDetails/', {
            oppDetails: data,
            incFormId: incFormId,
        })

        localStorage.setItem('oppDetails', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: OPPURTUNITIESFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const editOppDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editOppDetails/', {
            formId: localStorage.getItem('incFormId'),
            oppDetails: data,
        })
        localStorage.setItem('oppDetails', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const saveDeclarationDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: DECLARATIONFORM_SAVE_SUCCESS,
            payload: data,
        })

        const incFormId = localStorage.getItem('incFormId')
        await axios.post(process.env.REACT_APP_API_URL + '/users/saveDeclaration/', {
            declaration: data,
            incFormId: incFormId,
        })

        localStorage.setItem('declaration', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: DECLARATIONFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const editDeclarationDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editDeclarationDetails/', {
            formId: localStorage.getItem('incFormId'),
            declaration: data,
        })
        localStorage.setItem('declaration', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

export const saveSignDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SIGN_SAVE_SUCCESS,
            payload: data,
        })

        localStorage.setItem('sign', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: SIGN_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const editSignDetails = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editSignDetails/', {
            formId: localStorage.getItem('incFormId'),
            signature: data,
        })
        localStorage.setItem('sign', JSON.stringify(data))
    } catch (e) {
        console.log(e)
    }
}

//Not used function
export const saveIdProof = (dat) => async (dispatch) => {
    try {
        dispatch({
            type: PROOF_SAVE_REQUEST,
        })

        const { data } = await axios.post(process.env.REACT_APP_API_URL + '/form/ids/', dat)

        dispatch({
            type: PROOF_SAVE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PROOF_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

const clearLocalStorage = () => {
    localStorage.removeItem('detailsFormData')
    localStorage.removeItem('PostCodeBL')
    localStorage.removeItem('appliedCourse')
    localStorage.removeItem('incFormId')
    localStorage.removeItem('personalDetails')
    localStorage.removeItem('emergencyDetails')
    localStorage.removeItem('employmentDetails')
    localStorage.removeItem('qualificationDetails')
    localStorage.removeItem('oppDetails')
    localStorage.removeItem('declaration')
    localStorage.removeItem('sign')
    localStorage.removeItem('signature')
    localStorage.removeItem('nationalId')
    localStorage.removeItem('employmentdetailsNecessary')
    localStorage.removeItem('proofs')
    localStorage.removeItem('certificateLink')
    localStorage.removeItem('selectedCollege')
    localStorage.removeItem('selectedContract')
    localStorage.removeItem('fullTimeEducationElse')
    localStorage.removeItem('inWhichCountryDoYouLive')
    localStorage.removeItem('softEnglishTest')
}

const getLocalStorageData = () => {
    return {
        detailsFormData: {
            PostCodeBL: localStorage.getItem('PostCodeBL'),
            appliedCourse: localStorage.getItem('appliedCourse'),
        },
        incFormId: localStorage.getItem('incFormId'),
        completedBy: localStorage.getItem('completedBy'),
        collegeID: localStorage.getItem('selectedCollege'),
        contractId: localStorage.getItem('selectedContract'),
        personalDetails: JSON.parse(localStorage.getItem('personalDetails')),
        emergencyDetails: JSON.parse(localStorage.getItem('emergencyDetails')),
        employmentDetails: JSON.parse(localStorage.getItem('employmentDetails')),
        qualificationDetails: JSON.parse(localStorage.getItem('qualificationDetails')),
        oppDetails: JSON.parse(localStorage.getItem('oppDetails')),
        declaration: JSON.parse(localStorage.getItem('declaration')),
        signature: JSON.parse(localStorage.getItem('sign')),
    }
}

export const createForm = async (nationalId, certificateLink, groupArray, typeOfProof) => {
    try {
        let bar = getLocalStorageData()
        const incFormId = localStorage.getItem('incFormId')
        const { data } = await axios.post(process.env.REACT_APP_API_URL + '/form/add', {
            ...bar,
            incFormId: incFormId,
            nationalId: nationalId,
            certificateLink: certificateLink,
            callTime: groupArray,
            typeOfProof: typeOfProof,
            isCallCenter: false,
        })
        await facebookApiConversation("Completed Registration", bar.personalDetails.telephone, bar.personalDetails.email, bar.personalDetails.firstName, bar.personalDetails.lastName, bar.personalDetails.dob, bar.personalDetails.city, bar.personalDetails.postcode, bar.personalDetails.county, data.data._id)
        clearLocalStorage()

        return data
        // emailConfirmation.sendConfirmation(
        // 	bar.personalDetails.email,
        // 	'My Free Course',
        // 	"Congratulations, your course application has been received and passed on to one of our college partners.     \n\n What happens next?…     \n\n 1-Within 7 working days you will be called directly by the college enrolment team. They will go through what to expect from the course, how they will support you and answer any further questions you may have.\n\n 2- Once enrolled you will be sent an enrolment confirmation and login email.\n\n 3- Soon after you will be allocated a dedicated tutor. They will make contact with you and give you more information about your chosen course.    \n\n If you have any queries regarding your enrolment or haven't heard back from the college within 7 working days you can contact them using the below details. \n\n  Thanks again and we wish you all the best with your course.    \n\n Kind regards,  \n\n The My Free Course Team \n\n Web: www.myfreecourse.co.uk  \n\n Facebook: https://www.facebook.com/MyFreeCourseEngland/"
        // )
    } catch (error) {
        console.log(error)
    }
}


export const completeCallCenterForm = async (nationalId, certificateLink, groupArray, typeOfProof, finalForm) => {
    console.log(finalForm)
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_URL + '/form/add', {
            detailsFormData: finalForm.detailsFormData,
            personalDetails: finalForm.personalDetails,
            emergencyDetails: finalForm.emergencyDetails,
            employmentDetails: finalForm.employmentDetails,
            qualificationDetails: finalForm.qualificationDetails,
            oppDetails: finalForm.oppDetails,
            declaration: finalForm.declaration,
            nationalId: nationalId,
            certificateLink: certificateLink,
            incFormId: finalForm._id,
            collegeID: finalForm.collegeID,
            contractId: finalForm.contractId,
            callTime: groupArray,
            completedBy: finalForm.completedBy,
            typeOfProof: typeOfProof,
            signature: JSON.parse(localStorage.getItem('sign')),
            isCallCenter: true,
        })
        return data.success
    } catch (error) {
        console.log(error)
    }
}



export const completeFTCallCenterForm = async (nationalId, certificateLink, groupArray, typeOfProof, finalForm) => {
    console.log(finalForm)
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_URL + '/form/add', {
            detailsFormData: finalForm.detailsFormData,
            personalDetails: finalForm.personalDetails,
            emergencyDetails: finalForm.emergencyDetails,
            employmentDetails: finalForm.employmentDetails,
            qualificationDetails: finalForm.qualificationDetails,
            oppDetails: finalForm.oppDetails,
            declaration: finalForm.declaration,
            nationalId: nationalId,
            certificateLink: certificateLink,
            incFormId: finalForm._id,
            collegeID: finalForm.collegeID,
            contractId: finalForm.contractId,
            callTime: groupArray,
            completedBy: finalForm.completedBy,
            typeOfProof: typeOfProof,
            whyEnrollInthisProgram: finalForm.whyEnrollInthisProgram,
            anythingToBeAwareOf: finalForm.anythingToBeAwareOf,
            signature: JSON.parse(localStorage.getItem('sign')),
            isFtCollege: true,
        })
        return data.success
    } catch (error) {
        console.log(error)
    }
}


export const editProofDetails = async (nationalId, certificateLink) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/users/editProofDetails', {
            formId: localStorage.getItem('incFormId'),
            nationalId: nationalId ? nationalId : localStorage.getItem('nationalId'),
            certificateLink: certificateLink
                ? certificateLink
                : localStorage.getItem('certificateLink'),
        })
        clearLocalStorage()
    } catch (e) {
        console.log(e)
    }
}

export const updateForm = (dat) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_DETAILS_REQUEST,
        })

        const { data } = await axios.patch(
            process.env.REACT_APP_API_URL + '/form/updateform/' + localStorage.getItem('formid'),
            dat
        )

        dispatch({
            type: UPDATE_DETAILS_SUCCESS,
            payload: data,
        })
        localStorage.setItem('updateForm', JSON.stringify(dat))
    } catch (error) {
        dispatch({
            type: UPDATE_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getUserDetails = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_DETAILS_REQUEST,
        })

        const { data } = await axios.get('/form/' + id)
        dispatch({
            type: FORM_DETAILS_SUCCESS,
            payload: data,
        })
        localStorage.setItem('formDetails', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: FORM_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

const setLocalStorageItems = (data, id) => {
    localStorage.setItem('incFormId', id)
    localStorage.setItem('PostCodeBL', data.PostCodeBL)
    localStorage.setItem('appliedCourse', data.appliedCourse)
    localStorage.setItem('selectedCollege', data.collegeID)
    localStorage.setItem('selectedContract', data.contractId)
    localStorage.setItem(
        'detailsFormData',
        JSON.stringify({
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile,
            appliedCourse: data.appliedCourse,
            PostCodeBL: data.PostCodeBL,
        })
    )
    if (data.personalDetails) {
        localStorage.setItem('personalDetails', JSON.stringify(data.personalDetails))
    }
    if (data.emergencyDetails) {
        localStorage.setItem('emergencyDetails', JSON.stringify(data.emergencyDetails))
    }
    if (data.employmentDetails) {
        localStorage.setItem('employmentDetails', JSON.stringify(data.employmentDetails))
    }
    if (data.qualificationDetails) {
        localStorage.setItem('qualificationDetails', JSON.stringify(data.qualificationDetails))
    }
    if (data.oppDetails) {
        localStorage.setItem('oppDetails', JSON.stringify(data.oppDetails))
    }
    if (data.declaration) {
        localStorage.setItem('declaration', JSON.stringify(data.declaration))
    }
    if (data.signature) {
        localStorage.setItem('signature', data.signature)
    }
    if (data.nationalId) {
        localStorage.setItem('nationalId', data.nationalId)
    }
    if (data.certificateLink) {
        localStorage.setItem('certificateLink', data.certificateLink)
    }
    if (data.assignedToCallCenter) {
        if (data.status !== "Pending")
            localStorage.setItem('completedBy', data.assignedToCallCenter)
    }
}

export const getIncUserDetails = async (id) => {
    clearLocalStorage()
    try {
        const { data } = await axios.get('/form/getIncform/' + id)
        console.log(data)
        const college = await axios.post('/college/get_college_by_id/', {
            collegeID: data.data.collegeID
        })
        localStorage.setItem('employmentdetailsNecessary', college.data.data.employmentdetailsNecessary)
        localStorage.setItem('proofs', college.data.data.proofs)
        setLocalStorageItems(data.data, id)
    } catch (error) {
        console.log(error)
        return error
    }
}


export const getCallCenterFormUserDetails = async (id) => {
    clearLocalStorage()
    try {
        const { data } = await axios.get('/formsbycall/getCallCenterform/' + id)
        const college = await axios.post('/college/get_college_by_id/', {
            collegeID: data.data.collegeID
        })
        let d = data.data
        return { 'data': d, "proofs": college.data.data.proofs };
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getCallCenterFormsForUser = async (id) => {
    clearLocalStorage()
    try {
        const { data } = await axios.get('/formsbycall/getforms/' + id)
        return data.data;
    } catch (error) {
        console.log(error)
        return error
    }
}
export const getAllCallCenterForms = async () => {
    clearLocalStorage()
    try {
        const { data } = await axios.get('/formsbycall/')
        return data.data;
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getCompleteUserFormDetails = async (id) => {
    clearLocalStorage()
    try {
        const { data } = await axios.get('/form/get_complete_user_form_details/' + id)
        setLocalStorageItems(data.data, id)
    } catch (error) {
        console.log(error)
    }
}

export const deleteFormEntry = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FORM_REQUEST,
        })

        const { data } = await axios.delete('form/deleteform/' + id)
        dispatch({
            type: DELETE_FORM_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DELETE_FORM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteCollege = async (id) => {
    try {
        const data = await axios.delete(process.env.REACT_APP_API_URL + '/college/delete/' + id)
    } catch (error) {
        console.log(error)
    }
}
export const deleteContracts = async (id) => {
    try {
        const data = await axios.delete(process.env.REACT_APP_API_URL + '/contracts/delete/' + id)
    } catch (error) {
        console.log(error)
    }
}

export const deleteCourse = async (id) => {
    try {
        const data = await axios.delete(process.env.REACT_APP_API_URL + '/course/delete/' + id)
    } catch (error) {
        console.log(error)
    }
}

export const deleteinFormEntry = (id) => async (dispatch) => {

    try {
        dispatch({
            type: DELETE_IN_FORM_REQUEST,
        })

        const { data } = await axios.delete('form/deleteincompleteform/' + id)
        dispatch({
            type: DELETE_IN_FORM_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DELETE_IN_FORM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

// Assessments

export const saveEnglishAssessment = async (data) => {
    try {
        await axios.post(process.env.REACT_APP_API_URL + '/assessment/single-english/', {
            formId: data.formId,
            assessment: data.data,
        })
    } catch (e) {
        console.log(e)
    }
}

export const saveLongEnglishAssessment = async (data) => {

    try {
        await axios.post(process.env.REACT_APP_API_URL + '/assessment/long-english/', {
            formId: data.formId,
            data: data.data
        })
    } catch (e) {
        console.log(e)
    }
}

// New Swindon COllege Declaration
export const newDeclarationNSC = async (formId) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/nsc-declaration', {
            formId: formId
        })
        return response
    } catch (e) {
        console.log(e)
    }
}


//Call center form submission
export const saveFormsFilledByCallCenter = async (data) => {
    const {
        detailsFormData,
        personalDetails,
        emergencyDetails,
        employmentDetails,
        qualificationDetails,
        oppDetails,
        StaffID,
        collegeID,
        contractId } = data
    try {
        let response = await axios.post(process.env.REACT_APP_API_URL + '/formsbycall/add', {
            detailsFormData: detailsFormData,
            personalDetails: personalDetails,
            emergencyDetails: emergencyDetails,
            employmentDetails: employmentDetails,
            qualificationDetails: qualificationDetails,
            oppDetails: oppDetails,
            collegeID: collegeID,
            StaffID: StaffID,
            contractId: contractId
        })
    } catch (e) {
        console.log(e)
    }
}


// Leads Form

export const saveLeadsForm = (data) => async (dispatch) => {
    const { firstName, lastName, email, mobile, appliedCourse, PostCodeBL, residencyStatus } = data


    //not functional anymore
    // try {
    //     dispatch({
    //         type: LEADSFORM_SAVE_REQUEST,
    //     })

    //     const { data } = await axios.post(
    //         process.env.REACT_APP_API_URL + '/leads/add/',
    //         {
    //             firstName,
    //             lastName,
    //             email,
    //             mobile,
    //             appliedCourse,
    //             PostCodeBL,
    //             residencyStatus
    //         }
    //     )

    //     dispatch({
    //         type: LEADSFORM_SAVE_SUCCESS,
    //         payload: data.data,
    //     })
    // } catch (error) {
    //     console.log(error)
    //     dispatch({
    //         type: LEADSFORM_SAVE_FAIL,
    //         payload:
    //             error.response && error.response.data.message
    //                 ? error.response.data.message
    //                 : error.message,
    //     })
    // }
}


// Leads Form Twins

export const saveTwinsLeadsForm = (data) => async (dispatch) => {
    const {
        title,
        firstName,
        lastName,
        dob,
        mobile,
        address,
        email,
        postCode,
        preferredContactMethod,
        preferredContactTime,
        nationalInsurance,
        disabilities,
        learningDifficulties,
        appliedCourse
    } = data

    try {
        dispatch({
            type: TWINSLEADSFORM_SAVE_REQUEST,
        })

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + '/twinsleads/add/',
            {
                title,
                firstName,
                lastName,
                dob,
                mobile,
                address,
                email,
                postCode,
                preferredContactMethod,
                preferredContactTime,
                nationalInsurance,
                disabilities,
                learningDifficulties,
                appliedCourse
            }
        )

        dispatch({
            type: TWINSLEADSFORM_SAVE_SUCCESS,
            payload: data.data,
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: TWINSLEADSFORM_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}



// FT leads Form

export const saveFTLeadsForm = async (data) => {
    const { firstName, lastName, email, mobile, PostCodeBL, visaStatus, earningLess, liveInLondon } = data

    try {

        const response = await axios.post(
            process.env.REACT_APP_API_URL + '/leads/add/',
            {
                firstName,
                lastName,
                email,
                mobile,
                PostCodeBL,
                visaStatus,
                earningLess,
                liveInLondon
            }
        )
        await facebookApiConversation("Leads(Server)", mobile, email, firstName, lastName, '', '', '', '')
        return response

    } catch (error) {
        console.log(error)
    }
}




export async function getInquiryPOstcode(postcode) {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/leads/postcode/' + postcode)
        return response.data
    } catch (e) {
        console.log(e)
        return 400
    }
}


export async function completeSignupContract(data) {
    const {
        coursesList,
        whitelist,
        pdf,
        proofs,
        collegeID,
        contractID } = data
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/contracts/finalizing-contract/',
            {
                coursesList,
                whitelist,
                pdf,
                proofs,
                collegeID,
                contractID
            }
        )
        console.log(response)
        return response.data
    } catch (e) {
        console.log(e)
        return 400
    }
}


