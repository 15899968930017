import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from './FormContainer'
import { saveDetailsForm, saveRejectedForms } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
// import { Apple } from '@material-ui/icons'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { trackAccessPopupCancel, trackFailedEligibility, trackQualifiedEligibility } from '../../common/analyticsGA4'

const DetailsForm = ({ history }) => {
    const form = useSelector((state) => state.form)
    const { course } = useParams();

    const { detailsFormData } = form

    const [firstName, setFirstName] = useState(detailsFormData.firstName)
    // const [middleName, setMiddleName] = useState(detailsFormData.middleName)
    const [lastName, setLastName] = useState(detailsFormData.lastName)
    const [email, setEmail] = useState(detailsFormData.email)
    const [PostCodeBL, setPCodeBL] = useState()
    const [mobile, setMobile] = useState(detailsFormData.mobile)
    const [appliedCourse, setCourse] = useState(course || detailsFormData.appliedCourse)
    const [residencyStatus, setResStatus] = useState(detailsFormData.residencyStatus)
    const [ans, setAns] = useState(detailsFormData.ans)
    const [deviceAvail, setDeviceAvail] = useState(detailsFormData.ans)
    const [validated, setValidated] = useState(false)
    // const [collegeData, setCollegeData] = useState()
    const [allCourses, setAllCourses] = React.useState([])
    const [accessPopup, setAccessPopup] = useState(false)
    // const [contracts, setContracts] = useState();

    useEffect(async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL + '/course/')
        let data = response.data.data.sort((a, b) => a.courseCode.localeCompare(b.courseCode))
        // data.push({ courseCode: "Access in education" })
        // data.push({ courseCode: "Access in health and social care" })
        data.push({ courseCode: "Access in health science professions" })
        data.push({ courseCode: "Access to Biomedical Science" })
        data.push({ courseCode: "Access to Engineering" })
        data.push({ courseCode: "Access to Computing" })
        data.push({ courseCode: "Access in Medical And Health Science" })
        data.push({ courseCode: "Access in psychology" })
        data.push({ courseCode: "Access to Midwifery" })
        data.push({ courseCode: "Access to Nursing" })
        // data.push({ courseCode: "Functional Skills English" })
        // data.push({ courseCode: "Functional Skills Maths" })
        data.push({ courseCode: "Access to Social Work" })
        data.push({ courseCode: "Access in paramedic science" })
        setAllCourses(data)

        // let collegeDataRaw = await axios.get(process.env.REACT_APP_API_URL + '/admin/collegedata')
        // setCollegeData(collegeDataRaw.data.data)

        // let contract = await axios.get(process.env.REACT_APP_API_URL + "/contracts/all")
        // setContracts(contract.data.data)


    }, [])

    // useEffect(() => {
    //     if (appliedCourse === 'Access in health science professions' ||
    //         appliedCourse === 'Access to Biomedical Science' ||
    //         appliedCourse === 'Access to Engineering' ||
    //         appliedCourse === 'Access to Computing' ||
    //         appliedCourse === 'Access in Medical And Health Science'
    //     ) {
    //         setAccessPopup(true)
    //     }
    // }, [appliedCourse])


    const postCode = async () => {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/form/college-finder/', {
            appliedCourse: appliedCourse,
            PostCodeBL: PostCodeBL,
        })
        if (res.data.success) {
            const chosenContract = res.data.contract
            const chosenCollege = res.data.college
            localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
            localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
            localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
            localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
            localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
            localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
            localStorage.setItem('proofs', chosenCollege.proofs)
            localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
            localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)
            return { 'college': chosenCollege._id, 'contract': chosenContract._id, 'courses': null }
        } else if (res.data.courses) {
            return { 'college': null, 'contract': null, 'courses': res.data.courses }
        } else {
            return null
        }

    }

    const checkPhone = (phone) => {
        if (phone.toString().length === 11) {
            return true
        } else {
            return false
        }
    }
    function scrollTtoView() {
        document.getElementById('field-applied-course').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const duplicateTest = async () => {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/form/duplicate-exists/', {
            appliedCourse: appliedCourse,
            PostCodeBL: PostCodeBL,
        })
    }

    const dispatch = useDispatch()

    const submitHandler = async (e) => {
        const form = e.currentTarget
        // setTimeout(function () {}, 5000)
        e.preventDefault()


        let func = await postCode()
        const chosenCollege = func ? func.college : null
        const chosenContract = func ? func.contract : null
        let suggestedCourses = func ? func.courses : []

        if (checkPhone(mobile) === false) {
            e.preventDefault()
            e.stopPropagation()
            toast.error("Only 11 characters allowed in Phone number")
        }
        else if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()

        } else if (chosenCollege === null) {
            if (suggestedCourses.length > 0) {
                e.preventDefault()
                e.stopPropagation()
                confirmAlert({
                    title: 'Not Available',
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    message: "This course is currently unavailable fully funded in your postcode area. We have updated the list of available funded courses based on your postcode. \n \n Please choose a different course.",
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => scrollTtoView()
                        }
                    ]
                });
                suggestedCourses.sort()
                suggestedCourses = suggestedCourses.map(x => {
                    return { courseCode: x }
                })
                setAllCourses(suggestedCourses)
                setCourse('')
            } else {
                e.preventDefault()
                e.stopPropagation()
                saveRejectedForms({
                    firstName,
                    // middleName,
                    lastName,
                    email,
                    mobile,
                    appliedCourse,
                    PostCodeBL
                })
                trackFailedEligibility()
                toast.error("Unfortunately it doesn’t look like you qualify for one of our funded courses.")
                alert("Unfortunately it doesn’t look like you qualify for one of our funded courses.\nThis could be for one of the below reasons:\n\n1- Not living in England\n2- The course you are applying for is not currently funded by one of our college partners.\n3- You live in a devolved area, so funding for a course may only be available via a local college or course provider.\n\nIf you feel this is an error, please contact us directly.");
            }
        }
        else {
            e.preventDefault()
            if (ans === 'No' || ans === '') {
                alert('Sorry, you did not qualify for a free course')
                window.location.reload('/details')
            } 
            // else if (deviceAvail === 'No' || deviceAvail === '') {
            //     alert('Sorry, you did not qualify for a free course')
            //     window.location.reload('/details')

            // }
             else {
                e.preventDefault()
                const responseAPI = await dispatch(saveDetailsForm({
                    firstName,
                    // middleName,
                    lastName,
                    email,
                    chosenCollege,
                    chosenContract,
                    mobile,
                    appliedCourse,
                    ans,
                    PostCodeBL
                }))
                console.log("responseAPI: ", responseAPI)
                if (responseAPI.data.success) {

                    if (chosenCollege === '676fc6eac6cf1ffc3d18417c') {
                        const res = await axios.get(process.env.REACT_APP_API_URL + '/form/check-non-devolved/' + PostCodeBL)
                        console.log("response from postchecker: ", res)

                        if (res.data.included) {
                            toast('Congratulations you may be eligible to complete this course with funding.')
                        } else {
                            toast('Congratulations you may be eligible to complete this course with an Advance Learner loan.')
                        }
                    }
                    trackQualifiedEligibility()
                    let thisCouse = allCourses.find(n => n.courseCode === course)
                    if (thisCouse?.affiliatedCourse) {
                        let affCourse = allCourses.find(n => n.courseCode === thisCouse.affiliatedCourse)
                        if (affCourse.status === 'enabled') {
                            localStorage.setItem('affiliatedCourse', thisCouse.affiliatedCourse)
                            localStorage.setItem('level', thisCouse.courseLevel)
                        }
                    }

                    history.push('/personal')
                } else {
                    console.log("Failed")
                    if (responseAPI.data.data) {
                        console.log(responseAPI.data.data._id)
                        const userConfirmed = window.confirm("Your incomplete application is already pending. You will be redirected to complete it.");
                        if (userConfirmed) {
                            window.location.href = "https://portal.myfreecourse.co.uk/complete-form?id=" + responseAPI.data.data._id;
                        }
                    } else {
                        toast("Your Completed Application is already being processed by the team.")
                    }
                }


            }
        }
        setValidated(true)
    }

    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <FormContainer>
                <h1> Funded Course Application Form</h1>

                {/* {(appliedCourse === 'Access in health science professions' ||
                    appliedCourse === 'Access to Biomedical Science' ||
                    appliedCourse === 'Access to Engineering' ||
                    appliedCourse === 'Access to Computing' ||
                    appliedCourse === 'Access in Medical And Health Science') ? <div className='text-left mt-5 mb-3 form-notice'>
                    The courses provided will require an Advance Learner Loan.
                    <br />
                    <b>To complete your course application you will need an in date form of Identification proof and your national insurance number.
                        <br /><br />
                        Accepted ID proofs include:
                        <ul>
                            <li>
                                Full Drivers Licence
                            </li>
                            <li>
                                Passport
                            </li>
                            <li>
                                Birth Certificate
                            </li>
                            <li>
                                National ID Card
                            </li>
                            <li>
            Utility bill
            </li>
                        </ul>
                    </b></div>
                    : <div className='text-left mt-5 mb-3 form-notice'>
                        The courses provided are government funded. As a condition of claiming the funding on your behalf, the college is required to carry out an ID check.
                        <br />
                        This is to make sure the course funding is going to a genuine UK or EU citizen.<br /><br />
                        <b>To complete your course application and to be approved for funding you will need an in date form of identification proof and your national insurance number.
                            <br /><br />
                            Accepted ID proofs include:
                            <ul>
                                <li>
                                    Full Drivers Licence
                                </li>
                                <li>
                                    Passport
                                </li>
                                <li>
                                    Birth Certificate
                                </li>
                                <li>
                                    National ID Card
                                </li>
                                <li>
            Utility bill
            </li>
                            </ul>
                        </b></div>} */}
                <br />
                <FormCompletetionSteps step1 />
                <div className='text-left mt-5 mb-3' id='field-applied-course'>
                    <h1>Step 1: Eligibility Check</h1>
                    <p>To see if you qualify please answer the following questions.</p>
                </div>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Group controlId='appliedCourse' >
                        <Form.Label>
                            <b>Which course are you applying for?</b>{' '}
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={appliedCourse}
                            onChange={(e) => setCourse(e.target.value)}
                        >
                            <option disabled selected value=''>Please Select One</option>
                            {allCourses.map(x => <option value={x.courseCode}>{x.courseCode}</option>)}

                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(appliedCourse === "Under-standing Mental Health" || appliedCourse === "Preparing to work in Adult Social Care level 3") && (<div className='text-left mt-5 mb-3 form-notice'>
                        To qualify you must live in London and either be unemployed and on benefits or in paid employment earning less than £21,547 (excluding benefits) per annum (evidence such as most recent payslip as proof of earnings or current contract of employment will be required). You also need to have at least a Level 2 qualification.
                    </div>
                    )}

                    <Form.Group controlId='residencyStatus'>
                        <Form.Label>
                            <b>What is your residency status?</b>{' '}
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={residencyStatus || ''}
                            onChange={(e) => setResStatus(e.target.value)}
                        >
                            <option disabled value=''>
                                [Please Select One]
                            </option>
                            <option value='British Citizen'>British Citizen</option>
                            <option value='Citizen of a Country within the EEA'>
                                Citizen of a Country within the EEA
                            </option>
                            <option value='Non-EU Citizen with indefinite leave to remain'>
                                Non-EU Citizen with indefinite leave to remain
                            </option>
                            <option value='Non-EU Citizen with Discretionary leave to remain'>
                                Non-EU Citizen with Discretionary leave to remain
                            </option>
                            <option value='Non-EU Citizen with Exceptional leave to remain'>
                                Non-EU Citizen with Exceptional leave to remain
                            </option>
                            <option value='Refugee Status'>Refugee Status</option>
                            <option value='Asylum Seeker'>Asylum Seeker</option>
                            <option value='Husband, Wife, Civil Partner or Child of an eligible person'>
                                Husband, Wife, Civil Partner or Child of an eligible person
                            </option>
                            <option value='Other (details and evidence in learner file)'>
                                Other (details and evidence in learner file)
                            </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId='ans'>
                        <Form.Label>
                            <b>Can you answer yes to the below?</b>{' '}
                        </Form.Label>
                        <p>
                            Please note: to be eligible for funding you must choose yes to all questions to confirm
                            you meet the following criteria. Funding is only available for England.
                        </p>
                        <ol>
                            <li>I currently live in England</li>
                            <li>I have lived in the UK for the last 3 years</li>
                            <li>I am aged 19+</li>
                            <li>I have not studied the course I am applying for before</li>
                            <li>I am not currently on a funded course.</li>
                        </ol>
                        <Form.Control
                            as='select'
                            required
                            value={ans || ''}
                            onChange={(e) => setAns(e.target.value)}
                        >
                            <option disabled value=''>
                                [Please select one]
                            </option>
                            <option value='yes'>Yes</option>
                            <option value='No'> No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group controlId='ans'>
                        <Form.Label>
                            <b>
                                All courses are online, please confirm you have a computer, laptop or tablet to access the course
                            </b>
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={deviceAvail || ''}
                            onChange={(e) => setDeviceAvail(e.target.value)}
                        >
                            <option disabled value=''>
                                [Please select one]
                            </option>
                            <option value='yes'>Yes</option>
                            <option value='No'> No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group controlId='firstName'>
                        <Form.Label>First Name </Form.Label>
                        <Form.Control
                            type='text'
                            required
                            placeholder='Enter First Name'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                        <Form.Text className='text-muted'>
                            As it appears on your ID
                        </Form.Text>
                    </Form.Group>


                    {/* <Form.Group controlId='middleName'>
                        <Form.Label>Middle Name </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter Middle Name'
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                        <Form.Text className='text-muted'>
                            As it appears on your ID
                        </Form.Text>
                    </Form.Group> */}

                    <Form.Group controlId='lastName'>
                        <Form.Label>Last Name </Form.Label>
                        <Form.Control
                            type='text'
                            required
                            placeholder='Enter Last Name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                        <Form.Text className='text-muted'>
                            As it appears on your ID
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId='email'>
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                            type='email'
                            required
                            placeholder='Enter Email'
                            value={email}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='PostId'>
                        <Form.Label>Post Code</Form.Label>
                        <Form.Control
                            type='text'
                            required
                            placeholder='Enter PostCode'
                            value={PostCodeBL}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(/\s/g, '');
                                setPCodeBL(e.target.value.toUpperCase())
                            }}
                        ></Form.Control>
                        <Form.Text className='text-muted'>
                            No Special Charcters and no spaces.
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='mobile'>
                        <Form.Label>Mobile </Form.Label>
                        <Form.Control
                            required
                            type='tel'
                            placeholder='Enter Mobile No'
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <p>By providing your details you agree to being contacted and your data being processed under the terms of our <a href="/terms-conditions">Privacy Policy</a>.</p>
                    <Button
                        type='submit'
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                        }}
                    >
                        See if I qualify
                    </Button>
                </Form>
            </FormContainer>
            <Modal show={accessPopup} onHide={() => setAccessPopup(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Attention</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will need an advance learner loan to complete this course. For more information you can click the link below.
                    <br />
                    <a href="https://www.gov.uk/advanced-learner-loan" target="_blank" rel="noopener noreferrer">
                        https://www.gov.uk/advanced-learner-loan
                    </a>
                    <br />
                    Alternatively, you can speak to a member of our team on  <a href="tel:08001889161">0800 188 9161</a> or <a href="mailto:onlinelearning@myfreecourse.co.uk"> email</a> us.
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <Button
                        variant='primary'
                        className='mr-5 back-Button-form'
                        onClick={() => {
                            trackAccessPopupCancel()
                            history.push('/home')
                        }}>
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                            width: '100%'
                        }}
                        className='mrt next-Button-form'
                        onClick={() => setAccessPopup(false)}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DetailsForm
