import ReactGA from 'react-ga4';

export const trackApplyButton = () => {
    ReactGA.event({
        category: 'Button',
        action: 'Clicked Apply Button',
        label: 'Apply Now',
    });
};


export const trackQualifiedEligibility = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Eligibility Check Passed',
        label: 'Qualified',
    });
};

export const trackFailedEligibility = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Failed Eligibility Check',
        label: 'Eligibility Validation',
    });
};

export const trackStep2Personal = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Personal Step 2',
        label: 'Personal Information',
    });
};

export const trackStep3Emergency = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Emergency Step 3',
        label: 'Emergency Information',
    });
};

export const trackStep4Employment = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Employment Step 4',
        label: 'Opportunities Information',
    });
};

export const trackStep5Qualification = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Qualification Step 5',
        label: 'Qualification Information',
    });
};


export const trackStep6Opportunities = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Opportunities Step 6',
        label: 'Opportunities Information',
    });
};

export const trackStep7Declaration = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Completed Step 7',
        label: 'Declaration Submission',
    });
};

export const trackStep8Proof = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Completed Form Step 8 ',
        label: 'Completed Form',
    });
};


export const trackStep8CompletionLink = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Completed Form Completion Link',
        label: 'Completed Form',
    });
};

export const trackAccessPopupCancel = () => {
    ReactGA.event({
        category: 'Form',
        action: 'Access Popup Cancell Eligibility',
        label: 'Access Popup Cancell Eligibility',
    });
};
