import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import { trackApplyButton } from "../common/analyticsGA4";
const AccessToComputing = () => {
    let product = {
        "courseTitle": "Access to Higher Education Computing",
        "courseLength": "12 months",
        "awardingBody": "NCFE / TQUK",
        "courseLevel": "Level 3",
        "funding": "Advance Learner Loan",
        "learningMethods": "Online learning portal",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Computing_chlxw9.jpg",
    }
    console.log(product)
    const Benefits = (props) => {
        const text = props.text;
        const newText = text.split("•").map((str) => (
            <li>{str}</li>
        ));
        return newText;
    };
    let Content = (props) => {
        let text = props.text;
        const res = String(text);
        console.log(res);
        let newText = res.split("•").map((str) => (
            <li>{str} </li>
        ));
        return newText;
    };
    function demo(arr1, arr2) {
        let Farray = [];
        for (let i = 0; i < arr1.length - 1; i++) {
            let object = {
                heading: arr1[i],
                desc: arr2[i]
            }
            Farray.push(object);
        }
        return Farray;
    }
    return (
        <div>
            <Helmet>
                <title>{product.courseTitle}</title>
            </Helmet>

            <div className="jumbotron text-center splash only-on-desktop">
                <h3 className="heading-jumbotron">{product.courseTitle}</h3>
            </div>
            <div className="only-on-mobile">
                <img
                    src={product.img}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
            <div className="container-sm course-details-p" xs={12}>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
                        <div className="row" style={{ display: "flex !important" }}>
                            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                                <img
                                    src={product.img}
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                                <h3 className="coursedetailh2" style={{ margin: "auto" }}>{product.courseTitle}</h3>
                            </div>
                        </div>

                        <br />

                        <h1 className="coursedetailh2 only-on-mobile" style={{ margin: "auto", fontSize: "22px", color: "#222222" }}>{product.courseTitle}</h1>
                        <br />
                        <HashLink
                            className="btn btn-primary co-button vv only-on-mobile"
                            to={'/form/' + "Access to Computing" + '#top'}
                            onClick={() => trackApplyButton()}
                        >
                            Apply Now
                        </HashLink>
                        <br />
                        <h6><b>Description: </b></h6>
                        <p>

                            The <b>Access to Higher Education Diploma (Computing)</b> is your gateway to a university degree and a career in the ever-evolving digital world. In today’s tech-driven landscape, businesses rely on computing professionals to enhance digital operations, improve security, and deliver seamless user experiences.
                            <br /><br />
                            This course, equivalent to three A-Levels, provides a strong foundation in computer science, covering essential topics like mathematics, database development, cybersecurity, and web design. With companies actively seeking skilled computing professionals, this diploma sets you on the path to exciting, high-paying career opportunities in IT, software development, and beyond.

                        </p>
                        <br />
                        <b>Areas of Study: </b>
                        <ul>
                            <li>Components of computer science</li>
                            <li>AI, Machine and Deep Learning</li>
                            <li>Webpage Design and Production</li>
                            <li>Pure Math </li>
                        </ul>
                        <br />
                        <h5><strong>Key Features of the Advanced Learner Loan:</strong></h5>

                        <p><strong>No Income or Credit Check</strong> – Loan approval is not based on your financial status.</p>

                        <p><strong>Flexible Repayments</strong> – You only start repaying the loan <strong>once you earn above the repayment threshold</strong>.</p>

                        <p><strong>Covers Tuition Fees</strong> – The loan is paid directly to your learning provider to cover course costs.</p>

                        <p><strong>No Age Limit</strong> – Available for learners aged <strong>19 and above</strong>, with no upper age restriction.</p>

                        <p><strong>Loan Write-Off for University Graduates</strong> – If you go on to complete a <strong>higher education degree</strong>, your Advanced Learner Loan may be written off, subject to government policies.</p>

                        <br />
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1743009613/Site-pics/image_2025-03-26_221954782_a0eras.png" style={{width:'100%'}} />
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title="Content">
                                <br />
                                <p>
                                    <b>Module 1: Academic Writing Skills</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to structure a written response</li>
                                        <li>Be able to develop a structured response to a plan</li>
                                        <li>Be able to present the response appropriately for audience and purpose</li>
                                        <li>Understand how to apply academic writing principles to own work</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 2: Reading and Note Making</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand a range of reading strategies</li>
                                        <li>Understand the use of language in terms of the purpose and context of a range of texts</li>
                                        <li>Be able to use methods for developing notes from a range of sources</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 3: Use of Information and Communication Technology</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to use word processing software to create documents</li>
                                        <li>Be able to use spreadsheet software packages to manipulate numerical data</li>
                                        <li>Be able to use computer presentation software to produce a presentation</li>
                                        <li>Know how to combine data from different sources and packages to create a document</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 4: Components of Computer Systems</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the basic components of a computer system</li>
                                        <li>Understand the characteristics of data capture devices and the need control of data input</li>
                                        <li>Understand the characteristics of modern storage methods</li>
                                        <li>Understand the characteristics of common output devices</li>
                                        <li>Understand the operation and use of modern data communications methods</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 5: Algebra and Functions</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to use and understand the rules of indices</li>
                                        <li>Understand the use of surds</li>
                                        <li>Be able to solve a problem involving algebraic expressions</li>
                                        <li>Be able to use function notation and solve a problem involving functions</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 6: Cyber Security Fundamentals</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand cyber security concepts</li>
                                        <li>Understand current cyber threats and cyber warfare techniques and methods</li>
                                        <li>Be able to evaluate defence mechanisms against cyber threats</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 7: Database Development</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the use of database terminology and factors that influence design</li>
                                        <li>Use a database system to structure and organise data</li>
                                        <li>Use a database system to manipulate and present information</li>
                                        <li>Know how to create appropriate user interfaces</li>
                                        <li>Be able to test and review the database</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 8: Calculus</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to solve a problem involving coordinate geometry of straight lines</li>
                                        <li>Be able to perform and apply differentiation on a simple function</li>
                                        <li>Be able to perform and apply integration</li>
                                    </ul>
                                </p>

                                <p>
                                    <b>Module 9: AI, Machine Learning and Deep Learning</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the fundamentals of artificial intelligence</li>
                                        <li>Understand the fundamentals of machine learning</li>
                                        <li>Understand the fundamentals of deep learning</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 10: The Safe and Ethical Use of Generative Artificial Intelligence</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the principles of safe and ethical use of generative AI in own subject area</li>
                                        <li>Be able to evaluate the use of generative AI tools or resources with regard to academic integrity</li>
                                        <li>Be able to evaluate safe and ethical practices in using generative AI in own subject</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 11: Software Developement</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the key features of a programming language</li>
                                        <li>Be able to create a computer program to meet the needs of a specific situation</li>
                                        <li>Understand good practice within the software development process</li>
                                        <li>Understand the importance of testing within the software development cycle</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 12: Pure Maths</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to apply logarithms to solve a problem</li>
                                        <li>Be able to apply trigonometric methods to solve a problem</li>
                                        <li>Be able to solve a problem involving a series</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 13: Programming Constructs</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to design both simple and complex single task programs from a specification</li>
                                        <li>Be able to use both the basic and advanced constructs provided by a high-level programming language appropriately</li>
                                        <li>Be able to create programs to handle simple data structures</li>
                                        <li>Be able to test and correct complex single function programs</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 14: Further Differentiation</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to perform and apply differentiation on a simple function</li>
                                        <li>Be able to perform and apply differentiation on a complicated function</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 15: Web Page Design and Production</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the basic constituent elements of a Web Page</li>
                                        <li>Understand the basic language code used in web page production</li>
                                        <li>Understand how to design and produce a web page</li>
                                        <li>Understand how to make a web page ‘live’ on the internet</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 16:  Further Trigonometry</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to solve a problem involving arc length and sector area</li>
                                        <li>Be able to use simple trigonometric identities to solve problems</li>
                                        <li>Be able to use complex trigonometric identities to solve problems</li>
                                    </ul>
                                </p>
                                <p>
                                    In addition to the qualification units, you will also be required to complete short introductory tasks at the start of your course to support the development of your academic study skills.
                                </p>
                            </Tab>
                            <Tab eventKey="benefits" title="Benefits">
                                <h6><b>The course will help you…</b></h6>
                                <ul>
                                    <li>
                                        Gain highly desirable computer and technology skills
                                    </li>
                                    <li>
                                        Pursue a career in innovation tech-focused industries
                                    </li>
                                    <li>
                                        Learn at your pace
                                    </li>
                                    <li>
                                        100% online study
                                    </li>
                                    <li>
                                        No Exams
                                    </li>
                                </ul>
                                <b>Career Aspects: </b>
                                <ul>
                                    <li>Computer Science</li>
                                    <li>Game Design</li>
                                    <li>Data Analytics</li>
                                </ul>
                                <br />
                                <b>University Applications & Entry Requirements</b>
                                <br />
                                Your allocated college will support you in applying to universities, but <b>each university sets its own admission criteria</b>. It’s your responsibility to check if your <b>Access to HE Diploma</b> and other qualifications meet the requirements of your chosen university.
                                <br /><br />
                                Common university entry requirements include:
                                <ul>
                                    <li><b>A certain number of credits</b> at <b>Merit</b> or <b>Distinction</b></li>
                                    <li>A <b>face-to-face interview</b> at the university</li>
                                    <li><b>Literacy and numeracy assessments</b> provided by the university</li>
                                    <li><b>Course-related work placements</b> or work experience </li>
                                    <li><b>GCSEs in Maths & English (Grade C/4 or equivalent Level 2 qualification)</b> such as Functional Skills or Key Skills</li>
                                </ul>
                            </Tab>

                            <Tab eventKey="eligibility" title="Eligibility">
                                <br />
                                <h6><b>Eligibility Criteria:</b></h6>
                                <ul>
                                    <li>Must be 19 or older (Must turn 19 before 31st August, 2024)</li>
                                    <li>Level 2 in Maths and English</li>
                                </ul>
                                <b>Residency Status</b>
                                <ul>
                                    <li>Be living in the UK on first day of your course</li>
                                    <li>UK /Irish Citizen or have ‘settled status’ </li>
                                    <li>Lived in the UK, British territories, Channel islands or the Isle of Man for 3 years in a row.</li>
                                    <li>Other Visa types may be eligible <a href="https://www.gov.uk/advanced-learner-loan">Advanced Learner Loan: Overview - GOV.UK</a></li>
                                </ul>
                                <p >It will be the learner’s responsibility to understand the repayment terms of the loan.</p>
                            </Tab>
                            <Tab eventKey="Learning Method" title="Learning Method">
                                <br />
                                <h6><b>Learning Method: </b></h6>
                                <p>
                                    Once you enroll, all your learning materials will be available through our online portal. If you need any accessibility arrangements, please let our team know.
                                    <br /><br />
                                    You'll receive full support throughout your course, including one-on-one online tutorials and group sessions.
                                </p>

                                <p>
                                    <b>Flexible Online Learning</b><br />
                                    Studying online allows you to fit your learning around your existing commitments. Whether you're working or caring for family, you can complete your course at your own pace and move closer to your goals.

                                </p>
                            </Tab>
                            <Tab eventKey="Assessment Method" title="Assessment Method">
                                <br />
                                <h6><b>Assessment Method: </b></h6>
                                <p>Your assignments are due at the end of each unit and will be marked by either a tutor or a computer.
                                    <br /><br />
                                    Your assigned college will provide access to MS Office through your learning portal, so you can complete your assessments easily.
                                </p>
                                <b>Qualification & Certification</b>
                                <br />
                                After completing your course, you will receive a <b>Level 3 nationally recognized qualification</b> regulated by the <b>Quality Assurance Agency for Higher Education (QAA)</b>. Your certificate is typically issued <b>12-16 weeks</b> after your final assessment is graded, but this may vary depending on the time of year.
                            </Tab>
                        </Tabs>
                        <br />
                        <hr />



                    </div>
                    <div className="col-md-4 info-box-courses">
                        <Card style={{ width: "18rem", color: "black" }} className="wd">
                            <Card.Body style={{ boxShadow: "none" }}>
                                <Card.Title>KEY INFORMATION</Card.Title>
                                <Table striped bordered>
                                    <thead>
                                        <tbody>
                                            <tr>
                                                <td>Course Length</td>
                                                <td>{product.courseLength}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Provider</td>
                                                <td>{product.awardingBody}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Level</td>
                                                <td>{product.courseLevel}</td>
                                            </tr>
                                            <tr>
                                                <td>Funding (subject to availability)</td>
                                                <td>Advance Learner Loan</td>
                                            </tr>
                                            <tr>
                                                <td>Learning Method(s)</td>
                                                <td>{product.learningMethods}</td>
                                            </tr>
                                        </tbody>
                                    </thead>
                                </Table>
                                <HashLink
                                    className="btn btn-primary co-button vv "
                                    to={'/form/' + "Access to Computing" + '#top'}
                                    onClick={() => trackApplyButton()}
                                >
                                    Apply Now
                                </HashLink>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <section className="services section-bg" id="partners" style={{ borderTop: "1px solid #eaeaea", marginTop: "30px", paddingTop: "0px" }}>
                <div className="container">
                    <div className="row social-logo-section1" >
                        <div className="col">
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    <center>
                        <div className="row social-logo-section only-on-mobile" >
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                        </div>
                    </center>
                </div>

            </section>
        </div>
    )
}

export default AccessToComputing