import React from "react";
import { Container, Row, Col } from "react-bootstrap";


import { Helmet } from 'react-helmet'
import TrustpilotWidget from "../../common/trustPilotWidget";

const TITLE = 'Form'
const FormContainer = ({ children }) => {
    return (

        <Container className="form-container-outRow">
            <Helmet>
                <title>{TITLE}</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>


            <Row className="form-container">
                <Col xs={12} md={12}>
                    <div className='only-on-mobile' >
                        <TrustpilotWidget />
                    <br />
                    <br />
                    </div>
                    {children}
                    <br />
                    <br />
                    <TrustpilotWidget />
                </Col>
            </Row>
            <br /><br /><br /><br /><br />
            <section className="services section-bg" id="partners" style={{ borderTop: "1px solid #eaeaea", marginTop: "20px", paddingTop: "0px" }}>
                <div className="container">
                    <div className="row social-logo-section1" >
                        <div className="col">
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    <center>
                        <div className="row social-logo-section only-on-mobile" >
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                        </div>
                    </center>
                </div>


            </section>
        </Container>

    );
};

export default FormContainer;
