import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { saveSignDetails, createForm, getIncUserDetails } from '../../actions/formActions'
import { Form, Button } from 'react-bootstrap'
import FormCompletetionSteps from '../Update Form/FormCompletetionSteps'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import SignaturePad from 'react-signature-canvas'
import { CButton } from '@coreui/react'
import { trackStep8CompletionLink } from '../../common/analyticsGA4'

const EmailProofDetails = ({ history }) => {
	const urlParams = new URLSearchParams(history.location.search)
	const formId = urlParams.get('form_id')

	// If Form is already submitted
	useEffect(async () => {
		const res = await getIncUserDetails(formId)
		if (res?.response?.status === 503) history.push('/')
	}, [])

	const dispatch = useDispatch()

	const form = useSelector((state) => state.form)

	const { personalDetails, sign } = form
	const [idPic, setID] = useState()
	const [idPic1, setID1] = useState()

	const [signature, setTrimmed] = useState()

	const formCreate = useSelector((state) => state.final)
	const { final, success, error } = formCreate

	const [sigPad, setSigPad] = useState({})
	const [msg, setMsg] = useState()
	const [groupArray, setGroupArray] = useState([])
	const [typeOfProof, setTypeOfProof] = useState()
    const [proofList, setProofList] = useState((localStorage.getItem('proofs') || '').split(','))

	const clearSig = () => {
		sigPad.clear()
		setMsg(false)
	}
	const uploadImage = (file) => {
		const data = new FormData()
		data.append('file', file)
		data.append('upload_preset', 'evidence')
		data.append('cloud_name', 'my-free-course')
		return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
			method: 'POST',
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('URL FIles', res.url)
				return res.url
			})
	}
	const trim = () => {
		const response = sigPad.getTrimmedCanvas().toDataURL('image/png')
		setTrimmed(response)
		dispatch(saveSignDetails(response))
		setMsg(true)
	}
    const handleGroup = (id) => {
		if (groupArray.includes(id)) {
			let temp = groupArray.filter(function (item) {
				return item !== id
			})
			setGroupArray(temp)
		} else {
			let temp = [...groupArray]
			temp.push(id)
			setGroupArray(temp)
		}  
	}
    let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']

	useEffect(() => {
		if (success) {
			history.push('/success')
		}
	}, [history, success])

	const submitHandler = (e) => {
		e.preventDefault()
		document.getElementById('spinner-finalstep').style.display = 'block'
		window.stop()
		e.preventDefault() 

		if (!idPic || !signature) {
			document.getElementById('spinner-finalstep').style.display = 'none'
			toast.error('Submit all files and signature')
			return
		}

		const data = new FormData()
		data.append('nationalInsNo', personalDetails.nationalInsNo)
		dispatch(saveSignDetails(signature))

		let images = [idPic, idPic1]
		let promises = []
		images.forEach((image) => promises.push(uploadImage(image)))
		Promise.all([...promises])
			.then((values) => {
                trackStep8CompletionLink()
				createForm(values[0] || '', values[1] || '',groupArray, typeOfProof)
				history.push('/success')
			})
			.catch((err) => {
				console.log(err)
			})
	}

	function isImage(file) {
		return file && file['type'].split('/')[0] === 'image'
	}
	return (
		<>
			<div className='form-jumbotron'>
				<h3>LEARNER APPLICATION FORM</h3>
			</div>
			<center>
				<div className='container only-on-mobile'>
					<img
						src='https://res.cloudinary.com/my-free-course/image/upload/v1641932783/Site-pics/personal_page_images_nocdmn.png'
						alt=''
					/>
				</div>
			</center>
			<FormContainer>
				{/* <ScrollToMount /> */}
				<h1>
					<b>Final Step: ID Proof</b>
				</h1>
				<Form.Label className='form-notice'>
                The courses provided are government funded.<br/><br/>
                As a condition of claiming the funding on your behalf, the college is required to carry out an ID check.<br/><br/>
                This is to make sure the course funding is going to a genuine UK or EU citizen.<br/><br/>
                <b>Acceptable ID proofs are: Passport, Birth certificates, Full drivers licence (not provisional or learners licence) or the top of a Utility bill or Bank statement (clearly showing your name, address and the provider).</b>
                    <p className='p-light'>
					</p>
				</Form.Label>
                <Form onSubmit={submitHandler}>
					<Form.Group controlId='title'>
						<Form.Label>
							<span style={{ fontSize: '22px' }}>
								<b>1.</b>
							</span>{' '}
							<b>Please upload a photo of one valid ID Proof</b>
							<br />This can be a Passport, Full drivers licence (not provisional or learners licence), Birth certificate or the top of a Utility bill(clearly showing your name, address, date of issue and the provider) or Bank statement (clearly showing your name, address and the provider).
						</Form.Label>
                        <Form.Control
							as='select'
							required
							value={typeOfProof}
							onChange={(e) => setTypeOfProof(e.target.value)}
						>
							<option selected disabled value=''>
								Type of Proof
							</option>
                            {proofList.map(x=><option value={x}>{x}</option>)}
						</Form.Control>
                        <br/>

						<Form.Control
							type='file'
							placeholder='Enter address'
							accept='image/*'
							onChange={(e) => {
								if (isImage(e.target.files[0])) {
									setID(e.target.files[0])
								} else {
									alert('Only Image File Are accepted')
								}
							}}
						></Form.Control>
					</Form.Group>

					{/* <p>
						<span style={{ fontSize: '22px' }}>
							<b>2.</b>{' '}
						</span>
						<b> Optional (if name change applies) </b>
						<br />
						If your name on your ID is a different (surname/first) name to the one on
						your application, further evidence to support this change in the form of a
						marriage certificate or deed poll is required. If this does not apply to
						you, please continue to the signature section.
					</p>
					<Form.Group>
						<Form.Control
							type='file'
							accept='image/*'
							onChange={(e) => {
								if (isImage(e.target.files[0])) {
									setID1(e.target.files[0])
								} else {
									alert('Only Image File Are accepted')
								}
							}}
						></Form.Control>
					</Form.Group> */}

                    {/* Contact Time Schedule */}
                    <Form.Label ><b>Preferred Contact Times</b></Form.Label>
                    <p>Once your application has been submitted and checked we will pass the application to the course provider who will contact you to complete your enrolment. 
                    <br/>Please confirm the best time for the course enrolment team to contact you, you can select multiple times.</p>
                    <div className="multi-select-time" id="multi-select-contact">
                        {days.map(day=><>
                            <ul>
                            <li className='contact-title-day'>
                                <b>{day}</b>
                            </li>
                            <li>
                                <CButton
										color={
											groupArray.includes(day+": 8am to 10am") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 8am to 10am")
										}}
									>
										8am to 10am
									</CButton>
                            </li>
                            <li >
                                <CButton
										color={
											groupArray.includes(day+": 10am to 12pm") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 10am to 12pm")
										}}
									>
										10am to 12pm
									</CButton>
                            </li>
                            <li >
                                <CButton
										color={
											groupArray.includes(day+": 12pm to 2pm") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 12pm to 2pm")
										}}
									>
                                    12pm to 2pm
									</CButton>
                            </li>
                            <li>
                                <CButton
										color={
											groupArray.includes(day+": 2pm to 4pm") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 2pm to 4pm")
										}}
									>
										2pm to 4pm
									</CButton>
                            </li>
                            <li>
                                <CButton
										color={
											groupArray.includes(day+": 4pm to 6pm") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 4pm to 6pm")
										}}
									>
										4pm to 6pm
									</CButton>
                                
                            </li>
                            <li>
                                <CButton
										color={
											groupArray.includes(day+": 6pm to 8pm") ? 'success' : 'primary'
										}
										variant='outline'
										shape='square'
										size='sm'
										onClick={() => {
											handleGroup(day+": 6pm to 8pm")
										}}
									>
										6pm to 8pm
									</CButton>
                            </li>
                        </ul>
                        </>)}
                    </div>
<br/><hr/>

					<Form.Label as='legend'>Please Sign the Application</Form.Label>
					<SignaturePad
						id='canvas'
						penColor='black'
						className='mr-6'
                        onEnd={trim}
						ref={(ref) => {
							setSigPad(ref)
						}}
						canvasProps={{
							width: 500,
							height: 200,
							className: 'sigCanvas',
						}}
					/>
					<br />
					{/* <Button className='mr-5 next-Button-form' onClick={trim}>
						Sign{' '}
					</Button> */}
					<Button className='back-Button-form' onClick={clearSig}>
						Clear Signature
					</Button>
					<br />

					<br />
					{msg && <p>Your signature has been accepted.</p>}
					<br />

					<Button
						type='submit'
						className='next-Button-form'
						style={{
							width: '300px',
							color: 'white',
						}}
					>
						{' '}
						Submit
					</Button>
				</Form>
			</FormContainer>

			<div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
				<center>
					<Spinner animation='border' />
				</center>
			</div>
		</>
	)
}

export default EmailProofDetails
