import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import { trackApplyButton } from "../common/analyticsGA4";
const AccessInBiomedicalScience = () => {
    let product = {
        "courseTitle": "Access to Higher Education Biomedical Science",
        "courseLength": "12 months",
        "awardingBody": "NCFE / TQUK",
        "courseLevel": "Level 3",
        "funding": "Advance Learner Loan",
        "learningMethods": "Online learning portal",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Biomedical_Science_vc4vey.jpg",
    }
    console.log(product)
    const Benefits = (props) => {
        const text = props.text;
        const newText = text.split("•").map((str) => (
            <li>{str}</li>
        ));
        return newText;
    };
    let Content = (props) => {
        let text = props.text;
        const res = String(text);
        console.log(res);
        let newText = res.split("•").map((str) => (
            <li>{str} </li>
        ));
        return newText;
    };
    function demo(arr1, arr2) {
        let Farray = [];
        for (let i = 0; i < arr1.length - 1; i++) {
            let object = {
                heading: arr1[i],
                desc: arr2[i]
            }
            Farray.push(object);
        }
        return Farray;
    }
    return (
        <div>
            <Helmet>
                <title>{product.courseTitle}</title>
            </Helmet>

            <div className="jumbotron text-center splash only-on-desktop">
                <h3 className="heading-jumbotron">{product.courseTitle}</h3>
            </div>
            <div className="only-on-mobile">
                <img
                    src={product.img}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
            <div className="container-sm course-details-p" xs={12}>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
                        <div className="row" style={{ display: "flex !important" }}>
                            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                                <img
                                    src={product.img}
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                                <h3 className="coursedetailh2" style={{ margin: "auto" }}>{product.courseTitle}</h3>
                            </div>
                        </div>

                        <br />

                        <h1 className="coursedetailh2 only-on-mobile" style={{ margin: "auto", fontSize: "22px", color: "#222222" }}>{product.courseTitle}</h1>
                        <br />
                        <HashLink
                            className="btn btn-primary co-button vv only-on-mobile"
                            to={'/form/' + "Access to Biomedical Science" + '#top'}
                            onClick={() => trackApplyButton()}
                        >
                            Apply Now
                        </HashLink>
                        <br />
                        <h6><b>Description: </b></h6>
                        <p>
                            The <b>Access to Higher Education Diploma (Biomedical Science)</b> provides the essential skills and qualifications needed to pursue a university degree in biomedical science and related fields. Biochemistry plays a crucial role in life on Earth, driving medical advancements, diagnostic tools, and life-saving treatments.
                            <br /><br />
                            This course, equivalent to three A-Levels, prepares you for higher education by covering key topics such as atomic structure, chemical bonds, pollution chemistry, nutrition and digestion, cell division, and genetics. By building a strong foundation in biomedical science, you'll be well-equipped to explore exciting careers, from pharmacology to forensic science, making a real difference in people’s lives.

                            <br />
                            <br />

                            <b>Areas of Study: </b>
                            <ul>
                                <li>Cell division and Genetic Variation</li>
                                <li>Nutrition and Digestion</li>
                                <li>Chemistry of Drugs and Medicine</li>
                                <li>Chemistry of Pollution</li>
                            </ul>
                        </p>
                        <br />
                        <b>Areas of Study: </b>
                        <ul>
                            <li>Components of computer science</li>
                            <li>AI, Machine and Deep Learning</li>
                            <li>Webpage Design and Production</li>
                            <li>Pure Math </li>
                        </ul>
                        <br />
                        <h5><strong>Key Features of the Advanced Learner Loan:</strong></h5>

                        <p><strong>No Income or Credit Check</strong> – Loan approval is not based on your financial status.</p>

                        <p><strong>Flexible Repayments</strong> – You only start repaying the loan <strong>once you earn above the repayment threshold</strong>.</p>

                        <p><strong>Covers Tuition Fees</strong> – The loan is paid directly to your learning provider to cover course costs.</p>

                        <p><strong>No Age Limit</strong> – Available for learners aged <strong>19 and above</strong>, with no upper age restriction.</p>

                        <p><strong>Loan Write-Off for University Graduates</strong> – If you go on to complete a <strong>higher education degree</strong>, your Advanced Learner Loan may be written off, subject to government policies.</p>

                        <br />
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1743009613/Site-pics/image_2025-03-26_221954782_a0eras.png" style={{ width: '100%' }} />

                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title="Content">
                                <br />
                                <p>
                                    <b>Module 1: Academic Writing Skills</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to structure a written response</li>
                                        <li>Be able to develop a structured response to a plan</li>
                                        <li>Be able to present the response appropriately for audience and purpose</li>
                                        <li>Understand how to apply academic writing principles to own work</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 2: Reading and Note Making</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand a range of reading strategies</li>
                                        <li>Understand the use of language in terms of the purpose and context of a range of texts</li>
                                        <li>Be able to use methods for developing notes from a range of sources</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 3: Exploring the Structure and Function of Cells</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand cellular structure and organisation</li>
                                        <li>Understand cellular activity</li>
                                        <li>Understand the cell cycle</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 4: Atoms, Bonds and Structure</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand atomic structure</li>
                                        <li>Understand bonding, structure and properties</li>
                                        <li>Understand intermolecular forces</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 5: Cell Division and Genetics</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand mitosis</li>
                                        <li>Understand meiosis</li>
                                        <li>Understand genetic variation</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 6: Moles, Equations and Acids</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the mole concept and perform related calculations</li>
                                        <li>Understand chemical formulae and equations</li>
                                        <li>Understand acids and bases</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 7: Nutrition and Digestion</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the concept of a balanced diet</li>
                                        <li>Understand the structure and function of the human digestive system</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 8: Redox and Periodicity</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand oxidation and reduction</li>
                                        <li>Understand periodicity</li>
                                        <li>Understand the chemistry of Group 2 elements and compounds</li>
                                        <li>Understand the chemistry of halogens and their compounds</li>
                                    </ul>
                                </p>

                                <p>
                                    <b>Module 9: Human Musculoskeletal System</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the human skeleton and how it relates to locomotion</li>
                                        <li>Know how muscles contract</li>
                                        <li>Understand the development of bone</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 10: Chemistry of Drugs and Medicines</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the key definitions used in medicinal chemistry</li>
                                        <li>Understand the significance of the molecular structure of drugs upon their function</li>
                                        <li>Understand analytic methods used for drug detection</li>
                                        <li>Understand the importance of isomerism in drug action</li>
                                        <li>Understand the common processes involved in drug design</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 11: Nervous System</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the basic structure and function of the nervous system</li>
                                        <li>Understand the nature of nerve impulses</li>
                                        <li>Understand the principles of synaptic transmission</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 12: Chemistry of Pollution</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the causes and effects of atmospheric pollution</li>
                                        <li>Understand the effects of rising CO2 levels on the chemistry of the oceans</li>
                                        <li>Understand causes and effects of pollution of the hydrosphere</li>
                                        <li>Be able to evaluate sources of trace-metal pollution and their effects on local soils and/or water courses</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 13: Endocrine System</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand terms hormone and endocrine gland</li>
                                        <li>Understand homeostasis and feedback mechanisms</li>
                                        <li>Understand the action of hormones</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 14: Kinetics, Energetics, Equilibria and Acid-Base Equilibria</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand chemical kinetics</li>
                                        <li>Understand energetics</li>
                                        <li>Understand chemical equilibria</li>
                                        <li>Understand acid-base equilibria</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 15: Infection and Immunity</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the contributions of Snow, Semmelweiss, Pasteur, Jenner and Koch to the understanding of infectious disease</li>
                                        <li>Understand how the human body responds to infection</li>
                                        <li>Understand the cause and transmission of different pathogens</li>
                                        <li>Understand how infections can be prevented by vaccination</li>
                                        <li>Understand the mechanism of action of antibiotics</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 16: The Cause and Control of Disease</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand categories and causes of human disease</li>
                                        <li>Understand the epidemiology of a named disease</li>
                                        <li>Understand measures for disease prevention</li>
                                    </ul>
                                </p>
                                <p>
                                    In addition to the qualification units, you will also be required to complete short introductory tasks at the start of your course to support the development of your academic study skills.
                                </p>
                            </Tab>
                            <Tab eventKey="benefits" title="Benefits">
                                <h6><b>The course will help you…</b></h6>
                                <ul>
                                    <li>
                                        Pursue a career that makes life-changing discoveries
                                    </li>
                                    <li>
                                        Learn at your pace
                                    </li>
                                    <li>
                                        100% online study
                                    </li>
                                    <li>
                                        No Exams
                                    </li>
                                    <li>
                                        Equivalent to 3 A Levels
                                    </li>
                                </ul>
                                <br />
                                <b>University Applications & Entry Requirements</b>
                                <br />
                                Your allocated college will support you in applying to universities, but <b>each university sets its own admission criteria</b>. It’s your responsibility to check if your <b>Access to HE Diploma</b> and other qualifications meet the requirements of your chosen university.
                                <br /><br />
                                Common university entry requirements include:
                                <ul>
                                    <li><b>A certain number of credits</b> at <b>Merit</b> or <b>Distinction</b></li>
                                    <li>A <b>face-to-face interview</b> at the university</li>
                                    <li><b>Literacy and numeracy assessments</b> provided by the university</li>
                                    <li><b>Course-related work placements</b> or work experience </li>
                                    <li><b>GCSEs in Maths & English (Grade C/4 or equivalent Level 2 qualification)</b> such as Functional Skills or Key Skills</li>
                                </ul>
                                <br />
                            </Tab>

                            <Tab eventKey="eligibility" title="Eligibility">
                                <br />
                                <h6><b>Eligibility Criteria:</b></h6>
                                <ul>
                                    <li>Must be 19 or older (Must turn 19 before 31st August, 2024)</li>
                                    <li>Level 2 in Maths and English</li>
                                </ul>
                                <b>Residency Status</b>
                                <ul>
                                    <li>Be living in the UK on first day of your course</li>
                                    <li>UK /Irish Citizen or have ‘settled status’ </li>
                                    <li>Lived in the UK, British territories, Channel islands or the Isle of Man for 3 years in a row.</li>
                                    <li>Other Visa types may be eligible <a href="https://www.gov.uk/advanced-learner-loan">Advanced Learner Loan: Overview - GOV.UK</a></li>
                                </ul>
                                <p >It will be the learner’s responsibility to understand the repayment terms of the loan.</p>
                            </Tab>
                            <Tab eventKey="Learning Method" title="Learning Method">
                                <br />
                                <h6><b>Learning Method: </b></h6>
                                <p>
                                    Once you enroll, all your learning materials will be available through our online portal. If you need any accessibility arrangements, please let our team know.
                                    <br /><br />
                                    You'll receive full support throughout your course, including one-on-one online tutorials and group sessions.

                                </p>

                                <p>
                                    <b>Flexible Online Learning</b><br />
                                    Studying online allows you to fit your learning around your existing commitments. Whether you're working or caring for family, you can complete your course at your own pace and move closer to your goals.
                                </p>
                            </Tab>
                            <Tab eventKey="Assessment Method" title="Assessment Method">
                                <br />
                                <h6><b>Assessment Method: </b></h6>
                                <p>Your assignments are due at the end of each unit and will be marked by either a tutor or a computer.
                                    <br /><br />
                                    Your assigned college will provide access to MS Office through your learning portal, so you can complete your assessments easily.
                                </p>
                                <b>Qualification & Certification</b>
                                <br />
                                After completing your course, you will receive a <b>Level 3 nationally recognized qualification</b> regulated by the <b>Quality Assurance Agency for Higher Education (QAA)</b>. Your certificate is typically issued <b>12-16 weeks</b> after your final assessment is graded, but this may vary depending on the time of year.

                            </Tab>
                        </Tabs>
                        <br />
                        <hr />



                    </div>
                    <div className="col-md-4 info-box-courses">
                        <Card style={{ width: "18rem", color: "black" }} className="wd">
                            <Card.Body style={{ boxShadow: "none" }}>
                                <Card.Title>KEY INFORMATION</Card.Title>
                                <Table striped bordered>
                                    <thead>
                                        <tbody>
                                            <tr>
                                                <td>Course Length</td>
                                                <td>{product.courseLength}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Provider</td>
                                                <td>{product.awardingBody}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Level</td>
                                                <td>{product.courseLevel}</td>
                                            </tr>
                                            <tr>
                                                <td>Funding (subject to availability)</td>
                                                <td>Advance Learner Loan</td>
                                            </tr>
                                            <tr>
                                                <td>Learning Method(s)</td>
                                                <td>{product.learningMethods}</td>
                                            </tr>
                                        </tbody>
                                    </thead>
                                </Table>
                                <HashLink
                                    className="btn btn-primary co-button vv "
                                    to={'/form/' + "Access to Biomedical Science" + '#top'}
                                    onClick={() => trackApplyButton()}
                                >
                                    Apply Now
                                </HashLink>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <section className="services section-bg" id="partners" style={{ borderTop: "1px solid #eaeaea", marginTop: "30px", paddingTop: "0px" }}>
                <div className="container">
                    <div className="row social-logo-section1" >
                        <div className="col">
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    <center>
                        <div className="row social-logo-section only-on-mobile" >
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                        </div>
                    </center>
                </div>

            </section>
        </div>
    )
}

export default AccessInBiomedicalScience