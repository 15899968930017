import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ScrollToMount from '../../common/ScrollToMount'


import FormContainer from './FormContainer'
import { saveQualificationDetails, saveEnglishAssessmentRnnForm } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import axios from 'axios'
import { trackStep5Qualification } from '../../common/analyticsGA4'
const QualificationForm = ({ history }) => {
    const form = useSelector((state) => state.form)

    const { qualificationDetails, detailsFormData, personalDetails } = form
    const form1 = useSelector((state) => state.userDetails)
    const { formDetails } = form1
    // const [areYou2, setAreYou2] = useState()

    const [level, setLevel] = useState(qualificationDetails.level)
    const [fullTimeEducationElse, setFullTimeEducationElse] = useState(qualificationDetails.fullTimeEducationElse)
    const [mathGrades, setMathGrades] = useState(qualificationDetails.mathGrades)
    const [englishGrades, setEnglishGrades] = useState(qualificationDetails.englishGrades)
    const [GCSEEnglishabove, setGCSEEnglishabove] = useState(qualificationDetails.GCSEEnglishabove || false)
    const [GCSEMathsabove, setGCSEMathsabove] = useState(qualificationDetails.GCSEMathsabove || false)
    const [currentEducationLocation, setCurrentEducationLocation] = useState(qualificationDetails.currentEducationLocation)
    const [currentEducationCourse, setCurrentEducationCourse] = useState(qualificationDetails.currentEducationCourse)
    const [currentEducationFunding, setCurrentEducationFunding] = useState(qualificationDetails.currentEducationFunding)
    const [currentEducationStartDate, setCurrentEducationStartDate] = useState(qualificationDetails.currentEducationStartDate)
    const [currentEducationEndDate, setCurrentEducationEndDate] = useState(qualificationDetails.currentEducationEndDate)

    const [pastLearning, setPastLearning] = useState(qualificationDetails.pastLearning || false)
    const [preferredUniversityName, setPreferredUniversityName] = useState(qualificationDetails.preferredUniversityName)
    const [preferredUniversityStartDate, setPreferredUniversityStartDate] = useState(qualificationDetails.preferredUniversityStartDate)
    const [intendedDegreeTitle, setIntendedDegreeTitle] = useState(qualificationDetails.intendedDegreeTitle)
    const [intendedCareerToPersue, setIntendedCareerToPersue] = useState(qualificationDetails.intendedCareerToPersue)
    const [englishAchievedDate, setEnglishAchievedDate] = useState(qualificationDetails.englishAchievedDate)
    const [englishCollege, setEnglishCollege] = useState(qualificationDetails.englishCollege)
    const [mathsAchievedDate, setMathsAchievedDate] = useState(qualificationDetails.mathsAchievedDate)
    const [mathsCollege, setMathsCollege] = useState(qualificationDetails.mathsCollege)
    const [accessError, setAccessError] = useState("")


    const [showFields, setShowFields] = useState(true)
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(false)
    const [words, setWords] = useState()
    const [newDataAfterChange, setNewDataAfterChange] = useState()

    const [level3QualificationCheck, setLevel3QualificationCheck] = useState(false)
    const [liveInEngland, setLiveInEngland] = useState(false)
    const [ukFor3Years, setUkFor3Years] = useState(false)

    const [assessment, setAssessment] = useState(qualificationDetails.assessment)
    const [attachments, setAttachments] = useState()
    const wordCounter = (string) => {
        return (string.split(" ").length - 1);
    }

    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [showDownGradePopup, setShowDownGradePopup] = useState(false);
    const [accessCourseChange, setAccessCourseChange] = useState(false);
    const [accessCourseBeingChanged, setAccessCourseBeingChanged] = useState('')


    const [suggestedCourses, setSuggestedCourses] = useState([])
    const dispatch = useDispatch()

    const uploadImage = async (file, folderName) => {
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', folderName)
        data.append('cloud_name', 'my-free-course')
        const res = await fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        });
        const res_1 = await res.json();
        setLoading(false);
        return res_1.url;
    }

    const uploadingArrayImages = async (files) => {
        const links = await Promise.all(
            Array.from(files).map(file => uploadImage(file, "evidence"))
        );

        console.log("Links", links);
        return links;
    };



    useEffect(async () => {
        // Learn Direct Access COurse
        if (localStorage.getItem('selectedContract') === "6775148b34f6ad497a9526b7" || localStorage.getItem('selectedContract') === "67d020a4ab66b423a6fb9a47") {
            let response = await axios.get(process.env.REACT_APP_API_URL + '/course/')
            let data = response.data.data.sort((a, b) => a.courseCode.localeCompare(b.courseCode))
            setSuggestedCourses(data)
        }
    }, [])

    const courseCheck = () => {
        if (detailsFormData.appliedCourse === "Preparing to work in Adult Social Care level 3") {
            if (level === 'None' ||
                level === 'Entry Level' ||
                level === 'Level 1') {
                return false
            }
            else {
                return true
            }
        }
        else return true;

    }
    const courseCheckAccess = () => {
        console.log("here")
        let course = localStorage.getItem('appliedCourse')
        if (localStorage.getItem('selectedContract') === "6775148b34f6ad497a9526b7" && (
            course === "Access in education" ||
            course === "Access in health and social care" ||
            course === "Access in health science professions" ||
            course === "Access in psychology" ||
            course === "Access to Midwifery" ||
            course === "Access in paramedic science"
        )) {
            setAccessError("Unfortunately, you require a level 2 in both Maths and English to be eligible.")
            if (level === 'None' ||
                level === 'Entry Level' ||
                level === 'Level 1' ||
                GCSEEnglishabove === false ||
                GCSEMathsabove === false
            ) {
                return false
            }
            else {
                return true
            }
        }
        //HOY ACCESS
        else if (localStorage.getItem('selectedContract') === "67d020a4ab66b423a6fb9a47") {
            console.log("here2")

            const checkCourseAccessHOY = () => {
                if (course === "Access to Computing" || course === "Access to Engineering") {
                    console.log("here4")
                    if (GCSEMathsabove) {
                        return true
                    } else {
                        console.log("here5")
                        setAccessError("Unfortunately, you require a level 2 in Maths to be eligible, You would have to complete level 2 in English later.")
                        return false
                    }
                } else {
                    console.log("here6")
                    if (GCSEMathsabove || GCSEEnglishabove) {
                        return true
                    } else {
                        console.log("here7")
                        alert(" You can continue the application but you will need to complete Level 2 Maths/English at a later date etc")
                        return true
                    }
                }
            }

            if (parseInt(personalDetails.age) <= 23 && parseInt(personalDetails.age) >= 19) {
                console.log("here8")
                if (level === 'None' ||
                    level === 'Entry Level' ||
                    level === 'Level 1' ||
                    level === 'Level 2' ||
                    level === 'Full Level 2' ||
                    level === 'Level 3'
                ) {
                    console.log("here9")
                    setAccessError("Unfortunately, you require a Full level 3 and level 2 in Maths or English to be eligible, You would have to complete level 2 in the other later.")
                    return false
                }
                else {
                    console.log("here10")
                    return checkCourseAccessHOY()
                }
            } else {
                console.log("here11")
                if (level === 'None' ||
                    level === 'Entry Level' ||
                    level === 'Level 1' ||
                    level === 'Level 2'
                ) {
                    console.log("here12")
                    setAccessError("Unfortunately, you require a Full level 2 qualification and level 2 in Maths or English to be eligible, You would have to complete level 2 in the other later.")
                    return false
                } else {
                    console.log("here13")
                    return checkCourseAccessHOY()
                }
            }
        }
        else return true;

    }

    const submitHandler = (e) => {
        if ((localStorage.getItem('softEnglishTest') === "true") && wordCounter(assessment) < 10) {
            toast.error('Minimum 10 words required')
            e.preventDefault()
            e.stopPropagation()
        } else if (!courseCheck()) {
            e.preventDefault()
            e.stopPropagation()
            console.log("Not fullfilled requirements")
            alert("You must have at least a level 2 qualification to qualify.");
            history.push('/qualification')
        } else if (!courseCheckAccess()) {
            console.log("here3")
            e.preventDefault()
            e.stopPropagation()
            console.log("Not fullfilled requirements")
            setAccessCourseChange(true)
            history.push('/qualification')
        }
        else {
            const form = e.currentTarget
            if (form.checkValidity() === false) {
                e.preventDefault()
                e.stopPropagation()
                var element = document.getElementById('Error-msg-form')
                element.classList.remove('Error-msg-form-displayNone')
                element.classList.add('Error-msg-form-display')
                document.getElementById('Error-msg-form').scrollIntoView({
                    behavior: 'smooth',
                })
            } else {
                e.preventDefault()
                dispatch(saveQualificationDetails({
                    level,
                    mathGrades,
                    englishGrades,
                    GCSEEnglishabove,
                    GCSEMathsabove,
                    assessment,
                    fullTimeEducationElse,
                    currentEducationLocation: currentEducationLocation,
                    currentEducationCourse: currentEducationCourse,
                    currentEducationFunding: currentEducationFunding,
                    currentEducationStartDate: currentEducationStartDate,
                    currentEducationEndDate: currentEducationEndDate,
                    pastLearning: pastLearning,
                    preferredUniversityName: preferredUniversityName,
                    preferredUniversityStartDate: preferredUniversityStartDate,
                    intendedDegreeTitle: intendedDegreeTitle,
                    intendedCareerToPersue: intendedCareerToPersue,
                    englishAchievedDate: englishAchievedDate,
                    englishCollege: englishCollege,
                    mathsAchievedDate: mathsAchievedDate,
                    mathsCollege: mathsCollege,
                    attachments: attachments

                }))
                // dispatch(saveEnglishAssessmentRnnForm(assessment))
                trackStep5Qualification()
                history.push('/oppurtunities')
            }
            setValidated(true)
        }
    }
    const changeValue = (value) => {
        setAssessment(value);
        setWords(wordCounter(value));
    }

    const handleStatus = async (newStatus, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/users/change_status', {
            formId: formId,
            status: newStatus,
        })
        if (response.status === 200) {
            toast('Status Changed')
            return true
        } else {
            return false
        }
    }

    const handleChange = async (e) => {
        setLevel(e.target.value)
        console.log(e.target.value)
        let value = e.target.value
        let employmentDetails = JSON.parse(localStorage.getItem('employmentDetails'))
        if (localStorage.getItem('affiliatedCourse')) {
            if (localStorage.getItem('level') === 'Level 2') {
                if (employmentDetails.employementStatus === 'Employed but on less than £17,374.50 per year' ||
                    employmentDetails.employementStatus === 'Greater London Employed but on less than £21,547' ||
                    employmentDetails.employementStatus === 'Unemployed, looking for work') {
                    if (value !== 'None' &&
                        value !== "Entry Level" &&
                        value !== "Level 1") {
                        const res = await axios.post(process.env.REACT_APP_API_URL + '/form/college-finder/', {
                            appliedCourse: localStorage.getItem('affiliatedCourse'),
                            PostCodeBL: localStorage.getItem('PostCodeBL'),
                        })
                        if (res.data.success) {
                            setShowUpgradePopup(true)
                            console.log(res.data)
                            setNewDataAfterChange(res.data)
                        } else {
                            await handleStatus("Deleted", localStorage.getItem('incFormId'))
                            toast.error("Sorry, You dont qualify for any of our colleges.")
                            history.push('/')
                        }
                    }
                }
            } else {
                let changeCourse = false
                if (employmentDetails.employementStatus !== 'Employed but on less than £17,374.50 per year' &&
                    employmentDetails.employementStatus !== 'Greater London Employed but on less than £21,547' &&
                    employmentDetails.employementStatus !== 'Unemployed, looking for work') {
                    console.log("Employed case")
                    changeCourse = true
                } else {
                    console.log("employmentDetails", employmentDetails)
                    //not on benefits but unemlpoyed
                    if (employmentDetails.employementStatus === 'Unemployed, looking for work') {
                        if (employmentDetails.dValue1 ||
                            employmentDetails.dValue2 ||
                            employmentDetails.dValue3 ||
                            employmentDetails.dValue5 ||
                            employmentDetails.dValue6 ||
                            employmentDetails.dValue7 ||
                            employmentDetails.dValue8 ||
                            employmentDetails.dValue9
                        ) {
                        } else {
                            console.log("no benefits")
                            changeCourse = true
                        }
                    }


                    if (value === 'None' ||
                        value === "Entry Level" ||
                        value === "Level 1"
                    ) {
                        console.log("qualification case")

                        changeCourse = true
                    }
                }

                //retired Case
                if (employmentDetails.dValue10 === 'Yes') {
                    console.log("retired case", employmentDetails.dValue10)

                    changeCourse = true
                }


                if (changeCourse) {
                    const res = await axios.post(process.env.REACT_APP_API_URL + '/form/college-finder/', {
                        appliedCourse: localStorage.getItem('affiliatedCourse'),
                        PostCodeBL: localStorage.getItem('PostCodeBL'),
                    })
                    if (res.data.success) {
                        setShowDownGradePopup(true)
                        console.log(res.data)
                        setNewDataAfterChange(res.data)
                        handleDownGradeOfCourse(res.data)
                    } else {
                        await handleStatus("Deleted", localStorage.getItem('incFormId'))
                        toast.error("Sorry, You dont qualify for any of our colleges.")
                        history.push('/')
                    }
                }

            }
        }

        if (value === 'None') {
            setShowFields(false)
        } else {
            setShowFields(true)
        }
    }

    const handleChangeOfCourse = async () => {
        if (!level3QualificationCheck || !liveInEngland || !ukFor3Years) {
            toast.error("You have to Fullfill all the requirements to accept.")
        } else {
            const response = await axios.post('/form/incomplete-update-college', {
                id: localStorage.getItem('incFormId'),
                collegeID: newDataAfterChange.college._id,
                contractId: newDataAfterChange.contract._id,
                appliedCourse: localStorage.getItem('affiliatedCourse')
            });
            console.log(response)
            if (response.status === 200) {
                const chosenContract = newDataAfterChange.contract
                const chosenCollege = newDataAfterChange.college
                localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
                localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
                localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
                localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
                localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
                localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
                localStorage.setItem('proofs', chosenCollege.proofs)
                localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
                localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)
                localStorage.setItem('level', 'Level 3')
                localStorage.setItem('appliedCourse', localStorage.getItem('affiliatedCourse'))
                localStorage.setItem('selectedContract', newDataAfterChange.contract._id)
                localStorage.setItem('selectedCollege', newDataAfterChange.college._id)
                setShowUpgradePopup(false)
            } else {
                toast.error("Some error occurred. Please continue")
                setShowUpgradePopup(false)
            }
        }
    }

    const handleAccessCourseShifting = async () => {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/form/college-finder/', {
            appliedCourse: accessCourseBeingChanged,
            PostCodeBL: localStorage.getItem('PostCodeBL'),
        })

        const chosenContract = res.data.contract
        const chosenCollege = res.data.college

        const response = await axios.post('/form/incomplete-update-college', {
            id: localStorage.getItem('incFormId'),
            collegeID: chosenCollege._id,
            contractId: chosenContract._id,
            appliedCourse: accessCourseBeingChanged
        });
        console.log(response)
        if (response.status === 200) {
            localStorage.setItem('level', 'Level 2')
            localStorage.setItem('appliedCourse', accessCourseBeingChanged)
            localStorage.setItem('selectedContract', chosenContract._id)
            localStorage.setItem('selectedCollege', chosenCollege._id)
            localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
            localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
            localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
            localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
            localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
            localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
            localStorage.setItem('proofs', chosenCollege.proofs)
            localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
            localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)

            setAccessCourseChange(false)
        }
    }

    const handleDownGradeOfCourse = async (data) => {
        const newData = newDataAfterChange || data
        const response = await axios.post('/form/incomplete-update-college', {
            id: localStorage.getItem('incFormId'),
            collegeID: newData.college._id,
            contractId: newData.contract._id,
            appliedCourse: localStorage.getItem('affiliatedCourse')
        });
        console.log(response)
        if (response.status === 200) {
            const chosenContract = newData.contract
            const chosenCollege = newData.college
            localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
            localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
            localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
            localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
            localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
            localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
            localStorage.setItem('proofs', chosenCollege.proofs)
            localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
            localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)
            localStorage.setItem('level', 'Level 2')
            localStorage.setItem('appliedCourse', localStorage.getItem('affiliatedCourse'))
            localStorage.setItem('selectedContract', newData.contract._id)
            localStorage.setItem('selectedCollege', newData.college._id)

        } else {
            toast.error("Some error occurred. Please continue")
            setShowDownGradePopup(false)
        }
    }


    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <FormContainer>
                <ScrollToMount />
                <FormCompletetionSteps step1 step2 step3 step4 step5 />

                <h1>Step 5: Qualification Details</h1>

                {(localStorage.getItem('selectedContract') !== "6775148b34f6ad497a9526b7") ?
                    <Form.Label className='form-notice'>
                        If you are applying for a Level 3 course you need to already have a Level 2 qualification or you will not be eligible.
                    </Form.Label>
                    :
                    <Form.Label className='form-notice'>
                        If you are applying for access course, you need to have a level 2 in fundament maths or English.
                    </Form.Label>
                }
                <div
                    className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
                    id='Error-msg-form'
                >
                    <p>Please fix one or more fields.</p>
                </div>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Group controlId='level'>
                        <Form.Label>What is your highest level of qualification? </Form.Label>
                        <Form.Control as='select' required value={level} onChange={handleChange}>
                            <option selected disabled value=''>
                                [Please select one]
                            </option>
                            <option value='None'>None, not sure or n/a</option>
                            <option value='Entry Level'>
                                Entry Level - (e.g. Basic/Foundation Level, Word Power or Number
                                Power, GCF Entry Level Award/Certificate)
                            </option>
                            <option value='Level 1'>
                                Level 1 - (e.g. GC SE(5 or more grades D-G or fewer than 5 grades
                                A*-C), AS Level, CSE below grade 1, GNVQ Foundation, QCF Level 1
                                Award/Certificate/ Diploma or NVQ 1)
                            </option>
                            <option value='Level 2'>
                                Level 2 (e.g. 5 or more GCSE/O Levels at grades A* - C, 1 A Level,
                                2/3 AS Levels, 5 or more CSE grade 1’s, GNVQ Intermediate, QCF
                                Diploma Level 2)
                            </option>
                            <option value='Full Level 2'>
                                Full Level 2 (e.g. 5 or more GCSE/O Levels at grades A* - C, 1 A
                                Level, 2/3 AS Levels, 5 or more CSE grade 1’s, GNVQ Intermediate,
                                QCF Diploma Level 2)
                            </option>
                            <option value='Level 3'>
                                Level 3 - (e.g. 4 or more AS Levels, 2 or more A Levels, BTEC
                                Diploma, BTEC Extended Diplomas, QAA Access to Higher Education, QCF
                                Diplomas Level 3)
                            </option>
                            <option value='Full Level 3'>
                                Full Level 3 - (e.g. 4 or more AS Levels, 2 or more A Levels, BTEC
                                Diploma, BTEC Extended Diplomas, QAA Access to Higher Education, QCF
                                Diplomas Level 3)
                            </option>
                            <option value='Level 4'>
                                Level 4 - (e.g. Higher National Certificate (HNC), QCF Level 4
                                Award/Certificate/Diploma or NVQ Level 4)
                            </option>
                            <option value='Level 5'>
                                Level 5 - (e.g. Foundation Degree, Higher National Diploma (HND) or
                                QCF Level 5 Award/Certificate/Diploma)
                            </option>
                            <option value='Level 6'>
                                Level 6 - (e.g. Bachelors Degree, Professional Graduate Certificate
                                in Education (PGCE) or QCF Level 6 Award/Certificate/Diploma)
                            </option>
                            <option value='Level 7'>Level 7</option>
                            <option value='Level 7 and above'>Level 7 and Above</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {(level !== "None") && (
                        <>
                            <Form.Group controlId='CHeckbox1'>
                                <Form.Check
                                    type='checkbox'
                                    label={((localStorage.getItem('selectedCollege') !== "676fc6eac6cf1ffc3d18417c") && localStorage.getItem('selectedContract') !== "67d020a4ab66b423a6fb9a47") ? 'Do you have GCSE English Grade 4/C and above?' : 'Do you have GCSE English Grade 4/C and above or an equivalent level 2 (functional skills, key skills etc)?'}
                                    className='mr-2'
                                    checked={GCSEEnglishabove}
                                    name='GCSE English above'
                                    onChange={(e) => {
                                        setGCSEEnglishabove(!GCSEEnglishabove)
                                    }}
                                ></Form.Check>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='CHeckbox2'>
                                <Form.Check
                                    type='checkbox'
                                    label={((localStorage.getItem('selectedCollege') !== "676fc6eac6cf1ffc3d18417c") && localStorage.getItem('selectedContract') !== "67d020a4ab66b423a6fb9a47") ? 'Do you have GCSE Maths Grade 4/C and above?' : 'Do you have GCSE Maths Grade 4/C and above or an equivalent level 2 (functional skills, key skills etc)?'}
                                    className='mr-2'
                                    value={GCSEMathsabove}
                                    name='GCSE Maths above'
                                    onChange={(e) => {
                                        setGCSEMathsabove(!GCSEMathsabove)
                                    }}
                                ></Form.Check>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group controlId='englishGrades'>
                                <Form.Label>English GCSE Grades:</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={englishGrades}
                                    onChange={(e) => setEnglishGrades(e.target.value)}
                                >

                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='A*'>A*</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                    <option value='D'>D</option>
                                    <option value='E'>E</option>
                                    <option value='F'>F</option>
                                    <option value='G'>G</option>
                                    <option value='U'>U</option>
                                    <option value='9'>9</option>
                                    <option value='8'>8</option>
                                    <option value='7'>7</option>
                                    <option value='6'>6</option>
                                    <option value='5'>5</option>
                                    <option value='4'>4</option>
                                    <option value='3'>3</option>
                                    <option value='2'>2</option>
                                    <option value='1'>1</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group controlId='mathGrades'>
                                <Form.Label>Maths GCSE Grades:</Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={mathGrades}
                                    onChange={(e) => setMathGrades(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='A*'>A*</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                    <option value='D'>D</option>
                                    <option value='E'>E</option>
                                    <option value='F'>F</option>
                                    <option value='G'>G</option>
                                    <option value='U'>U</option>
                                    <option value='9'>9</option>
                                    <option value='8'>8</option>
                                    <option value='7'>7</option>
                                    <option value='6'>6</option>
                                    <option value='5'>5</option>
                                    <option value='4'>4</option>
                                    <option value='3'>3</option>
                                    <option value='2'>2</option>
                                    <option value='1'>1</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </>

                    )}
                    {(localStorage.getItem('fullTimeEducationElse') === "true") && <>

                        <Form.Group>
                            <Form.Label>
                                Are you in full time education elsewhere?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={fullTimeEducationElse}
                                onChange={(e) => setFullTimeEducationElse(e.target.value)}
                            >
                                <option selected disabled value=''>
                                    [Please select one]
                                </option>
                                <option value='Yes'>Yes</option>
                                <option value='No'> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {((localStorage.getItem('selectedCollege') === "676fc6eac6cf1ffc3d18417c") && fullTimeEducationElse === "Yes") && <>
                            <Form.Group controlId='currentEducationCourse'>
                                <Form.Label>Current Education Course</Form.Label>
                                <Form.Control
                                    type='text'
                                    required
                                    value={currentEducationCourse}
                                    onChange={(e) => setCurrentEducationCourse(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='currentEducationLocation'>
                                <Form.Label>Current Education Location (Institute)</Form.Label>
                                <Form.Control
                                    type='text'
                                    required
                                    value={currentEducationLocation}
                                    onChange={(e) => setCurrentEducationLocation(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='currentEducationFunding'>
                                <Form.Label>Current Education Funding</Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={currentEducationFunding}
                                    onChange={(e) => setCurrentEducationFunding(e.target.value)}
                                >
                                    <option disabled selected value=''>Please Select One</option>
                                    <option value="Privately funded">Privately funded</option>
                                    <option value="Loan">Loan</option>
                                    <option value="Government funded">Government funded</option>
                                    <option value="I am unsure">I am unsure</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='currentEducationStartDate'>
                                <Form.Label>Current Education Start Date</Form.Label>
                                <Form.Control
                                    type='date'
                                    required
                                    value={currentEducationStartDate}
                                    onChange={(e) => setCurrentEducationStartDate(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='currentEducationEndDate'>
                                <Form.Label>Current Education End Date</Form.Label>
                                <Form.Control
                                    type='date'
                                    required
                                    value={currentEducationEndDate}
                                    onChange={(e) => setCurrentEducationEndDate(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </>}



                    </>}

                    {(localStorage.getItem('selectedContract') === "6775148b34f6ad497a9526b7") && <>
                        <Form.Group controlId='pastLearning'>
                            <Form.Label>Done any learning in the past 3 years?</Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={pastLearning}
                                onChange={(e) => setPastLearning(e.target.value)}
                            >
                                <option selected disabled value=''>
                                    [Please select one]
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='preferredUniversityName'>
                            <Form.Label>Preferred University Name</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={preferredUniversityName}
                                onChange={(e) => setPreferredUniversityName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group controlId='preferredUniversityStartDate'>
                            <Form.Label>Preferred University Start Date</Form.Label>
                            <Form.Control
                                type='date'
                                value={preferredUniversityStartDate}
                                onChange={(e) => setPreferredUniversityStartDate(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                        <Form.Group controlId='intendedDegreeTitle'>
                            <Form.Label>Title of the degree intended to study</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={intendedDegreeTitle}
                                onChange={(e) => setIntendedDegreeTitle(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='intendedCareerToPersue'>
                            <Form.Label>Career intending to pursue</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={intendedCareerToPersue}
                                onChange={(e) => setIntendedCareerToPersue(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='englishCollege'>
                            <Form.Label>Name of College (English Qualification)</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={englishCollege}
                                onChange={(e) => setEnglishCollege(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group controlId='englishAchievedDate'>
                            <Form.Label>Acheived Date (English Qualification)</Form.Label>
                            <Form.Control
                                type='date'
                                value={englishAchievedDate}
                                onChange={(e) => setEnglishAchievedDate(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                        <Form.Group controlId='mathsCollege'>
                            <Form.Label>Name of College (Maths Qualification)</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={mathsCollege}
                                onChange={(e) => setMathsCollege(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group controlId='mathsAchievedDate'>
                            <Form.Label>Acheived Date (Maths Qualification)</Form.Label>
                            <Form.Control
                                type='date'
                                value={mathsAchievedDate}
                                onChange={(e) => setMathsAchievedDate(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </>}

                    {(localStorage.getItem('softEnglishTest') === "true") && <>
                        <Form.Group>
                            <Form.Label>Please briefly tell us why you wish to study this course or what you hope to achieve by completing the course. </Form.Label>
                            <Form.Control
                                as='textarea'
                                required
                                rows={3}
                                value={assessment}
                                onChange={(e) => changeValue(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <p style={{ float: "right", fontSize: "12px" }}>{words || "0"} Words</p><br />

                    </>}

                    {(localStorage.getItem('selectedContract') === "67d020a4ab66b423a6fb9a47") &&
                        <>
                            <br />
                            <div className='form-notice'>
                                Proofs accepted are: GCSE results, GCSE certificates, School record of achievement, Functional skills certificates, Key Skills in Communication, Key Skills in Application of Number, Foreign certificates, Naric certificates
                            </div><Form.Group controlId='certificates'>
                                <Form.Label>Certificates</Form.Label>
                                <Form.Control
                                    type='file'
                                    multiple
                                    accept='image/*,application/pdf'
                                    onChange={async (e) => setAttachments(await uploadingArrayImages(e.target.files))}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please upload required file.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>}

                    <br />
                    <Button
                        onClick={(e) => {
                            window.location.href = '/employment'
                        }}
                        variant='primary'
                        className='mr-5 back-Button-form'
                    >
                        Back
                    </Button>

                    <Button
                        type='submit'
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                        }}
                        className='mrt next-Button-form'
                    >
                        Save Progress & Continue
                    </Button>
                </Form>
            </FormContainer>



            <Modal show={showUpgradePopup} onHide={() => setShowUpgradePopup(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Congratulations, you qualify for the Level 3 course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Congratulations, based on your application answers and qualification level, you meet the criteria to undertake a Level 3 qualification (equivalent to A Level) instead of a Full Level 2 (equivalent to GCSE) in your chosen subject.
                    <br />
                    You will still receive tutor support and guidance but now have a longer timeframe (up to 6 months) to complete the level 3 qualification.
                    <br />
                    <br />

                    <b>To change to the Level 3 course please confirm you meet the below requirements and continue or press cancel to resume your Level 2  course application instead</b>

                    <Form.Group controlId='level3qualification'>
                        <Form.Check
                            label='You do not hold a Level 3 qualification in any subject'
                            type='checkbox'
                            checked={level3QualificationCheck}
                            onChange={(e) => setLevel3QualificationCheck(!level3QualificationCheck)}
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>
                    <Form.Group controlId='liveInEngland'>
                        <Form.Check
                            label='Currently live in England'
                            type='checkbox'
                            checked={liveInEngland}
                            onChange={(e) => setLiveInEngland(!liveInEngland)}
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>
                    <Form.Group controlId='ukFor3Years'>
                        <Form.Check
                            label='Resident in the UK/EU for 3+ years, for purposes other than study.'
                            type='checkbox'
                            checked={ukFor3Years}
                            onChange={(e) => setUkFor3Years(!ukFor3Years)}
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <Button
                        variant='primary'
                        className='mr-5 back-Button-form'
                        onClick={() => setShowUpgradePopup(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                            width: '100%'
                        }}
                        className='mrt next-Button-form'
                        onClick={() => handleChangeOfCourse()}
                    >
                        Shift to {localStorage.getItem('affiliatedCourse')}
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showDownGradePopup} onHide={() => setShowDownGradePopup(true)} >
                <Modal.Header >
                    <Modal.Title>You do not qualify</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Unfortunately, based on your answers for qualification levels, you do NOT meet the criteria to undertake this Level 3 qualification. In order to apply for this course you will need to hold a Level 2 in Math or English or have an equivalent. If you would like to complete the required Maths or English course, you can select on the drop down and your application will be switched across.
                    <br />
                    <br />

                    <b>To change to the Level 2 course please click below to continue</b>

                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <Button
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                            width: '100%'
                        }}
                        className='mrt next-Button-form'
                        onClick={() => setShowDownGradePopup(false)}
                    >
                        Shift to {localStorage.getItem('affiliatedCourse')}
                    </Button>
                </Modal.Footer>
            </Modal>



            {(localStorage.getItem('selectedCollege') === "676fc6eac6cf1ffc3d18417c" || (localStorage.getItem('selectedContract') === "67d020a4ab66b423a6fb9a47")) && <><Modal show={accessCourseChange} onHide={() => setAccessCourseChange(true)} >
                <Modal.Header >
                    <Modal.Title>You do not qualify</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Thank you for your interest in our {localStorage.getItem('appliedCourse')}
                    <br />
                    <br />
                    {accessError}
                    <br />
                    <br />
                    Our functional Skills Maths and English courses should be resuming in March of 2025. If you would like to be put on a wait list our team will be in touch with you in due course.
                    <br />
                    <br />

                    Alternatively, you can select from one of our level 2 courses below to do in the meantime whilst you wait. <br />
                    <br />
                    <Form.Group controlId='accessCourseBeingChanged'>
                        <Form.Label>Shift course to:</Form.Label>
                        <Form.Control
                            as="select"
                            required
                            value={accessCourseBeingChanged}
                            onChange={(e) => setAccessCourseBeingChanged(e.target.value)}
                        >

                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            {suggestedCourses.map(x => <option value={x.courseCode}>{x.courseCode}</option>)}

                            {/* <option value='Functional Skills English'>Functional Skills English</option>
                            <option value='Functional Skills Maths'>Functional Skills Maths</option> */}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <Button
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                            width: '100%'
                        }}
                        disabled={accessCourseBeingChanged === ''}
                        className='mrt next-Button-form'
                        onClick={() => handleAccessCourseShifting()}
                    >
                        Shift to {accessCourseBeingChanged}
                    </Button>
                </Modal.Footer>
            </Modal>
            </>}
            {loading && <div id='spinner-finalstep' style={{ zIndex: '999' }}>
                <center>
                    <Spinner animation='border' />
                </center>
            </div>}
        </>

    )
}

export default QualificationForm
