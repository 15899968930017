import React from "react";
import { Button, Grid, Container } from "@material-ui/core/";
import Badge from 'react-bootstrap/Badge';


const Posts = (props) => {
    const { posts } = props;


    const coursesPosts = [
        // {
        //     "courseTitle": "Access to Higher Education Diploma in Education Professions",
        //     "shortDescription": "Do you have a passion for teaching? Have you considered career aspects in teaching? Do you want to help mould the next generation? If so you can take the first step today in starting your journey to your career in Education!",
        //     "courseLevel": "Level 3",
        //     "funding": "Funded",
        //     "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Maths_Eng_gdkky2.jpg",
        //     'url': '/access-in-education'
        // }, {
        //     "courseTitle": "Access to Higher Education Diploma in Health and Social Care",
        //     "shortDescription": "Do you have a passion for giving back to communities? Do you enjoy supporting people? Have you considered learning more about health and social care and how they play a significant role in the functionality of our societies?",
        //     "courseLevel": "Level 3",
        //     "funding": "Funded",
        //     "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Access_to_health_and_Social_Care_ohnid2.jpg",
        //     'url': '/access-in-health'
        // },
        {
            "courseTitle": "Access to Higher Education Psychology",
            "shortDescription": "Are you interested in understanding what drives people and why they act the way they do? Studying psychology lets you explore the mind and uncover the various factors that influence how people think, feel, and behave. You'll learn how motivations and past experiences shape a person’s actions, reactions, and relationships.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loans",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Health_Science_Professionals_bi4n68.jpg",
            'url': '/access-in-psychology'
        },
        // {
        //     "courseTitle": "Function Skills Maths and English",
        //     "shortDescription": "Functional Skills are essential for success in any form of future study, qualifications and work opportunities. As a recognised alternative to GCSE qualifications, Functional Skills courses were created to offer you the most convenient solution to learn and develop practical skills in the essential subjects of maths and English.",
        //     "courseLevel": "Level 3",
        //     "funding": "Funded",
        //     "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Maths_Eng_gdkky2.jpg",
        //     'url': '/functional-skills-maths-english'
        // },
        {
            "courseTitle": "Access to Higher Education Nursing",
            "shortDescription": "Take the first step toward a rewarding nursing career with this university-recognized diploma. Study key topics like healthcare communication, equality and diversity, health promotion, psychology, and professional behaviors, while building essential study skills for higher education. Designed for those without traditional qualifications, this flexible online course provides a direct pathway to a Nursing degree—helping you turn your passion for care into a profession.",
            "courseLevel": "Level 3",
            "funding": "Advance learner loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736940251/Courses/dz91fpnvndep1vbkbbzo.jpg",
            'url': '/access-to-nursing'
        },
        {
            "courseTitle": "Access to Higher Education Midwifery",
            "shortDescription": "Prepare for a career in maternal care with our Access to HE Diploma (Midwifery). This online course covers essential topics such as prenatal, intrapartum, and postnatal care, equipping you with the knowledge needed to apply for a midwifery degree. Designed for those without traditional qualifications, it provides a flexible pathway to university and a rewarding future in midwifery.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736940314/Midwife_pizfry.jpg",
            'url': '/access-to-midwifery'
        },
        {
            "courseTitle": "Access to Higher Education Paramedic Science",
            "shortDescription": "Do you have a passion for helping others and a goal of becoming a paramedic? Paramedics are vital to healthcare, delivering life-saving care in emergencies and responding to critical situations. This course provides a solid foundation in paramedic science while also strengthening your study skills to help you succeed in higher education.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736976334/Untitled_design_1_ew62i0.jpg",
            'url': '/access-to-paramedic-science'
        },
        {
            "courseTitle": "Access to Higher Education Diploma in Health Science Professions",
            "shortDescription": "If you're interested in a healthcare career but unsure which path to take, the Access to Higher Education Diploma (Health Science Professions) is a great starting point. This course introduces you to various healthcare fields and prepares you for degree-level study",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1736276457/Courses/Access_to_Psychology_aambtu.jpg",
            'url': '/access-in-health-science'
        },
        {
            "courseTitle": "Access to Higher Education Biomedical Science",
            "shortDescription": "If you're fascinated by laboratory research and the scientific breakthroughs that drive progress in health, science, and industry, the Access to Higher Education Diploma (Biomedical Science) is the perfect starting point. This course is designed for curious problem solvers who are passionate about science and eager to pursue a career in biomedical research and innovation.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Biomedical_Science_vc4vey.jpg",
            'url': '/access-to-biomedical-science'
        },
        {
            "courseTitle": "Access to Higher Education Engineering",
            "shortDescription": "Engineering is a vital skill needed across many industries, from electronics to defense. This course opens the door to exciting opportunities, equipping you with the knowledge and expertise to make a meaningful impact in the field.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Engineering_l4d4j9.jpg",
            'url': '/access-to-engineering'
        },
        {
            "courseTitle": "Access to Higher Education Computing",
            "shortDescription": "The Access to Higher Education Diploma (Computing) is your first step towards a university degree and a career in technology. You’ll learn key skills in programming, cybersecurity, and data analysis, preparing you for exciting roles in IT and software development. If you love technology and problem-solving, this course will help you turn your passion into a career.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Computing_chlxw9.jpg",
            'url': '/access-to-computing'
        }, {
            "courseTitle": "Access to Science - Medical And Health Science",
            "shortDescription": "The Access to Higher Education Diploma (Medical and Health Sciences) is your first step toward a university degree and a career in healthcare or medical science. Designed as an alternative to A-Levels, this course is perfect if you’ve been out of education or don’t meet traditional entry requirements. Gain the essential knowledge you need to progress to higher education and unlock exciting career opportunities in the medical and health sectors.",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Health_Medical_Science_gdldyl.jpg",
            'url': '/access-to-medical-health-sciences'
        }, {
            "courseTitle": "Access to Higher Education Social Work",
            "shortDescription": "Take the first step toward a rewarding career in social work! This course equips you with essential knowledge, skills, and confidence to progress into higher education or social care roles. Explore key topics like safeguarding, social justice, and working with diverse communities—your pathway to making a real difference starts here!",
            "courseLevel": "Level 3",
            "funding": "Advance Learner Loan",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1743673398/Courses/Untitled_design_lw9fto.png",
            'url': '/access-to-social-work'
        },
    ]


    if (!posts)
        return <p className="text-center">Cannot find any posts</p>;
    posts.data = posts.data.filter(x => x.status !== "disabled")
    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {posts.data.map((post) => {
                        return (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={post.id} xs={12} md={12}>
                                <div className="card ">
                                    <div className="row">
                                        <div className="col-md-2 hidden-xs">
                                            <img
                                                style={{ objectFit: "contain" }}
                                                className="card-img-top"
                                                src={post.img}
                                                alt="Card image cap"
                                                height="300px"
                                                width="300px"
                                            />
                                        </div>
                                        <div className="col-md-8 col-xs-12">
                                            <div>
                                                <h5 className="card-title">{post.courseTitle}</h5>
                                                <p className="card-text disp ">{post.funding}</p>
                                                <p className="desc pbor">{post.shortDescription}</p>
                                                <p className="card-text  dispp">{post.funding}</p>
                                            </div>
                                        </div>

                                        <div className="col cbutton" style={{ border: "0px" }}>
                                            <Button
                                                className="co-button"
                                                onClick={(e) => {
                                                    window.location.href =
                                                        "/single?id=" + post.courseTitle;
                                                }}
                                            >
                                                View Course
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                    {coursesPosts?.map((post) => {
                        return (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={post.id} xs={12} md={12}>
                                <div className="card ">
                                    <div className="row">
                                        <div className="col-md-2 hidden-xs">
                                            <img
                                                style={{ objectFit: "contain" }}
                                                className="card-img-top"
                                                src={post.img}
                                                alt="Card image cap"
                                                height="300px"
                                                width="300px"
                                            />
                                        </div>
                                        <div className="col-md-8 col-xs-12">
                                            <div>
                                                <h5 className="card-title">{post.courseTitle}</h5>
                                                <p className="card-text disp ">{post.funding}</p>
                                                <p className="desc pbor">{post.shortDescription}</p>
                                                <p className="card-text  dispp">{post.funding}</p>
                                            </div>
                                        </div>

                                        <div className="col cbutton" style={{ border: "0px" }}>
                                            <Button
                                                className="co-button"
                                                onClick={(e) => {
                                                    window.location.href = post.url;
                                                }}
                                            >
                                                View Course
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                    {/* New static course template */}
                    {/* <Grid item xs={12} md={12}>
                        <div className="card ">
                            <div className="row">
                                <div className="col-md-2 hidden-xs">
                                    <img
                                        style={{ objectFit: "contain" }}
                                        className="card-img-top"
                                        src="https://res.cloudinary.com/my-free-course/image/upload/v1715171108/Courses/FREE_Courses_buvv14.jpg"
                                        alt="Card image cap"
                                        height="300px"
                                        width="300px"
                                    />
                                </div>
                                <div className="col-md-8 col-xs-12">
                                    <div>
                                        <h5 className="card-title">Green Skills Employability Program</h5>
                                        <p className="card-text disp ">Funded</p>
                                        <p className="desc pbor">
                                            An employability program available to those in London who are interested in gaining 2 qualifications, getting a guaranteed job interview within the green sector and receiving 1 to 1 employment support.
                                        </p>
                                        <p className="card-text  dispp">Funded</p>
                                    </div>
                                </div>

                                <div className="col cbutton" style={{ border: "0px" }}>
                                    <Button
                                        className="co-button"
                                        onClick={(e) => {
                                            window.location.href =
                                                "/employment-skills-and-climate-change"
                                        }}
                                    >
                                        View Course
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid> */}
                    {/* DEMO DATA ENDS */}


                    {/* New static Contruction course template */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665492418/Courses/Makeup_Artist_cvmycl.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Make-Up Artistry</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Make-up Artistry is to prepare you to enter the specific sector of beauty therapy as a make-up artist. All the units in this qualification relate directly to the specific job role of a make-up artist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/makeup-artistry-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665489863/Courses/Bridal_Hair_yzdryg.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Bridal</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The Level 3 Diploma in Bridal Hair and Make-up will provide you with advanced practical skills and knowledge in bridal hair and make-up.

                        The diploma is aimed at people currently working in the hair and beauty sector who wish to specialise in bridal hair and make-up.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/v1661175867/Courses/Ixion_Beauty_Therapy_rghn9i.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Beauty Therapy Treatment</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Beauty Therapy Treatment is to prepare you to enter the specific sector of beauty therapy as a nail technician. All the units in this qualification relate directly to the specific job role of a beauty therapist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-therapy-general"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* DEMO DATA ENDS */}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Posts;
