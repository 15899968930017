import { useEffect } from "react";

const TrustpilotWidget = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        script.onload = () => {
            if (window.Trustpilot) {
                window.Trustpilot.loadFromElement(document.getElementById("trustpilot-widget") , true);
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            id="trustpilot-widget"
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="5613c9cde69ddc09340c6beb"
            data-businessunit-id="6452c1443652500ac4cf8caf"
            data-style-height="100%"
            data-style-width="100%"
        >
            <a href="https://uk.trustpilot.com/review/myfreecourse.co.uk" target="_blank" rel="noopener">
                Trustpilot
            </a>
        </div>
    );
};

export default TrustpilotWidget;
