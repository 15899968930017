export function convertToDDMMYYYY(dates) {
    let dateStr = dates.slice(0, 10)
    const formats = [
        { format: 'yyyy-mm-dd', regex: /^(\d{4})-(\d{2})-(\d{2})$/ },
        { format: 'dd/mm/yyyy', regex: /^(\d{2})\/(\d{2})\/(\d{4})$/ },
        { format: 'mm-dd-yyyy', regex: /^(\d{2})-(\d{2})-(\d{4})$/ },
        { format: 'mmm dd, yyyy', regex: /^([a-zA-Z]{3}) (\d{1,2}), (\d{4})$/ },
        { format: 'd mmmm yyyy', regex: /^(\d{1,2}) ([a-zA-Z]+) (\d{4})$/ },
    ];

    for (const fmt of formats) {
        const match = dateStr.match(fmt.regex);
        if (match) {
            let day, month, year;

            switch (fmt.format) {
                case 'yyyy-mm-dd':
                    [year, month, day] = match.slice(1);
                    break;
                case 'dd/mm/yyyy':
                    [day, month, year] = match.slice(1);
                    break;
                case 'mm-dd-yyyy':
                    [month, day, year] = match.slice(1);
                    break;
                case 'mmm dd, yyyy':
                    month = new Date(Date.parse(match[1] + ' 1, 2000')).getMonth() + 1;
                    month = String(month).padStart(2, '0');
                    day = match[2].padStart(2, '0');
                    year = match[3];
                    break;
                case 'd mmmm yyyy':
                    month = new Date(Date.parse(match[2] + ' 1, 2000')).getMonth() + 1;
                    month = String(month).padStart(2, '0');
                    day = match[1].padStart(2, '0');
                    year = match[3];
                    break;
                default:
                    return dates;
            }

            return `${day}/${month}/${year}`;
        }
    }

    return dates;
}