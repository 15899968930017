import { Container, Button, Card, Accordion, Row, Col, Badge } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "../landingpage2.css"
import CoursesCards from "../common/coursesCards"
import CookieConsent from "react-cookie-consent"
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import AccessCourses from "./AccessCourses"


export default function AdvanceLearnerAccessPage() {
    function scrollTtoCourse() {
        document.getElementById('courses').scrollIntoView({
            behavior: 'smooth'
        });
    }


    return (
        <div className="landing-page">
            <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay>
                This website uses cookies in order to give you the best possible visitor experience. Please <a href="/terms-conditions">click here</a> to view our <a href="/terms-conditions">privacy & cookies policy</a> and find out more information about how we use cookies and how you can manage them. By continuing to use our website you are consenting to such use of cookies.        </CookieConsent>

            {/* Hero Section */}
            <section className="hero-section py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} className="mb-4 mb-lg-0">
                            <Badge bg="primary" className="mb-3 px-3 py-2">
                                100% Government Funded
                            </Badge>
                            <h1 className="display-5 fw-bold mb-3">Government-Funded Courses for Adults in England</h1>
                            <p className="lead mb-4">
                                My Free Course connects adult learners in eligible areas of England with top UK training providers
                                offering fully funded education opportunities.
                            </p>
                            {/* <a onKeyPress={scrollTtoCourse} onClick={scrollTtoCourse} className=" px-5 py-3 text-white mt-3 mt-sm-0 landing-page-btn" >Start a free course</a> */}

                        </Col>
                        <Col lg={6}>
                            <img
                                src="https://res.cloudinary.com/my-free-course/image/upload/v1681575491/Site-pics/home_01-1-_1__z0ftri.webp"
                                alt="Adult education"
                                className="img-fluid rounded"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Why Choose Us */}
            <section className="why-choose-us py-5">
                <Container>
                    <h2 className="text-center mb-4">Why Choose Us</h2>
                    <p className="text-center mb-5 lead">
                        We make education accessible, flexible, and completely free for eligible learners
                    </p>

                    <Row className="g-4 row-cols-1 row-cols-md-2 row-cols-lg-5">
                        <Col>
                            <Card className="h-100 shadow-md border-1 why-choose-card">
                                <Card.Body style={{ minHeight: "300px" }} className="d-flex flex-column align-items-center text-center p-3">
                                    <div className="bg-tertiary rounded-circle p-3 mb-4 d-flex align-items-center justify-content-center">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                        </svg>
                                    </div>
                                    <Card.Title className="mb-3 fs-5 fw-bold">Seamless Enrolment</Card.Title>
                                    <Card.Text className="text-muted">We simplify finding and joining courses that match your needs</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card className="h-100 shadow-md border-1 why-choose-card">
                                <Card.Body style={{ minHeight: "300px" }} className="d-flex flex-column align-items-center text-center p-3">
                                    <div className="bg-tertiary rounded-circle p-3 mb-4 d-flex align-items-center justify-content-center">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                            <path d="M12 20h9"></path>
                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                    </div>
                                    <Card.Title className="mb-3 fs-5 fw-bold">Quality Education</Card.Title>
                                    <Card.Text className="text-muted">Partnership with leading higher education training providers</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* <Col>
                            <Card className="h-100 shadow-md border-1 why-choose-card">
                                <Card.Body style={{ minHeight: "300px" }} className="d-flex flex-column align-items-center text-center p-3">
                                    <div className="bg-tertiary rounded-circle p-3 mb-4 d-flex align-items-center justify-content-center">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                            <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                            <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                        </svg>
                                    </div>
                                    <Card.Title className="mb-3 fs-5 fw-bold">100% Funded</Card.Title>
                                    <Card.Text className="text-muted">Access courses covered by the Adult Education Budget (AEB)</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col> */}

                        <Col>
                            <Card className="h-100 shadow-md border-1 why-choose-card">
                                <Card.Body style={{ minHeight: "300px" }} className="d-flex flex-column align-items-center text-center p-3">
                                    <div className="bg-tertiary rounded-circle p-3 mb-4 d-flex align-items-center justify-content-center">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                            <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                                            <line x1="8" y1="21" x2="16" y2="21"></line>
                                            <line x1="12" y1="17" x2="12" y2="21"></line>
                                        </svg>
                                    </div>
                                    <Card.Title className="mb-3 fs-5 fw-bold">Flexible Learning</Card.Title>
                                    <Card.Text className="text-muted">Complete courses online at your own pace</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card className="h-100 shadow-md border-1 why-choose-card">
                                <Card.Body style={{ minHeight: "300px" }} className="d-flex flex-column align-items-center text-center p-3">
                                    <div className="bg-tertiary rounded-circle p-3 mb-4 d-flex align-items-center justify-content-center">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                    </div>
                                    <Card.Title className="mb-3 fs-5 fw-bold">Dedicated Support</Card.Title>
                                    <Card.Text className="text-muted">Receive guidance throughout your educational journey</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* <div className="text-center mt-5 cta-box">
                        <p className="lead mb-3">
                            Our college partners use the Education and Skills Funding Agency (ESFA) funds to deliver free online courses across
                            diverse subjects, helping you boost your skills with confidence.
                        </p>
                        <h3 className="mb-3">Ready to advance your career at no cost?</h3>
                        <br />
                        <a onKeyPress={scrollTtoCourse} onClick={scrollTtoCourse} className=" px-5 py-3 text-white mt-3 mt-sm-0 landing-page-btn" >Start a free course</a>

                    </div> */}
                </Container>
            </section>

            {/* Government Funding */}
            {/* <section className="funding-section py-5" id="funding">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} className="mb-4 mb-lg-0">
                            <img
                                src="https://res.cloudinary.com/my-free-course/image/upload/c_fill,w_900,h_600/v1742559561/Site-pics/120116_jcpwjv.jpg"
                                alt="Government funding"
                                className="img-fluid rounded shadow"
                            />
                        </Col>
                        <Col lg={6}>
                            <h2 className="mb-4">Government Funding Explained</h2>
                            <p className="mb-3">
                                The UK Government's Adult Education Budget (AEB) is a funding programme that provides skills and
                                learning opportunities for adults aged 19+. It funds fully or partially subsidised courses for eligible
                                learners, focusing on essential skills, vocational training and qualifications up to Level 3.
                            </p>
                            <p className="mb-4">
                                The AEB aims to help adults enter employment, advance in work or prepare for further education. It's
                                managed by the Education and Skills Funding Agency (ESFA) and delivered through approved training
                                providers, colleges and local authorities across England, with devolved administration in certain
                                regions to address regional skills needs better.
                            </p>
                            <br />
                            <br />
                            <a onKeyPress={scrollTtoCourse} onClick={scrollTtoCourse} className=" px-5 py-3 text-white mt-3 mt-sm-0 landing-page-btn" >Start a free course</a>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* Popular Courses */}
            <section className="courses-section py-5" id="courses-section">
                <Container>
                    <h2 className="text-center mb-3">Popular Government Funded Courses Online</h2>
                    <p className="text-center mb-5">Our courses are accredited by either NCFE or TQUK</p>

                    <div id="courses">
                        <AccessCourses />
                    </div>
                </Container>
            </section>

            {/* FAQs */}
            {/* <section className="faqs-section py-5" id="faqs">
                <Container>
                    <h2 className="text-center mb-3">FAQs: Adult Education & Government Funding in the UK</h2>
                    <p className="text-center mb-5">Find answers to commonly asked questions about our funded courses</p>

                    <Row>
                        <Col lg={10} className="mx-auto">
                            <Accordion defaultActiveKey="0">
                                {[
                                    {
                                        title: "What is the Adult Education Budget (AEB)?",
                                        content:
                                            "The AEB is a government funding stream that supports adults aged 19+ to develop skills and qualifications. It covers courses up to Level 3 and can aid a person in re-entry into work, advancing in their career or preparing for further education.",
                                    },
                                    {
                                        title: "Who is eligible for funded courses?",
                                        content:
                                            "Eligibility typically includes UK/EU residents aged 19+ living in England (with specific regional requirements). Full funding is often available for unemployed individuals, those on low incomes, or people without prior qualifications at certain levels. Eligibility criteria may vary by course and provider.",
                                    },
                                    {
                                        title: "What types of courses are funded?",
                                        content:
                                            "Funded courses typically include essential skills (English, maths, digital), vocational qualifications, sector-specific training, and classes designed to help people enter or progress in employment. Level 3 qualifications are also available through the National Skills Fund for eligible adults.",
                                    },
                                    {
                                        title: "How do I apply for funded training?",
                                        content:
                                            "You can apply directly through approved training providers, colleges, or adult education services. Our service can help match you with appropriate providers and guide you through the application process.",
                                    },
                                    {
                                        title: "Are there any costs involved?",
                                        content:
                                            "Fully funded courses have no tuition fees for eligible learners. Co-funded courses may require a contribution. Additional costs like materials or certification fees may apply depending on the course.",
                                    },
                                    {
                                        title: "Can I study part-time or online?",
                                        content:
                                            "Many AEB-funded courses offer flexible study options, including part-time, evening, weekend, and online learning, to accommodate work and family commitments.",
                                    },
                                    {
                                        title: "What is the difference between AEB and Advanced Learner Loans?",
                                        content:
                                            "AEB funding typically covers courses up to Level 3 for eligible learners without requiring repayment. Advanced Learner Loans are available for courses at Levels 3-6 and must be repaid once you earn above the threshold, similar to student loans.",
                                    },
                                    {
                                        title: "Do I need qualifications to access funded training?",
                                        content:
                                            "Many entry-level courses have no prior qualification requirements. Higher-level courses may require previous qualifications or relevant experience. Initial assessments help determine appropriate course levels.",
                                    },
                                    {
                                        title: "What support is available while studying?",
                                        content:
                                            "Depending on your circumstances, you may access additional support such as childcare funding, travel expenses, learning support for disabilities, or financial assistance for course materials.",
                                    },
                                    {
                                        title: "How will these courses benefit my career?",
                                        content:
                                            "Government-funded courses are designed to address skills gaps and employer needs. They can help you gain recognised qualifications, develop industry-specific skills, and improve your employability and earning potential.",
                                    },
                                ].map((faq, index) => (
                                    <>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey={index.toString()}
                                            className="w-100 text-left px-4 py-3 text-dark text-decoration-none bg-light rounded-0 position-relative"
                                        >
                                            {faq.title}
                                            <span className="position-absolute end-0 me-3">▼</span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            eventKey={index.toString()}
                                            className="border border-top-0 bg-white"
                                        >
                                            <div className="p-4">
                                                {faq.content}
                                            </div>
                                        </Accordion.Collapse>
                                    </>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* Contact */}
            <section className="contact-section py-1 bg-gradient-light">
                <Container>
                    <div className="contact-card rounded-4 shadow-sm p-4 p-lg-5">
                        <Row className="align-items-center">
                            <Col md={7}>
                                <h4 className="fw-bold mb-3 text-dark">
                                    Need Assistance? We're Here to Help
                                </h4>
                                <p className="text-muted mb-0">
                                    Let our expert team guide you to your ideal learning path
                                </p>
                            </Col>

                            <Col md={5} className="mt-3 mt-md-0">
                                <div style={{ alignItems: "center", gap: "5px" }} className="d-flex flex-column flex-md-row gap-3 justify-content-md-end">
                                    <a style={{ width: "180px", gap: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} href="tel:08081889161" className="btn btn-primary px-2 py-2 fw-medium">
                                        <FaPhoneAlt /><span>0808 188 9161</span>
                                    </a>
                                    <a style={{ width: "150px", gap: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} href="mailto:support@myfreecourse.co.uk" className="btn btn-outline-primary px-4 py-2 fw-medium">
                                        <FaEnvelope /><span>Email Us</span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

        </div>
    )
}

