import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import auth from './services/authService'
import NavBar from './components/header'
import PrivacyPolicy from './components/PrivacyPolicy'
import Conditions from './components/TermsConditions'
import faqs from './components/faqs'
import LoginForm from './components/login'
import Logout from './components/logout'
import SignUp from './components/signup'
import LandingPage from './components/landingPage'
import FreeCoursesPage from './components/freeCourses'
import Courses from './components/Courses'
import AdminPanel from './components/adminLogin'
import AdminLandingPage from './components/AdminMainPage'
import getStudentData from './common/getStudentData'
import StudentsData from './components/studentsList'
import Details from './common/details'
import Colleges from './components/colleges'
import IncompleteForms from './components/incompleteForms'
import LeadsList from './components/leadsList'
import TwinsLeadsList from './components/leadsTwinslist'
import ProtectedRoute from './components/ProtectedRoute'
import UpdateCollegeInfo from './components/editCollegeInfo'
import Proof from './components/Form/Proof'
import DeclarationForm from './components/Form/Declaration'
import EqualOppurtunities from './components/Form/EqualOppurtunities'
import QualificationForm from './components/Form/QualificationForm'
import EmployemntForm from './components/Form/EmplyementForm'
import PersonalDetails from './components/Form/PersonalDetails'
import DetailsForm from './components/Form/DetailForm'
import EmergencyForm from './components/Form/EmergencyDetails'
import Success from './components/Form/Success'
import thankYou from './components/thankYou'
import SendEmail from './components/SendEmail'
import AddCourse from './components/addCourse'
import LeadsForm from './components/leadsForm'
import UpdatePersonalDetails from './components/Update Form/UpdatePersonalDetails'
import UpdateEmergencyForm from './components/Update Form/UpdateEmergencyDetails'
import UpdateQualification from './components/Update Form/UpdateQualificationForm'
import UpdateEmployment from './components/Update Form/UpdateEmplyementForm'
import UpdateEqualOpp from './components/Update Form/UpdateEqualOppurtunities'
import UpdateDeclaration from './components/Update Form/UpdateDeclaration'
import CollegePanel from './components/college/CollegePanel'
import CallCenterPanel from './components/callcenter/CallCenterPanel'
import CollegeLandingPage from './components/college/CollegeLandingPage'
import EditPersonalDetails from './components/EditForm/personalDetails'
import EditEmergencyDetails from './components/EditForm/emergencydetails'
import EditEmploymentDetails from './components/EditForm/employmentDetails'
import EditQualificationDetails from './components/EditForm/qualificationDetails'
import EditOppurtunitiesDetails from './components/EditForm/oppurtunitiesDetails'
import EditDeclarationDetails from './components/EditForm/declarationDetails'
import EditProofDetails from './components/EditForm/proofDetails'
import EmailProofDetails from './components/EmailForm/proofDetails'
import TeacherTraining from './components/teacher-training'
import NailTechnology from './components/nailTechnology'
import EnglishTestSingleQuestion from './components/entryTest/englishTest0'
import EnglishTestLong from './components/entryTest/englishTestLong'
import CallCenterFroms from './components/callcenter/CallCenterForms'
import CallCenterAllFormsList from './components/callcenter/CallCenterAllFormsList'
import CallCenterProofForm from './components/EmailForm/callCenterProofForm'
import BeautyTherapyGeneral from './components/beautyTherapyGeneral'
import LeadsTwins from './components/leadsTwins'

import './App.css'
import AllStudents from './components/allStudents'
import ConstructionCourse from './components/CounstructionCourse'
import CallCenterList from './components/callCenterList'
import AddCallCenterStaffForm from './components/addCallCenter'
import FormsCallCenter from './components/formsCallCenter'
import SupportStaffList from './components/supportStaffList'
import AddSupportStaffForm from './components/addSupportStaff'
import SupportLoginPanel from './components/SupportLoginPanel'
import bridalCertifical from './components/bridalCertifical'
import makeupArtistry from './components/makeupArtistry'
import DeletedCompletedForms from './components/trash/deletedForms'
import DeletedInCompleteForms from './components/trash/deletedIncompleteForms'
import CCLandingPage from './components/callcenter/dashboard'
import NewSwindonDec from './components/declaration/newSwindon'
import NewSwindonPolicy from './components/newSwindonPolicy'
import Step1FailedForms from './components/failedStep1'
import AddNewCollege from './components/addCollege'
import CourseManage from './components/coursesManage'
import EditCourse from './components/editCourseInfo'
import Contracts from './components/contracts'
import AddNewContract from './components/addContract'
import EditContract from './components/editContract'
import AddAttachmentLinkForm from './components/attachmentLink/addLink'
import AllAttachmentLinks from './components/attachmentLink/allLinks'
import AttachmentLinkForUser from './components/attachmentLink/attachmentLinkUser'
import ApprovedForms from './components/approvedForms'
import DatabaseIncompleteForms from './components/databaseIncomplete'
import Footer from './components/footer'
import NCGPolicy from './components/NCGpolicy'
import CollegeCompleteSignup from './components/college-complete-signup'
import MakeNewContract from './components/makeNewContract'
import CollegeConditions from './components/collegeTermsContent'
import FTForm from './components/forward-trust-leads-form'
import FTCollegeCourse from './common/FTCollegeCourse'
import FTFormThankYou from './components/forward-trust-thankyou'
import YorkShirePolicy from './components/YorkShirePolicy'
import AccessInEducation from './components/access-in-education'
import AccessInHealth from './components/access-in-health'
import AccessInHealthScience from './components/access-in-health-sciences-professions'
import AccessInPsychology from './components/access-in-psychology'
import FunctionalSkillsMathsEnglish from './components/functional-skills-math-english'
import AccessToMidwifery from './components/access-to-midwifery'
import AccessToNursing from './components/access-to-nursing'
import AccessToParamedicScience from './components/access-to-paramedic-science'
import AccessInBiomedicalScience from './components/access-in-biomedical-science';
import AccessToEngineering from './components/access-to-higher-engineering';
import AccessToComputing from './components/access-in-computing';
import AccessToMedicalHealthSciences from './components/access-to-medical-health-sciences';
import AdvanceLearnerAccessPage from './components/advance-learner-courses';
import AccessToSocialWork from './components/access-to-social-work';


const GA4_ID = 'G-4S960HB9GD';


class App extends React.Component {
    state = {}

    componentDidMount() {
        const user = auth.getCurrentUser()
        this.setState({ user })
        ReactGA.initialize(GA4_ID);
        ReactGA.send('pageview');
    }
    constructor(props) {
        super(props)
        this.state = { apiResponse: '' }
    }

    callAPI() {
        fetch('https://backend.myfreecourse.co.uk')
            .then((res) => console.log('App connected'))
            .then((res) => this.setState({ apiResponse: res }))
    }

    componentWillMount() {
        this.callAPI()
    }
    

    render() {
        const { user } = this.state


        return (
            <React.Fragment>
                <ToastContainer />
                <NavBar user={user} />
                <main className=''>
                    <Switch>
                        <Route path='/home' component={LandingPage} />
                        <Route path='/funded-courses' component={FreeCoursesPage} />
                        <Route path='/access-courses' component={AdvanceLearnerAccessPage} />
                        <Route path='/privacy' component={PrivacyPolicy} />
                        <Route path='/NewSwindon-Policy' component={NewSwindonPolicy} />
                        <Route path='/NCG-Policy' component={NCGPolicy} />
                        <Route path='/yorkshire-Policy' component={YorkShirePolicy} />
                        <Route path='/terms-conditions' component={Conditions} />
                        <Route path='/college-terms-conditions' component={CollegeConditions} />
                        <Route path='/faqs' component={faqs} />

                        <Route path='/login' component={LoginForm} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/register' component={SignUp} />

                        <Route path='/courses' component={Courses} />
                        <Route path='/single' component={Details} />
                        <Route path='/employment-skills-and-climate-change' component={FTCollegeCourse} />
                        {/* <ProtectedRoute path='/edit' exact component={UpdateCollegeInfo} /> */}
                        {/* <ProtectedRoute path='/edit-course' exact component={EditCourse} /> */}
                        {/* <ProtectedRoute path='/edit-contract' exact component={EditContract} /> */}
                        {/* <ProtectedRoute path='/add-college' component={AddNewCollege}/> */}
                        {/* <ProtectedRoute path='/add-contract' component={AddNewContract}/> */}
                        <ProtectedRoute path='/add-attachment-link' component={AddAttachmentLinkForm} />

                        {/* <Route path='/admin' component={AdminPanel} /> */}
                        <Route path='/college' exact component={CollegePanel} />
                        {/* <Route path='/callcenter' exact component={CallCenterPanel} /> */}
                        {/* <Route path='/support-login' exact component={SupportLoginPanel} /> */}
                        <ProtectedRoute path='/incompleteForms' component={IncompleteForms} />
                        {/* <ProtectedRoute path='/database-incompleteForms' component={DatabaseIncompleteForms} /> */}
                        <ProtectedRoute path='/approvedForms' component={ApprovedForms} />
                        <ProtectedRoute path='/adminhome' component={AdminLandingPage} />
                        <ProtectedRoute path='/collegehome' component={CollegeLandingPage} />
                        <ProtectedRoute path='/incompleteForms-cc' component={FormsCallCenter} />
                        <ProtectedRoute path='/callcenterhome' component={CCLandingPage} />
                        <ProtectedRoute path='/all-attachment-links' component={AllAttachmentLinks} />


                        <ProtectedRoute path='/college-complete-signup' component={CollegeCompleteSignup} />
                        <ProtectedRoute path='/new-contract' component={MakeNewContract} />

                        {/* <ProtectedRoute path='/colleges' component={Colleges} />
						<ProtectedRoute path='/contracts' component={Contracts} /> */}
                        <ProtectedRoute path='/all-courses' component={CourseManage} />
                        <ProtectedRoute path='/studentslist' component={StudentsData} />
                        <ProtectedRoute path='/trash' component={DeletedCompletedForms} />
                        <ProtectedRoute path='/failedStep1' component={Step1FailedForms} />
                        <ProtectedRoute path='/incomplete-trash' component={DeletedInCompleteForms} />
                        {/* <ProtectedRoute path='/allStudents' component={AllStudents} /> */}
                        <ProtectedRoute path='/show-data' component={getStudentData} />
                        <ProtectedRoute path='/email/:email' component={SendEmail} />
                        <ProtectedRoute path='/addcourse' component={AddCourse} />
                        <ProtectedRoute path='/leadsList' component={LeadsList} />
                        <ProtectedRoute path='/twinleadsList' component={TwinsLeadsList} />

                        {/* <ProtectedRoute path='/update/qualification' component={UpdateQualification} />
                        <ProtectedRoute path='/update/employment' component={UpdateEmployment} />
                        <ProtectedRoute path='/update/personal' component={UpdatePersonalDetails} />
                        <ProtectedRoute path='/update/emergencydetails' component={UpdateEmergencyForm} />
                        <ProtectedRoute path='/update/oppurtunities' component={UpdateEqualOpp} />
                        <ProtectedRoute path='/update/declaration' component={UpdateDeclaration} /> */}

                        {/* <ProtectedRoute path='/edit/personal' exact component={EditPersonalDetails} />
                        <ProtectedRoute path='/edit/emergencydetails' exact component={EditEmergencyDetails} />
                        <ProtectedRoute path='/edit/employment' exact component={EditEmploymentDetails} />
                        <ProtectedRoute path='/edit/qualification' exact component={EditQualificationDetails} />
                        <ProtectedRoute path='/edit/oppurtunities' exact component={EditOppurtunitiesDetails} />
                        <ProtectedRoute path='/edit/declaration' exact component={EditDeclarationDetails} />
                        <ProtectedRoute path='/edit/proof' exact component={EditProofDetails} /> */}
                        <ProtectedRoute path="/add-form-callcenter" component={CallCenterFroms} />
                        <ProtectedRoute path="/all-forms-callcenter" component={CallCenterAllFormsList} />
                        <ProtectedRoute path="/all-callcenter-staff" component={CallCenterList} />
                        <ProtectedRoute path="/add-callcenter-staff" component={AddCallCenterStaffForm} />
                        <ProtectedRoute path="/all-support-staff" component={SupportStaffList} />
                        <ProtectedRoute path="/add-support-staff" component={AddSupportStaffForm} />

                        <Redirect from='/' exact to='/home' />
                    </Switch>
                    <Route path='/proof' component={Proof} />
                    <Route path='/declaration' component={DeclarationForm} />
                    <Route path='/oppurtunities' component={EqualOppurtunities} />
                    <Route path='/success' component={Success} />
                    <Route path='/thank-you' component={thankYou} />
                    <Route path='/qualification' component={QualificationForm} />
                    <Route path='/employment' component={EmployemntForm} />
                    <Route path='/personal' component={PersonalDetails} />
                    <Route path='/emergencydetails' component={EmergencyForm} />

                    <Route path='/form/:course?' component={DetailsForm} />
                    {/* <Route path='/leadsForm' component={LeadsForm} /> */}
                    <Route path='/ft-leads' component={FTForm} />
                    <Route path='/ft-thankyou' component={FTFormThankYou} />

                    <Route path='/leadsTwin/:course' component={LeadsTwins} />

                    <Route path='/email_completion_step_8' exact component={EmailProofDetails} />
                    <Route path='/email_completion_attachment' exact component={CallCenterProofForm} />

                    <Route path="/teacher-training" component={TeacherTraining} />
                    <Route path="/access-in-education" component={AccessInEducation} />
                    <Route path="/access-in-health" component={AccessInHealth} />
                    <Route path="/access-in-health-science" component={AccessInHealthScience} />
                    <Route path="/access-in-psychology" component={AccessInPsychology} />
                    <Route path="/access-to-midwifery" component={AccessToMidwifery} />
                    <Route path="/access-to-paramedic-science" component={AccessToParamedicScience} />
                    <Route path="/access-to-nursing" component={AccessToNursing} />
                    <Route path="/access-to-biomedical-science" component={AccessInBiomedicalScience} />
                    <Route path="/access-to-engineering" component={AccessToEngineering} />
                    <Route path="/access-to-computing" component={AccessToComputing} />
                    <Route path="/access-to-medical-health-sciences" component={AccessToMedicalHealthSciences} />
                    <Route path="/access-to-social-work" component={AccessToSocialWork} />
                    
                    <Route path="/functional-skills-maths-english" component={FunctionalSkillsMathsEnglish} />

                    <Route path="/nail-technology" component={NailTechnology} />
                    <Route path="/beauty-therapy-general" component={BeautyTherapyGeneral} />
                    <Route path="/beauty-certificate" component={bridalCertifical} />
                    <Route path="/makeup-artistry-certificate" component={makeupArtistry} />
                    <Route path="/contruction-course" component={ConstructionCourse} />

                    <Route path="/english-assessments/rnn" component={EnglishTestSingleQuestion} />
                    <Route path="/english-assessment" component={EnglishTestLong} />

                    <Route path="/attachments-single" component={AttachmentLinkForUser} />
                    <Route path="/nsc-declaration/review" component={NewSwindonDec} />
                </main>
                <Footer />
            </React.Fragment>
        )
    }
}
export default App
