import React, { useEffect, useState } from "react";

import posts from "../common/posts";
import postLoading from "../common/postLoading";

const AccessCourses = () => {
    const PostLoading = postLoading(posts);
    const [appState, setAppState] = useState({
        loading: false,
        posts: null,
    });

    useEffect(() => {
        // setAppState({ loading: true });
        // const apiURL = process.env.REACT_APP_API_URL + "/course/";

        // fetch(apiURL)
        //     .then((data) => data.json())
        //     .then((posts) => {
        //         console.log(posts)
        //         setAppState({ loading: false, posts: posts });
        //     });
    }, [setAppState]);
    return (
        <div>
            <PostLoading isLoading={appState.loading} posts={{ data: [] }} />
        </div>
    );
};
export default AccessCourses;
