import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from './FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import { createForm, saveSignDetails } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import SignaturePad from 'react-signature-canvas'
import { toast } from 'react-toastify'
import { useRef } from 'react'
import { CButton } from '@coreui/react'
import { trackStep8Proof } from '../../common/analyticsGA4'

const Proof = ({ history }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const myRef = useRef(null)
    const dispatch = useDispatch()

    const form = useSelector((state) => state.form)

    const { personalDetails, sign } = form
    const [idPic, setID] = useState()
    const [idPic1, setID1] = useState()

    const [signature, setTrimmed] = useState()

    const formCreate = useSelector((state) => state.final)
    const { final, success, error } = formCreate

    const [sigPad, setSigPad] = useState({})
    const [msg, setMsg] = useState()
    const [groupArray, setGroupArray] = useState([])
    const [typeOfProof, setTypeOfProof] = useState()
    const [validated, setValidated] = useState(false)
    const [sendButton, setSendButton] = useState(false)
    const clearSig = () => {
        sigPad.clear()
        setMsg(false)
    }
    const uploadImage = (file) => {
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'evidence')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log('URL FIles', res.url)
                return res.url
            })
    }
    // const executeScroll = () => myRef.current.scrollIntoView()   
    const trim = () => {
        console.log("function called")
        const response = sigPad.getTrimmedCanvas().toDataURL('image/png')
        setTrimmed(response)
        dispatch(saveSignDetails(response))
        setMsg(true)
        // executeScroll();
    }

    useEffect(() => {
        if (success) {
            const urlParams = new URLSearchParams(window.location.search)
            const redirect = urlParams.get('redirect')
            if (redirect) {
                history.push('/incompleteForms')
            }
            trackStep8Proof()
            history.push('/success')
        }
    }, [history, success])


    function scrollTtoView() {
        document.getElementById('title-assessment').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const proofList = localStorage.getItem('proofs')?.split(',') || ["Passport", "Birth certificate", "Drivers licence", "Utility Bill", "Bank statement"]
    const submitHandler = (e) => {
        setSendButton(true)
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            scrollTtoView();
            e.preventDefault()
            e.stopPropagation()
            setSendButton(false)
        } else {
            e.preventDefault()
            const urlParams = new URLSearchParams(window.location.search)
            const redirect = urlParams.get('redirect')
            document.getElementById('spinner-finalstep').style.display = 'block'
            window.stop()
            e.preventDefault()
            if (!signature) {
                document.getElementById('spinner-finalstep').style.display = 'none'
                toast.error('Please submit signature')
                setSendButton(false)
                return
            }
            const data = new FormData()
            data.append('nationalInsNo', personalDetails.nationalInsNo)
            dispatch(saveSignDetails(signature))
            let images = [idPic, idPic1]
            let promises = []
            images.forEach((image) => promises.push(uploadImage(image)))
            Promise.all([...promises])
                .then((values) => {
                    let response = createForm(values[0] || '', values[1] || '', groupArray, typeOfProof)
                    const urlParams = new URLSearchParams(window.location.search)
                    const redirect = urlParams.get('redirect')
                    response.then((a) => {
                        if (a.success) {
                            trackStep8Proof()
                            setSendButton(false)
                            history.push('/success')
                        }
                        else {
                            setSendButton(false)
                            toast("Your application already exists.")
                        }
                    })
                })
                .catch((err) => {
                    setSendButton(false)
                    console.log(err)
                })
        }
        setValidated(true)
    }



    const handleGroup = (id) => {
        if (groupArray.includes(id)) {
            let temp = groupArray.filter(function (item) {
                return item !== id
            })
            setGroupArray(temp)
        } else {
            let temp = [...groupArray]
            temp.push(id)
            setGroupArray(temp)
        }
    }
    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    return (
        <>
            <div className='form-jumbotron' >
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <center>
                <div className='container only-on-mobile'>
                    <img
                        src='https://res.cloudinary.com/my-free-course/image/upload/v1641932783/Site-pics/personal_page_images_nocdmn.png'
                        alt=''
                    />
                </div>
            </center>
            <FormContainer>
                {/* <ScrollToMount /> */}
                <FormCompletetionSteps step1 step2 step3 step4 step5 step6 step7 step8 />

                <h1>
                    <b>Final Step: ID Proof</b>
                </h1>
                <Form.Label className='form-notice'>
                    The courses provided are government funded.<br /><br />
                    As a condition of claiming the funding on your behalf, the college is required to carry out an ID check.<br /><br />
                    This is to make sure the course funding is going to a genuine UK or EU citizen.<br /><br />
                    <b>Acceptable ID proofs are: Passport, Birth certificates, Full or provisional drivers licence.</b>
                    <p className='p-light'>
                    </p>
                </Form.Label>
                <img className="only-on-mobile" src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" style={{ width: "100%" }} />
                <br /><br />
                <Form noValidate validated={validated} onSubmit={submitHandler}>

                    <Form.Group >
                        <Form.Label>
                            <span style={{ fontSize: '22px' }}>
                                <b>1.</b>
                            </span>{' '}
                            <b>Please upload a photo of one valid ID Proof</b>
                            <br />This can be a Passport, Full or provisional drivers licence, Birth certificate.
                            {/*  or the top of a Utility bill(clearly showing your name, address, date of issue and the provider) or Bank statement (clearly showing your name, address and the provider) */}
                        </Form.Label>
                        <Form.Control
                            as='select'
                            id="title-assessment"
                            required
                            value={typeOfProof}
                            onChange={(e) => setTypeOfProof(e.target.value)}
                        >
                            <option selected disabled value=''>
                                Type of Proof
                            </option>
                            {proofList.map(x => <option value={x}>{x}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group id="file-upload-laststep">
                        <Form.Control
                            type='file'
                            required
                            placeholder='Enter address'
                            accept='image/*,application/pdf'
                            onChange={(e) => {
                                setID(e.target.files[0])
                            }}
                        ></Form.Control>
                        <Form.Text className='text-muted'>
                            Required to confirm your identity as part of Adult Skills Funding Guidelines and College requirements
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {(localStorage.getItem('selectedContract') === "67d020a4ab66b423a6fb9a47") && (<>
                        <p>
                            <span style={{ fontSize: '22px' }}>
                                <b>1.1.</b>{' '}
                            </span>
                            <b> Requires Proof of Address</b>
                            <br />
                            Proof of Address accepted: Utility Bill (gas, water, electricity, telephone, internet etc); Bank Statement/Credit Card Statement; Mortgage Statement; Lease Agreement; Insurance Policy; Payslip; Letter of Employment; P45/P60; Universal Credit/Other benefit Claim.
                        </p>
                        <Form.Group className='file-upload-box' id="adderss-proof">
                            <Form.Control
                                type='file'
                                required
                                accept='image/*'
                                onChange={(e) => {
                                    setID1(e.target.files[0])
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </>)}


                    {/* Contact Time Schedule */}
                    <Form.Label >							<span style={{ fontSize: '22px' }}>
                        <b>2.</b>
                    </span>{' '}<b>Preferred Contact Times</b></Form.Label>
                    <p>Please confirm the best time for the course enrolment team to contact you, you can select multiple times.</p>
                    <div className="multi-select-time" id="multi-select-contact">
                        {days.map(day => <>
                            <ul>
                                <li className='contact-title-day'>
                                    <b>{day}</b>
                                </li>
                                <li>
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 8am to 10am") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 8am to 10am")
                                        }}
                                    >
                                        8am to 10am
                                    </CButton>
                                </li>
                                <li >
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 10am to 12pm") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 10am to 12pm")
                                        }}
                                    >
                                        10am to 12pm
                                    </CButton>
                                </li>
                                <li >
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 12pm to 2pm") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 12pm to 2pm")
                                        }}
                                    >
                                        12pm to 2pm
                                    </CButton>
                                </li>
                                <li>
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 2pm to 4pm") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 2pm to 4pm")
                                        }}
                                    >
                                        2pm to 4pm
                                    </CButton>
                                </li>
                                <li>
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 4pm to 6pm") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 4pm to 6pm")
                                        }}
                                    >
                                        4pm to 6pm
                                    </CButton>

                                </li>
                                <li>
                                    <CButton
                                        color={
                                            groupArray.includes(day + ": 6pm to 8pm") ? 'success' : 'primary'
                                        }
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            handleGroup(day + ": 6pm to 8pm")
                                        }}
                                    >
                                        6pm to 8pm
                                    </CButton>
                                </li>
                            </ul>
                        </>)}



                    </div>

                    <hr />
                    <br />
                    {/* Signature */}
                    <Form.Group>
                        <Form.Label as='legend'>							<span style={{ fontSize: '22px' }}>
                            <b>3.</b>
                        </span>{' '}<b> Please Sign The Application</b></Form.Label>
                        <SignaturePad
                            id='canvas'
                            required
                            penColor='black'
                            className='mr-6'
                            onEnd={trim}
                            ref={(ref) => {
                                setSigPad(ref)
                            }}
                            canvasProps={{
                                width: 500,
                                height: 200,
                                className: 'sigCanvas',
                            }}
                        />
                        <br />
                        {/* <Button  className='mr-5 next-Button-form' >
						Sign {' '}
					</Button> */}
                        <Button className='back-Button-form' onClick={clearSig}>
                            Clear Signature
                        </Button>
                        <br />


                        <Form.Control.Feedback type='invalid'>
                            Please fill in this required field.
                        </Form.Control.Feedback>
                        <br />
                        {msg && <p><b>Your signature has been accepted. Please click the ‘Send Application’ button below.</b></p>}
                        <br />
                    </Form.Group>
                    <Form.Group controlId='CHeckbox1'>
                        <Form.Check
                            type='checkbox'
                            label='By ticking this, you confirm that you are happy to submit your course application. Once your application is checked and approved you will be contacted by the college to complete your enrolment. Once enrolled you will be required to start your course.'
                            name='decl'
                            required
                            feedback='You must agree before submitting.'
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>
                    <br />
                    <br />
                    <p>By providing your details you agree to being contacted and your data being processed under the terms of our <a href="/terms-conditions">Privacy Policy</a>.</p>

                    <Button
                        id="submittingForm"
                        onClick={(e) => {
                            const urlParams = new URLSearchParams(window.location.search)
                            const redirect = urlParams.get('redirect')
                            if (redirect) {
                                window.location.href = '/update/declaration'
                            } else {
                                window.location.href = '/declaration'
                            }
                        }}
                        variant='primary'
                        className='mr-5 back-Button-form'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        ref={myRef}
                        disabled={sendButton}
                        type='submit'
                        className='next-Button-form'
                        style={{
                            width: '300px',
                            color: 'white',
                        }}
                    >
                        {' '}
                        Send Application
                    </Button>
                </Form>
            </FormContainer>

            <div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
                <center>
                    <Spinner animation='border' />
                </center>
            </div>
        </>
    )
}

export default Proof
