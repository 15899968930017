import axios from 'axios'
//import logger from '../services/logService'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.timeout = 1000000

axios.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response && error.response.status >= 400 && error.response.status < 500

    if (!expectedError) {
        //logger.log();
        console.log(error)
        toast.error(error.message)
    }
    return Promise.reject(error)
})

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
}
