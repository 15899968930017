import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToMount from '../../common/ScrollToMount'
import FormContainer from './FormContainer'
import { saveEmergencyDetails } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import { trackStep3Emergency } from '../../common/analyticsGA4'
const EmergencyForm = ({ history }) => {
	const form = useSelector((state) => state.form)

	const { emergencyDetails } = form

	const [emergencyContactName, setEmerCon] = useState(emergencyDetails.emergencyContactName)
	const [emergencyTelephone, setEmerTel] = useState(emergencyDetails.emergencyTelephone)
	const [relationshipToLearner, setRelLearn] = useState(emergencyDetails.relationshipToLearner)
	const [validated, setValidated] = useState(false)

	const dispatch = useDispatch()

	const submitHandler = (e) => {
		const form = e.currentTarget
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
			var element = document.getElementById('Error-msg-form')
			element.classList.remove('Error-msg-form-displayNone')
			element.classList.add('Error-msg-form-display')
			document.getElementById('Error-msg-form').scrollIntoView({
				behavior: 'smooth',
			})
		} else {
			e.preventDefault()
			dispatch(
				saveEmergencyDetails({
					emergencyContactName,
					emergencyTelephone,
					relationshipToLearner,
				})
			)
            trackStep3Emergency()
			history.push('/employment')
		}
		setValidated(true)
	}

	return (
		<>
			<div className='form-jumbotron'>
				<h3>LEARNER APPLICATION FORM</h3>
			</div>
			<FormContainer>
				<ScrollToMount />
				<FormCompletetionSteps step1 step2 step3 />

				<h1>Step 3: Emergency Details</h1>
				<Form.Label
					// as="legend"
					className='text-center mt-5 mb-3 font form-notice'
				>
					Providing an emergency contact is a requirement to gain access to funding. This
					information will not be shared other than in the rare case of an emergency.
					<br /> <br /> For those doing their course online this is highly unlikely to
					happen but is still a requirement by the funding body.
				</Form.Label>
				<div
					className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
					id='Error-msg-form'
				>
					<p>Please fix one or more fields.</p>
				</div>

				<Form noValidate validated={validated} onSubmit={submitHandler}>
					<Form.Group controlId='emergencyContactName'>
						<Form.Label>Emergency Contact Name</Form.Label>
						<Form.Control
							type='text'
							required
							value={emergencyContactName}
							onChange={(e) => setEmerCon(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='relationshipToLearner'>
						<Form.Label>Relation to Learner</Form.Label>
						<Form.Control
							type='text'
							required
							value={relationshipToLearner}
							onChange={(e) => setRelLearn(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='emergencyTelephone'>
						<Form.Label>Telephone</Form.Label>
						<Form.Control
							type='number'
							required
							value={emergencyTelephone}
							onChange={(e) => setEmerTel(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Button
						onClick={(e) => {
							window.location.href = '/personal'
						}}
						variant='primary'
						className='mr-5 back-Button-form'
					>
						Back
					</Button>

					<Button
						type='submit'
						variant='primary'
						style={{
							backgroundColor: 'green',
							borderColor: 'green',
							color: 'white',
						}}
						className='mrt next-Button-form'
					>
						Save Progress & Continue
					</Button>
				</Form>
			</FormContainer>
		</>
	)
}

export default EmergencyForm
