import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToMount from '../../common/ScrollToMount'

import FormContainer from './FormContainer'
import { saveEmploymentDetails, saveIdProof } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import { toast } from 'react-toastify'
import { trackStep4Employment } from '../../common/analyticsGA4'
const EmployemntForm = ({ history }) => {
    const form = useSelector((state) => state.form)
    const { employmentDetails, personalDetails, detailsFormData } = form
    const [employementStatus, setEmpStatus] = useState(employmentDetails.employementStatus)
    const [hoursPerWeek, setHours] = useState(employmentDetails.hoursPerWeek)
    const [employmentType, setEmploymentType] = useState(employmentDetails.employmentType)
    const [annualSalary, setAnnualSalary] = useState(employmentDetails.annualSalary)
    const [length, setLength] = useState(employmentDetails.length)
    const [employerName, setName] = useState(employmentDetails.employerName)
    const [employerAdd, setAdd] = useState(employmentDetails.employerAdd)
    const [startDateEmployment, setStartDateEmployment] = useState(employmentDetails.startDateEmployment)
    const [postcode, setPostcode] = useState(employmentDetails.postcode)
    const [ph, setPh] = useState(employmentDetails.ph)
    const [unemployedLength, setUnEmpLength] = useState(employmentDetails.unemployedLength)
    const [lookingForWork, setLookingForWork] = useState(employmentDetails.lookingForWork)
    const [dValue1, setValue1] = useState('')
    const [dValue2, setValue2] = useState('')
    const [dValue3, setValue3] = useState('')
    const [dValue5, setValue5] = useState('')
    const [dValue6, setValue6] = useState('')
    const [dValue7, setValue7] = useState('')
    const [dValue8, setValue8] = useState('')
    const [dValue9, setValue9] = useState('')
    const [dValue10, setValue10] = useState('')
    const [dValue11, setValue11] = useState('')
    const [pipBenifits, setPipBenifits] = useState(false)
    const [areYou, setAreYou] = useState()
    const [receiptBenifits, setReceiptBenifits] = useState('')
    const [evidence, setEvidence] = useState(null)
    const [evidence1, setEvidence1] = useState(null)
    const [evidence2, setEvidence2] = useState(null)

    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [validated, setValidated] = useState(false)

    const dispatch = useDispatch()
    const uploadImage = (file) => {
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'evidence')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log('URL FIles', res.url)
                return res.url
            })
    }

    function isImage(file) {
        return file && file['type'].split('/')[0] === 'image'
    }


    const courseCheck = () => {
        console.log(detailsFormData.appliedCourse)
        if (detailsFormData.appliedCourse === "Preparing to work in Adult Social Care level 3" || detailsFormData.appliedCourse === "Level 3 ESOL Teacher Training") {
            console.log("the level 3 course")
            if (employementStatus === 'Greater London Self Employed but on less than £21,547' || employementStatus === 'Greater London Employed but on less than £21,547') {
                console.log("INside London")
                return true
            }
            else if (employementStatus === 'Unemployed, looking for work' && receiptBenifits === "Yes") {
                console.log("unemployed and receiving benifits while in london")
                return true
            }
            else {
                return false
            }
        }
        else return true;

    }
    const employmentdetailsNecessary = (localStorage.getItem('employmentdetailsNecessary') === 'true')
    const startDateEmploymentNecessary = (localStorage.getItem('startDateEmployment') === 'true')
    const ifEmploymentType = (localStorage.getItem('ifEmploymentType') === 'true')
    const ifAnualSalary = (localStorage.getItem('ifAnualSalary') === 'true')

    const submitHandler = (e) => {
        const form = e.currentTarget
        console.log("annualSalary ", annualSalary)
        if (annualSalary <= 100) {
            e.preventDefault()
            e.stopPropagation()
            toast.error("Please put in the actual value for annual Salary. Its required for the freecourse")
        } else if (!courseCheck()) {
            e.preventDefault()
            e.stopPropagation()
            console.log("Not fullfilled requirements")
            alert("To qualify you must live in London and either be unemployed and on benefits or in paid employment earning less than £21,547.\n\nYou must have at least a level 2 qualification to qualify");
            history.push('/employment')
        }
        else {
            console.log("other course")
            if (form.checkValidity() === false) {
                console.log("Validity check failed")
                e.preventDefault()
                e.stopPropagation()
                var element = document.getElementById('Error-msg-form')
                element.classList.remove('Error-msg-form-displayNone')
                element.classList.add('Error-msg-form-display')
                document.getElementById('Error-msg-form').scrollIntoView({
                    behavior: 'smooth',
                })
            } else {
                e.preventDefault()
                console.log("Unemployed")
                if (employementStatus === 'Unemployed, looking for work') {
                    let images = [evidence]
                    let promises = []
                    images.forEach((image) => promises.push(uploadImage(image)))
                    Promise.all([...promises])
                        .then((values) => {
                            console.log('Values', values)
                            dispatch(
                                saveEmploymentDetails({
                                    employementStatus,
                                    unemployedLength,
                                    lookingForWork,
                                    dValue1,
                                    dValue2,
                                    dValue3,
                                    dValue5,
                                    dValue6,
                                    dValue7,
                                    dValue8,
                                    dValue9,
                                    dValue10,
                                    dValue11,
                                    areYou,
                                    evidence: values[0] || '',
                                    pipBenifits: pipBenifits
                                })
                            )
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    trackStep4Employment()
                    history.push('/qualification')
                } else {

                    console.log("Employment status check")
                    if (
                        employementStatus === 'Employed but on less than £17,374.50 per year' ||
                        employementStatus === 'Self Employed but on less than £17,374.50' ||
                        employementStatus === 'Greater London Self Employed but on less than £21,547' ||
                        employementStatus === 'Greater London Employed but on less than £21,547'
                    ) {
                        console.log("Less than case")
                        const data = new FormData()
                        let images = [evidence, evidence1, evidence2]
                        let promises = []
                        images.forEach((image) => promises.push(uploadImage(image)))
                        Promise.all([...promises])
                            .then((values) => {
                                console.log('Values', values)
                                dispatch(
                                    saveEmploymentDetails({
                                        employementStatus,
                                        hoursPerWeek,
                                        length,
                                        employerName,
                                        employerAdd,
                                        postcode,
                                        ph,
                                        dValue1,
                                        dValue2,
                                        dValue3,
                                        dValue5,
                                        dValue6,
                                        dValue7,
                                        dValue8,
                                        dValue9,
                                        dValue10,
                                        dValue11,
                                        areYou,
                                        evidence: values[0] || '',
                                        evidence1: values[1] || '',
                                        evidence2: values[2] || '',
                                        startDateEmployment,
                                        employmentType,
                                        annualSalary,
                                        pipBenifits: pipBenifits
                                    })
                                )
                            })
                            .catch((err) => {
                                console.log(err)
                            })

                        trackStep4Employment()
                        history.push('/qualification')
                    } else {
                        console.log("else case")
                        dispatch(
                            saveEmploymentDetails({
                                employementStatus,
                                hoursPerWeek,
                                length,
                                employerName,
                                employerAdd,
                                postcode,
                                ph,
                                dValue11,
                                startDateEmployment,
                                employmentType,
                                annualSalary,
                                evidence
                            })
                        )

                        trackStep4Employment()
                        history.push('/qualification')
                    }
                }
            }
        }

        setValidated(true)
    }

    const handleChange = (e) => {
        setEmpStatus(e.target.value)
        setEmpStatus(e.target.value)
        if (e.target.value === 'Unemployed, looking for work') {
            setShow(true)
        } else if (e.target.value === 'Employed but on less than £17,374.50 per year') {
            setShow1(true)
            setShow(false)
            setShow3(false)
        } else if (e.target.value === 'Self Employed but on less than £17,374.50' || e.target.value === 'Greater London Employed but on less than £21,547' || e.target.value === 'Greater London Self Employed but on less than £21,547') {
            setShow1(true)
            setShow(false)
            setShow3(false)
        } else {
            setShow1(false)
            setShow(false)
            setShow3(false)
        }
    }

    const handleChange1 = (e) => {
        setValue11(e.target.value)
        setReceiptBenifits(e.target.value)
        if (e.target.value === 'Yes') {
            setShow2(true)
        } else setShow2(false)
    }
    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>


            <FormContainer>
                <ScrollToMount />
                <FormCompletetionSteps step1 step2 step3 step4 />
                <div
                    className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
                    id='Error-msg-form'
                >
                    <p>Please fix one or more fields.</p>
                </div>
                <h1>Step 4: Employment Details</h1>
                <br />

                <div className='text-left mb-3 form-notice'>
                    <b>Providing your employment status is a requirement for funding. The fields in this section are mandatory unless otherwise stated as optional.
                        <br />
                        If supplying employer details, please note that your employer will not be informed that you have applied for this course.
                        <br />
                        <br />
                        When applying for level 3 courses you need to earn under £25,000 amount per year or receiving benefits to be eligible.
                        <br />
                        <br />
                        If you do not have evidence of low income, please continue to the next step but the evidence may be required prior to starting your course.
                    </b>
                </div>
                {(detailsFormData.appliedCourse === "Under-standing Mental Health") && <Form.Label className='form-notice'>
                    If you are aged between 19-23 and do not have at minimum a Level 2 qualification you will not be eligible for funding to do this course.
                    <br />
                    If you are applying for a Level 3 course you need to already have a Level 2 qualification or you will not be eligible.
                </Form.Label>}

                <Form noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Group controlId='employementStatus'>
                        <Form.Label>Please select your employment status </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={employementStatus}
                            onChange={handleChange}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='In paid employment (or self employed)'>
                                Living outside London - Employed or self employed
                            </option>
                            <option value='Employed but on less than £17,374.50 per year'>
                                Living outside London - Earning less than £25,000.00
                            </option>
                            {/* <option value='Self Employed but on less than £17,374.50'>
								Self Employed but on less than £17,374.50
							</option> */}
                            <option value='Greater London Employed but on less than £21,547'>
                                Living in London - Earning less than £27,000
                            </option>
                            <option value='Living in London - Employed or self employed'>
                                Living in London - Employed or self employed
                            </option>
                            <option value='Unemployed, looking for work'>
                                Unemployed/Unwaged/Retired
                            </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(employementStatus === 'Living in London - Employed or self employed' || employementStatus === 'In paid employment (or self employed)') && (
                        <>
                            {ifEmploymentType && <Form.Group controlId='emlpoyedType'>
                                <Form.Label>
                                    Employment Type
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={employmentType}
                                    onChange={(e) => setEmploymentType(e.target.value)}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Self Employed'>
                                        Self Employed
                                    </option>
                                    <option value='Employed'>
                                        Employed
                                    </option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            }
                            <Form.Group controlId='hoursPerWeek'>
                                <Form.Label>
                                    Please select how many hours you work per week?
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={hoursPerWeek}
                                    onChange={(e) => setHours(e.target.value)}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='0 to 10 hours per week'>
                                        0 to 10 hours per week
                                    </option>
                                    <option value='11 to 20 hours per week'>
                                        11 to 20 hours per week
                                    </option>
                                    <option value='21 to 30 hours per week'>
                                        21 to 30 hours per week
                                    </option>
                                    <option value='30+ hours per week'>30+ hours per week</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='length'>
                                <Form.Label>
                                    Please select the length of your employment (months){' '}
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Up to 3'>Up to 3</option>
                                    <option value='4-6'>4-6</option>
                                    <option value='7-12'>7-12</option>
                                    <option value='12+'>12+</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/*
                             <Form.Group controlId='CHeckbox'>
                                    <Form.Check
                                        required
                                        type='checkbox'
                                        label='I can confirm that I am not in receipt of benefits'
                                        className='mr-2'
                                        value='dValue11'
                                        name='dValue11'
                                        onChange={(e) => {
                                            setValue11(e.target.value)
                                        }}
                                    ></Form.Check>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                             */}
                            {ifAnualSalary && <Form.Group controlId='employerName'>
                                <Form.Label>Please share your Annual Salary.</Form.Label>
                                <Form.Control
                                    type='number'
                                    required
                                    value={annualSalary}
                                    onChange={(e) => setAnnualSalary(e.target.value)}
                                ></Form.Control>

                                <Form.Text className='text-muted'>
                                    The college requires this as part of Adult Skills Funding Guidelines
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>}

                            <Form.Group controlId='employerName'>
                                <Form.Label>Please enter the name of your employer {employmentdetailsNecessary ? '' : '(Optional)'}.</Form.Label>
                                <Form.Control
                                    type='text'
                                    required={employmentdetailsNecessary}
                                    value={employerName}
                                    onChange={(e) => setName(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='employerAdd'>
                                <Form.Label>Employer address {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                <Form.Control
                                    type='text'
                                    required={employmentdetailsNecessary}
                                    value={employerAdd}
                                    onChange={(e) => setAdd(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='postcode'>
                                <Form.Label>Postcode {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                <Form.Control
                                    type='text'
                                    required={employmentdetailsNecessary}
                                    value={postcode}
                                    onChange={(e) => setPostcode(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='ph'>
                                <Form.Label>Phone Number {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                <Form.Control
                                    type='number'
                                    required={employmentdetailsNecessary}
                                    value={ph}
                                    onChange={(e) => setPh(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Start Date Employment</Form.Label>
                                <Form.Control
                                    type='date'
                                    required={startDateEmploymentNecessary}
                                    value={startDateEmployment}
                                    onChange={(e) => setStartDateEmployment(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* HOY, LMP, LD */}
                            {(localStorage.getItem('selectedCollege') === "66349265fdc7023702af57c2" ||
                                localStorage.getItem('selectedCollege') === "676fc6eac6cf1ffc3d18417c" ||
                                localStorage.getItem('selectedCollege') === "66fba0d9e573f5f419ef1dc2") && <>
                                    <Form.Label className='form-notice'>
                                        Please note: Some college partners may require employment proof for your application.
                                    </Form.Label>
                                    <Form.Group controlId='evidence'>
                                        <Form.Label>Proof</Form.Label>
                                        <Form.Control
                                            type='file'
                                            accept='image/*,application/pdf'
                                            required={localStorage.getItem('selectedContract') !== "67d020a4ab66b423a6fb9a47"}
                                            onChange={async (e) => {
                                                setEvidence(await uploadImage(e.target.files[0], 'evidence'))
                                            }}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please upload required file.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>}
                        </>
                    )}

                    {(employementStatus === "Unemployed, looking for work") && (
                        <>
                            <h3>Step 3.5: Unwaged, Unemployed or Retired </h3>
                            <Form.Group controlId='unemployedLength'>
                                <Form.Label>
                                    Please select how long you have been unwaged, unemployed or
                                    retired
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={unemployedLength}
                                    onChange={(e) => setUnEmpLength(e.target.value)}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='0-5 months'>0-5 months</option>
                                    <option value='6-11 months'>6-11 months</option>
                                    <option value='12-23 months'>12-23 months</option>
                                    <option value='24-35 months'>24-35 months</option>
                                    <option value='Over 36 months'>Over 36 months</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='areYou'>
                                <Form.Label>
                                    Are you 19-23 (on first day of learning) and enrolling onto
                                    qualifications up to and including Level 2
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    onChange={(e) => setAreYou(e.target.value)}
                                    value={areYou}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='receiptBenifits'>
                                <Form.Label>Are you in receipt of benefits</Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    className='mr-2'
                                    onChange={handleChange1}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Text className='text-muted'>
                                    The college requires this as part of Adult Skills Funding Guidelines
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {(receiptBenifits === "Yes") && (
                                <>
                                    <Form.Group controlId='dec'>
                                        <Form.Label>
                                            Benefit status? (Please tick all that apply){' '}
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Check
                                        type='checkbox'
                                        label='JSA'
                                        name='dValue1'
                                        value='dValue1'
                                        onChange={(e) => {
                                            setValue1(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Income Support'
                                        name='dValue2'
                                        value='dValue2'
                                        onChange={(e) => {
                                            setValue2(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Council Tax Benefit'
                                        name='dValue3'
                                        value='dValue3'
                                        onChange={(e) => {
                                            setValue3(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='ESA (Any)'
                                        name='dValue5'
                                        value='dValue5'
                                        onChange={(e) => {
                                            setValue5(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Incapacity Benefit'
                                        name='dValue6'
                                        value='dValue6'
                                        onChange={(e) => {
                                            setValue6(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Housing Benefit'
                                        name='dValue7'
                                        value='dValue7'
                                        onChange={(e) => {
                                            setValue7(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Universal Credit'
                                        name='dValue8'
                                        value='dValue8'
                                        onChange={(e) => {
                                            setValue8(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>
                                    <Form.Check
                                        type='checkbox'
                                        label='Employed but on low wage'
                                        name='dValue9'
                                        value='dValue9'
                                        onChange={(e) => {
                                            setValue9(e.target.value)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>

                                    <Form.Check
                                        type='checkbox'
                                        label='Personal Independence Payment'
                                        name='pipBenifits'
                                        checked={pipBenifits}
                                        onChange={(e) => {
                                            setPipBenifits(!pipBenifits)
                                        }}
                                        className='mr-2'
                                    ></Form.Check>


                                    <br />
                                    <Form.Label className='form-notice'>
                                        (Optional) Additional government funding is available to support the provision of qualifications to people who are on particular benefits. Please provide evidence of your receipt of benefits.
                                        <br /><br />Acceptable documents include: Benefits letter or screenshot of your benefits portal statement. This needs to show your benefit type, benefit amount, the deduction section and be dated within the last 3 months.
                                        <br /><br /><b>If you do not have evidence of benefits to upload, please continue to the next step</b>

                                    </Form.Label>

                                    <Form.Group controlId='evidence'>
                                        <Form.Label>Evidence</Form.Label>
                                        <Form.Control
                                            type='file'
                                            required
                                            accept='image/*,application/pdf'
                                            onChange={(e) => {
                                                setEvidence(e.target.files[0])
                                            }}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please upload required file.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId='lookingForWork'>
                                        <Form.Label>Are you actively looking for work </Form.Label>
                                        <Form.Control
                                            as='select'
                                            required
                                            value={lookingForWork}
                                            className='mr-2'
                                            onChange={(e) => setLookingForWork(e.target.value)}
                                        >
                                            <option selected disabled value=''>
                                                [Please select one]
                                            </option>
                                            <option value='Yes'>Yes</option>
                                            <option value='No'>No</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </>
                            )}

                            <Form.Group controlId='areYourRetired'>
                                <Form.Label>Are you retired?</Form.Label>
                                <Form.Control
                                    as='select'
                                    onChange={(e) => {
                                        setValue10(e.target.value)
                                    }}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}

                    <>
                        {((employementStatus === "Greater London Employed but on less than £21,547") || (employementStatus === "Employed but on less than £17,374.50 per year")) && (
                            <>
                                {ifEmploymentType && <Form.Group controlId='emlpoyedType'>
                                    <Form.Label>
                                        Employment Type
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        required
                                        value={employmentType}
                                        onChange={(e) => setEmploymentType(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Self Employed'>
                                            Self Employed
                                        </option>
                                        <option value='Employed'>
                                            Employed
                                        </option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>}

                                <Form.Group controlId='hoursPerWeek'>
                                    <Form.Label>
                                        Please select how many hours you work per week?
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        required
                                        value={hoursPerWeek}
                                        onChange={(e) => setHours(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='0 to 10 hours per week'>
                                            0 to 10 hours per week
                                        </option>
                                        <option value='11 to 20 hours per week'>
                                            11 to 20 hours per week
                                        </option>
                                        <option value='21 to 30 hours per week'>
                                            21 to 30 hours per week
                                        </option>
                                        <option value='30+ hours per week'>
                                            30+ hours per week
                                        </option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='length'>
                                    <Form.Label>
                                        Please select the length of your employment (months){' '}
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        required
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Up to 3'>Up to 3</option>
                                        <option value='4-6'>4-6</option>
                                        <option value='7-12'>7-12</option>
                                        <option value='12+'>12+</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {ifAnualSalary && <Form.Group controlId='employerName'>
                                    <Form.Label>Please share your Annual Salary.</Form.Label>
                                    <Form.Control
                                        type='number'
                                        required
                                        value={annualSalary}
                                        onChange={(e) => setAnnualSalary(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>}
                                <Form.Group controlId='employerName'>
                                    <Form.Label>Please enter the name of your employer {employmentdetailsNecessary ? '' : '(Optional)'}.</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required={employmentdetailsNecessary}
                                        value={employerName}
                                        onChange={(e) => setName(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='employerAdd'>
                                    <Form.Label>Employer Address {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required={employmentdetailsNecessary}
                                        value={employerAdd}
                                        onChange={(e) => setAdd(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='postcode'>
                                    <Form.Label>Postcode {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required={employmentdetailsNecessary}
                                        value={postcode}
                                        onChange={(e) => setPostcode(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='ph'>
                                    <Form.Label>Phone Number {employmentdetailsNecessary ? '' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        required={employmentdetailsNecessary}
                                        type='number'
                                        value={ph}
                                        onChange={(e) => setPh(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Start Date Employment</Form.Label>
                                    <Form.Control
                                        type='date'
                                        required={startDateEmploymentNecessary}
                                        value={startDateEmployment}
                                        onChange={(e) => setStartDateEmployment(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Label className='form-notice'>
                                    <p>
                                        Government funding is also available to those in work who can provide evidence of a lower income.
                                        <br />
                                        If you have evidence of your low income, please upload it here.
                                        <br />
                                        Acceptable documents include: 2 payslips dated within the last 3 months or an employment contract stating your income or a recent P60.
                                        <br />
                                        If you are Self-Employed, you can provide your recent Tax Return Confirmation (SA302).
                                        <br />
                                        <br />
                                        <b>If your evidence does not meet the requirement, you will be contacted by us. </b></p>
                                </Form.Label>
                                <Form.Group controlId='evidence'>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        // required={(employementStatus === "Employed but on less than £17,374.50 per year") ? false : true}
                                        required
                                        onChange={(e) => {
                                            setEvidence(e.target.files[0])
                                        }}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='evidence'>
                                    <Form.Label>Additional Evidence</Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        onChange={(e) => {
                                            setEvidence1(e.target.files[0])
                                        }}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='evidence'>
                                    <Form.Label>Additional Evidence</Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        onChange={(e) => setEvidence2(e.target.files[0])}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        )}
                    </>

                    <Button
                        onClick={(e) => {
                            window.location.href = '/emergencydetails'
                        }}
                        variant='primary'
                        className='mr-5 back-Button-form'
                    >
                        Back
                    </Button>

                    <Button type='submit' variant='primary' className='next-Button-form'>
                        Save Progress & Continue
                    </Button>
                </Form>
            </FormContainer >
        </>
    )
}

export default EmployemntForm
