import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import { trackApplyButton } from "../common/analyticsGA4";
const AccessToEngineering = () => {
    let product = {
        "courseTitle": "Access to Higher Education Engineering",
        "courseLength": "12 months",
        "awardingBody": "NCFE / TQUK",
        "courseLevel": "Level 3",
        "funding": "Advance Learner Loan",
        "learningMethods": "Online learning portal",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_250,ar_1:1,c_fill,g_auto,e_art:hokusai/v1741698355/Site-pics/Engineering_l4d4j9.jpg",
    }
    console.log(product)
    const Benefits = (props) => {
        const text = props.text;
        const newText = text.split("•").map((str) => (
            <li>{str}</li>
        ));
        return newText;
    };
    let Content = (props) => {
        let text = props.text;
        const res = String(text);
        console.log(res);
        let newText = res.split("•").map((str) => (
            <li>{str} </li>
        ));
        return newText;
    };
    function demo(arr1, arr2) {
        let Farray = [];
        for (let i = 0; i < arr1.length - 1; i++) {
            let object = {
                heading: arr1[i],
                desc: arr2[i]
            }
            Farray.push(object);
        }
        return Farray;
    }
    return (
        <div>
            <Helmet>
                <title>{product.courseTitle}</title>
            </Helmet>

            <div className="jumbotron text-center splash only-on-desktop">
                <h3 className="heading-jumbotron">{product.courseTitle}</h3>
            </div>
            <div className="only-on-mobile">
                <img
                    src={product.img}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
            <div className="container-sm course-details-p" xs={12}>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
                        <div className="row" style={{ display: "flex !important" }}>
                            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                                <img
                                    src={product.img}
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                                <h3 className="coursedetailh2" style={{ margin: "auto" }}>{product.courseTitle}</h3>
                            </div>
                        </div>

                        <br />

                        <h1 className="coursedetailh2 only-on-mobile" style={{ margin: "auto", fontSize: "22px", color: "#222222" }}>{product.courseTitle}</h1>
                        <br />
                        <HashLink
                            className="btn btn-primary co-button vv only-on-mobile"
                            to={'/form/' + "Access to Engineering" + '#top'}
                            onClick={() => trackApplyButton()}
                        >
                            Apply Now
                        </HashLink>
                        <br />
                        <h6><b>Description: </b></h6>
                        <p>
                            The <b>Access to Higher Education Diploma (Engineering)</b> prepares you for a degree in engineering by equipping you with essential skills and knowledge. Through this nationally recognized course, you’ll explore key engineering principles, including atomic structure, chemical change, and mechanical engineering fundamentals. Strong mathematical skills are important, as you'll apply mathematical principles used daily by engineers. This course is designed to build a solid foundation, helping you develop problem-solving abilities and practical expertise to advance your engineering career.
                        </p>

                        <br />
                        <b>Areas of Study: </b>
                        <ul>
                            <li>Principles of Mechanical Engineering</li>
                            <li>Mathematical Principles Applies to Engineering</li>
                            <li>Energy and Motion in Mechanical Science</li>
                            <li>Hydrocarbons, Alkenes and Alkanes</li>
                            <li>Algebraic and Trigonometric Techniques in Engineering</li>
                        </ul>
                        <h5><strong>Key Features of the Advanced Learner Loan:</strong></h5>

                        <p><strong>No Income or Credit Check</strong> – Loan approval is not based on your financial status.</p>

                        <p><strong>Flexible Repayments</strong> – You only start repaying the loan <strong>once you earn above the repayment threshold</strong>.</p>

                        <p><strong>Covers Tuition Fees</strong> – The loan is paid directly to your learning provider to cover course costs.</p>

                        <p><strong>No Age Limit</strong> – Available for learners aged <strong>19 and above</strong>, with no upper age restriction.</p>

                        <p><strong>Loan Write-Off for University Graduates</strong> – If you go on to complete a <strong>higher education degree</strong>, your Advanced Learner Loan may be written off, subject to government policies.</p>

                        <br />
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1743009613/Site-pics/image_2025-03-26_221954782_a0eras.png" style={{ width: '100%' }} />

                       
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title="Content">
                                <br />
                                <p>
                                    <b>Module 1: Academic Writing Skills</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to structure a written response</li>
                                        <li>Be able to develop a structured response to a plan</li>
                                        <li>Be able to present the response appropriately for audience and purpose</li>
                                        <li>Understand how to apply academic writing principles to own work</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 2: Reading and Note Making</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand a range of reading strategies</li>
                                        <li>Understand the use of language in terms of the purpose and context of a range of texts</li>
                                        <li>Be able to use methods for developing notes from a range of sources</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 3: The Structure of the Atom</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the properties of protons, electrons and neutrons</li>
                                        <li>Understand the distribution of protons, neutrons and electrons in an atom/isotope</li>
                                        <li>Understand s, p and d orbitals</li>
                                        <li>Understand the relationship between ionisation energies and reactivity</li>
                                        <li>Understand how ionisation energy data is reflected in the periodic table</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 4: Arrangement and Trends in the Periodic Table</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the arrangement of elements in the periodic table</li>
                                        <li>Understand the periodicity of period 3 of the periodic table</li>
                                        <li>Understand ionisation energies in relation to period 3</li>
                                        <li>Understand the oxidation and reduction effects of reactions</li>
                                        <li>Understand reactions of the Group 2 metals</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 5: Using Algebra</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to use series</li>
                                        <li>Be able to perform logarithmic calculations</li>
                                        <li>Be able to solve problems involving surds</li>
                                        <li>Be able to solve algebraic equations</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 6: Principles of Mechanical Engineering</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to perform vector calculations</li>
                                        <li>Be able to perform vector resolution calculations</li>
                                        <li>Understand uniformly accelerated motion</li>
                                        <li>Be able perform projectile motion calculations</li>
                                        <li>Be able to use Newton’s Laws of Motion</li>
                                        <li>Be able to perform calculations involving the principles of momentum and conservation</li>
                                        <li>Understand the concepts of energy, work and power</li>
                                        <li>Understand the components of internal energy</li>
                                        <li>Be able to perform calculations related to the ideal gas laws</li>
                                        <li>Understand the basic principles of kinetic theory of gases</li>
                                    </ul>

                                </p>
                                <p>
                                    <b>Module 7: Trigonometric Functions, Rations and Rules</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to use trigonometric functions and ratios</li>
                                        <li>Be able to solve equations and problems by employing trigonometric rules</li>
                                        <li>Be able to construct proofs by applying trigonometric</li>
                                        <li>Be able to illustrate trigonometric functions graphically</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 8: Calculus</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to solve a problem involving coordinate geometry of straight lines</li>
                                        <li>Be able to perform and apply differentiation on a simple function</li>
                                        <li>Be able to perform and apply integration</li>
                                    </ul>
                                </p>

                                <p>
                                    <b>Module 9: Hydrocarbons, Alkenes and Alkanes</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the impact of organic compounds</li>
                                        <li>Understand alkane structure and reactions</li>
                                        <li>Understand alkene structure and reactions</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 10: Mathematical Principles Applies to Engineering</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to assess complex expressions and solve engineering problems using the laws of logarithms and indices</li>
                                        <li>Be able to use algebraic techniques to solve problems</li>
                                        <li>Be able to solve engineering problems using a range of mathematical techniques</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 11: Molarity and Chemical Change</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to calculate solution concentrations</li>
                                        <li>Understand enthalpy change</li>
                                        <li>Be able to analyse data to determine reaction order</li>
                                        <li>Understand chemical equilibrium</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 12: Study Skills Portfolio Building</b> <br />
                                    {/* <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Understand the causes and effects of atmospheric pollution</li>
                                        <li>Understand the effects of rising CO2 levels on the chemistry of the oceans</li>
                                        <li>Understand causes and effects of pollution of the hydrosphere</li>
                                        <li>Be able to evaluate sources of trace-metal pollution and their effects on local soils and/or water courses</li>
                                    </ul> */}
                                </p>
                                <p>
                                    <b>Module 13: Energy and Motion in Mechanical Science</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to perform linear and angular motion calculations</li>
                                        <li>Be able to perform calculations on the effect of forces on materials</li>
                                        <li>Be able to solve problems related to friction</li>
                                        <li>Be able to perform heat energy and transfer calculations</li>
                                        <li>Be able to perform torque related calculations</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 14: Engineering Calculus</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to use differentiation techniques applied to engineering</li>
                                        <li>Be able to use calculus to solve engineering problems</li>
                                        <li>Be able to apply integration techniques</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 15: Conducting a Design Project</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to prepare a design specification for an engineered product, from a given design brief</li>
                                        <li>Be able to produce alternative design solutions for an engineered product</li>
                                    </ul>
                                </p>
                                <p>
                                    <b>Module 16:  Algebraic and Trigonometric Techniques in Engineering</b> <br />
                                    <p>On completion of this unit you will:</p>
                                    <ul>
                                        <li>Be able to apply algebraic techniques in engineering applications</li>
                                        <li>Be able to use matrix algebra to solve simple technical problems</li>
                                        <li>Be able to perform trigonometric functions to calculate basic and inverse ratios and plot equations</li>
                                        <li>Be able to prove trigonometric identities and apply rules to solve engineering problems</li>
                                        <li>Be able to use binomial theorem to expand mathematical expressions and solve engineering problems</li>
                                    </ul>
                                </p>
                                <p>
                                    In addition to the qualification units, you will also be required to complete short introductory tasks at the start of your course to support the development of your academic study skills.
                                </p>
                            </Tab>
                            <Tab eventKey="benefits" title="Benefits">
                                <h6><b>The course will help you…</b></h6>
                                <ul>
                                    <li>
                                        First steps towards a career in Engineering
                                    </li>
                                    <li>
                                        Achieve the equivalent to 3 A levels
                                    </li>
                                    <li>
                                        Learn at your pace
                                    </li>
                                    <li>
                                        100% online study
                                    </li>
                                    <li>
                                        No Exams
                                    </li>
                                </ul>
                                <b>Career Aspects: </b>
                                <ul>
                                    <li>Applied Engineering</li>
                                    <li>Mechanical Engineering</li>
                                    <li>Chemical Engineering</li>
                                </ul>
                                <br />
                                <b>University Applications & Entry Requirements</b>
                                <br />
                                Your allocated college will support you in applying to universities, but <b>each university sets its own admission criteria</b>. It’s your responsibility to check if your <b>Access to HE Diploma</b> and other qualifications meet the requirements of your chosen university.
                                <br /><br />
                                Common university entry requirements include:
                                <ul>
                                    <li><b>A certain number of credits</b> at <b>Merit</b> or <b>Distinction</b></li>
                                    <li>A <b>face-to-face interview</b> at the university</li>
                                    <li><b>Literacy and numeracy assessments</b> provided by the university</li>
                                    <li><b>Course-related work placements</b> or work experience </li>
                                    <li><b>GCSEs in Maths & English (Grade C/4 or equivalent Level 2 qualification)</b> such as Functional Skills or Key Skills</li>
                                </ul>
                                <br />
                            </Tab>

                            <Tab eventKey="eligibility" title="Eligibility">
                                <br />
                                <h6><b>Eligibility Criteria:</b></h6>
                                <ul>
                                    <li>Must be 19 or older (Must turn 19 before 31st August, 2024)</li>
                                    <li>Level 2 in Maths and English</li>
                                </ul>
                                <b>Residency Status</b>
                                <ul>
                                    <li>Be living in the UK on first day of your course</li>
                                    <li>UK /Irish Citizen or have ‘settled status’ </li>
                                    <li>Lived in the UK, British territories, Channel islands or the Isle of Man for 3 years in a row.</li>
                                    <li>Other Visa types may be eligible <a href="https://www.gov.uk/advanced-learner-loan">Advanced Learner Loan: Overview - GOV.UK</a></li>
                                </ul>
                                <p >It will be the learner’s responsibility to understand the repayment terms of the loan.</p>
                            </Tab>
                            <Tab eventKey="Learning Method" title="Learning Method">
                                <br />
                                <h6><b>Learning Method: </b></h6>
                                <p>
                                    Once you enroll, all your learning materials will be available through our online portal. If you need any accessibility arrangements, please let our team know.
                                    <br /><br />
                                    You'll receive full support throughout your course, including one-on-one online tutorials and group sessions.
                                </p>

                                <p>
                                    <b>Flexible Online Learning</b><br />
                                    Studying online allows you to fit your learning around your existing commitments. Whether you're working or caring for family, you can complete your course at your own pace and move closer to your goals.
                                    

                                </p>
                            </Tab>
                            <Tab eventKey="Assessment Method" title="Assessment Method">
                                <br />
                                <h6><b>Assessment Method: </b></h6>
                                <p>Your assignments are due at the end of each unit and will be marked by either a tutor or a computer.
                                    <br /><br />
                                    Your assigned college will provide access to MS Office through your learning portal, so you can complete your assessments easily.
                                </p>
                                <b>Qualification & Certification</b>
                                <br />
                                After completing your course, you will receive a <b>Level 3 nationally recognized qualification</b> regulated by the <b>Quality Assurance Agency for Higher Education (QAA)</b>. Your certificate is typically issued <b>12-16 weeks</b> after your final assessment is graded, but this may vary depending on the time of year.


                            </Tab>
                        </Tabs>
                        <br />
                        <hr />



                    </div>
                    <div className="col-md-4 info-box-courses">
                        <Card style={{ width: "18rem", color: "black" }} className="wd">
                            <Card.Body style={{ boxShadow: "none" }}>
                                <Card.Title>KEY INFORMATION</Card.Title>
                                <Table striped bordered>
                                    <thead>
                                        <tbody>
                                            <tr>
                                                <td>Course Length</td>
                                                <td>{product.courseLength}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Provider</td>
                                                <td>{product.awardingBody}</td>
                                            </tr>
                                            <tr>
                                                <td>Course Level</td>
                                                <td>{product.courseLevel}</td>
                                            </tr>
                                            <tr>
                                                <td>Funding (subject to availability)</td>
                                                <td>Advance Learner Loan</td>
                                            </tr>
                                            <tr>
                                                <td>Learning Method(s)</td>
                                                <td>{product.learningMethods}</td>
                                            </tr>
                                        </tbody>
                                    </thead>
                                </Table>
                                <HashLink
                                    className="btn btn-primary co-button vv "
                                    to={'/form/' + "Access to Engineering" + '#top'}
                                    onClick={() => trackApplyButton()}
                                >
                                    Apply Now
                                </HashLink>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <section className="services section-bg" id="partners" style={{ borderTop: "1px solid #eaeaea", marginTop: "30px", paddingTop: "0px" }}>
                <div className="container">
                    <div className="row social-logo-section1" >
                        <div className="col">
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    <center>
                        <div className="row social-logo-section only-on-mobile" >
                            <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                        </div>
                    </center>
                </div>

            </section>
        </div>
    )
}

export default AccessToEngineering